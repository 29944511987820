import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function Password() {
  const navigate = useNavigate();
  const { password } = useAuth();
  const [showPass, setShowPass] = useState() as any;
  const showNewPass = showPass === "newPass";
  const showConfirmPass = showPass === "confirmPass";
  return (
    <Formik
      initialValues={{
        newPass: "",
        confirmPass: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        newPass: Yup.string().max(255).required(" Đặt mật khẩu mới bắt buộc"),
        confirmPass: Yup.string().required("Vui lòng điền lại mật khẩu")
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          password(values.newPass);
          navigate("/auth/sign-in");
        } catch (error: any) {
          const message = error.message || "Đã xảy ra sự cố";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>

          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type={showNewPass ? "text" : "password"}
                name="newPass"
                label="Nhập mật khẩu mới "
                value={values.newPass}
                error={Boolean(touched.newPass && errors.newPass)}
                fullWidth
                helperText={touched.newPass && errors.newPass}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
                size="small"
                InputProps={{
                  endAdornment:
                    < InputAdornment position="end" >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPass(showNewPass ? "" : "newPass")}
                        edge="end"
                      >
                        {showNewPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
    
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={showConfirmPass ? "text" : "password"}
                name="confirmPass"
                label="Nhập lại mật khẩu mới "
                value={values.confirmPass}
                error={Boolean(touched.confirmPass && errors.confirmPass)}
                fullWidth
                helperText={touched.confirmPass && errors.confirmPass}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
                size="small"
                InputProps={{
                  endAdornment:
                    < InputAdornment position="end" >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPass(showConfirmPass ? "" : "confirmPass")}
                        edge="end"
                      >
                        {showConfirmPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
    
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                size="small"
              >
                Xác nhận 
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                size="small"
                onClick={() => navigate("/auth/sign-in")}
              >
                Quay lại đăng nhập
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default Password;
