import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import { IVacation, IVacationCreate } from "../../types/vacation";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface VacationState {
  fetchVacationsStatus: StatusEnums;
  fetchVacationStatus: StatusEnums;
  addVacationStatus: StatusEnums;
  vacations: IPagination<IVacation> | null,
  vacation: IVacation | null,
  resError: IResError | null,
}

const initialState: VacationState = {
  fetchVacationsStatus: StatusEnums.IDLE,
  fetchVacationStatus: StatusEnums.IDLE,
  addVacationStatus: StatusEnums.IDLE,
  vacations: null,
  vacation: null,
  resError: null,

};

export const fetchVacationAsync = createAsyncThunk(
  "vacation/fetchVacation",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/vacations/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addVacationAsync = createAsyncThunk(
  "vacation/addVacation",
  async (vacation: IVacationCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/vacations/add", vacation);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchVacationsAsync = createAsyncThunk(
  "vacations/fetchVacations",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/vacations/search", filters);
    return response.data;
  }
);


export const vacationSlice = createSlice({
  name: "vacations",
  initialState,
  reducers: {
    resetFetchVacationsStatus: (state) => {
      state.fetchVacationsStatus = StatusEnums.IDLE;
    },
    resetAddVacationStatus: (state) => {
      state.addVacationStatus = StatusEnums.IDLE;
    },
    resetFetchVacationStatus: (state) => {
      state.fetchVacationStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch vacation
      .addCase(fetchVacationAsync.pending, (state, action) => {
        state.fetchVacationStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVacationAsync.fulfilled, (state, action) => {
        state.fetchVacationStatus = StatusEnums.SUCCESS;
        state.vacation = action.payload;
      })
      .addCase(fetchVacationAsync.rejected, (state, action) => {
        state.fetchVacationStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add vacation
      .addCase(addVacationAsync.pending, (state) => {
        state.addVacationStatus = StatusEnums.LOADING;
      })
      .addCase(addVacationAsync.fulfilled, (state, action) => {
        state.addVacationStatus = StatusEnums.SUCCESS;
      })
      .addCase(addVacationAsync.rejected, (state, action) => {
        state.addVacationStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch vacations 
      .addCase(fetchVacationsAsync.pending, (state, action) => {
        state.fetchVacationsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVacationsAsync.fulfilled, (state, action) => {
        state.fetchVacationsStatus = StatusEnums.SUCCESS;
        state.vacations = action.payload as IPagination<IVacation>;
      })
      .addCase(fetchVacationsAsync.rejected, (state, action) => {
        state.fetchVacationsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchVacationStatus, resetFetchVacationsStatus, resetAddVacationStatus } = vacationSlice.actions;


export const selectVacations = (state: RootState) => state.vacation.vacations;
export const selectVacation = (state: RootState) => state.vacation.vacation;
export const selectVacationCount = (state: RootState) => state.vacation.vacations?.count;
export const selectFetchVacationsStatus = (state: RootState) => state.vacation.fetchVacationsStatus;
export const selectAddVacationStatus = (state: RootState) => state.vacation.addVacationStatus;
export const selectFetchVacationStatus = (state: RootState) => state.vacation.fetchVacationStatus;
export const selectVacationResError = (state: RootState) => state.vacation.resError;

export default vacationSlice.reducer;
