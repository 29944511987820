import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { SystemLog } from "../../types/system-log";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface SystemLogState {
  fetchSystemLogsStatus: StatusEnums;
  systemLogs: IPagination<SystemLog> | null,
  filters: IFilters | null,
  showFilters: boolean
}

const initialState: SystemLogState = {
  fetchSystemLogsStatus: StatusEnums.IDLE,
  systemLogs: null,
  filters: null,
  showFilters: false

};

export const fetchSystemLogsAsync = createAsyncThunk(
  "systemLog/fetchSystemLogs",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/system-logs/search", filters);
    return response.data;
  }
);

export const systemLogSlice = createSlice({
  name: "systemLog",
  initialState,
  reducers: {
    showSystemLogFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetSystemLogStatus: (state) => {
      state.fetchSystemLogsStatus = StatusEnums.IDLE;
    },
    setSystemLogFilters: (state, action) => {
      state.filters = { ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch systemLogs 
      .addCase(fetchSystemLogsAsync.pending, (state) => {
        state.fetchSystemLogsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchSystemLogsAsync.fulfilled, (state, action) => {
        state.fetchSystemLogsStatus = StatusEnums.SUCCESS;
        state.systemLogs = action.payload as IPagination<SystemLog>;
      })
      .addCase(fetchSystemLogsAsync.rejected, (state) => {
        state.fetchSystemLogsStatus = StatusEnums.FAILED;
      });
  },
});

export const { resetSystemLogStatus, setSystemLogFilters, showSystemLogFilters } = systemLogSlice.actions;


export const selectSystemLogs = (state: RootState) => state.systemLog.systemLogs;
export const selectSystemLogFilters = (state: RootState) => state.systemLog.filters;
export const selectSystemLogCount = (state: RootState) => state.systemLog.systemLogs?.count;
export const selectFetchSystemLogsStatus = (state: RootState) => state.systemLog.fetchSystemLogsStatus;
export const selectShowSystemLogFilters = (state: RootState) => state.systemLog.showFilters;

export default systemLogSlice.reducer;
