import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IDevice } from "../../types/device";
import { IFilters, IPagination} from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IBranch } from "../../types/branch";

export interface BranchState {
  fetchBranchsStatus: StatusEnums;
  branchs: IPagination<IBranch> | null,
  branch: IBranch | null,
  resError: IResError | null,
  filters: IFilters | null,
}

const initialState: BranchState = {
  fetchBranchsStatus: StatusEnums.IDLE,
  branchs: null,
  branch: null,
  resError: null,
  filters: null,

};

export const fetchBranchsAsync = createAsyncThunk(
  "branchs/fetchbranchs",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/branchs/search", filters);
    return response.data;
  }
);

export const branchslice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    resetfetchBranchsStatus: (state) => {
      state.fetchBranchsStatus = StatusEnums.IDLE;
    },

  },
  extraReducers: (builder) => {
    builder
      // fetch branch 
      .addCase(fetchBranchsAsync.pending, (state, action) => {
        state.fetchBranchsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchBranchsAsync.fulfilled, (state, action) => {
        state.fetchBranchsStatus = StatusEnums.SUCCESS;
        state.branchs = action.payload as IPagination<IBranch>;
      })
      .addCase(fetchBranchsAsync.rejected, (state, action) => {
        state.fetchBranchsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetfetchBranchsStatus} = branchslice.actions;

export const selectbranchs = (state: RootState) => state.branch.branchs;
export const selectbranch = (state: RootState) => state.branch.branch;
export const selectfetchBranchsStatus = (state: RootState) => state.branch.fetchBranchsStatus;
export const selectbranchResError = (state: RootState) => state.branch.resError;

export default branchslice.reducer;
