import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import MarketingLayout from "./layouts/MarketingLayout";
import ManagerLayout from "./layouts/ManagerLayout";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import PrivateLayout from "./layouts/PrivateLayout";
import MaOTP from "./pages/auth/MaOTP";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import Password from "./pages/auth/Password";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import AdminTransactionPage from "./pages/admin/transaction/AdminTransactionPage";

const HomePage = async(() => import("./pages/HomePage"));

// Admin routes
const AdminProfilePage = async(() => import("./pages/admin/profile/AdminProfilePage"));
const AdminUserPage = async(() => import("./pages/admin/user/AdminUserPage"));
const AdminDevicePage = async(() => import("./pages/admin/device/AdminDevicePage"));
const AdminArticlePage = async(() => import("./pages/admin/article/AdminArticlePage"));
const AdminNotifPage = async(() => import("./pages/admin/notification/AdminNotifPage"));
const AdminGroupPage = async(() => import("./pages/admin/group/AdminGroupPage"));
const AdminCustomerPage = async(() => import("./pages/admin/customer/AdminCustomerPage"));
const AdminInvoicePage = async(() => import("./pages/admin/history-invoice/AdminInvoicePage"));
const AdminBilliardTablePage = async(() => import("./pages/admin/table/AdminBilliardTablePage"));
const AdminBookingPage = async(() => import("./pages/admin/booking/AdminBookingPage"));
const AdminInvoiceDetailPage = async(() => import("./pages/admin/invoice-detail/AdminInvoiceDetailPage"));
const AdminActivityPage = async(() => import("./pages/admin/activity/AdminActivityPage"));
const AdminMissionPage = async(() => import("./pages/admin/mission/AdminMissionPage"));
const AdminVoucherPage = async(() => import("./pages/admin/voucher/AdminVoucherPage"));
const AdminReleasePage = async(() => import("./pages/admin/release/AdminReleasePage"));
const AdminPlusPointPage = async(() => import("./pages/admin/plus-point/AdminPlusPointPage"));
const AdminClubPage = async(() => import("./pages/admin/club/AdminClubPage"));
const AdminFeedbackPage = async(() => import("./pages/admin/feed-back/AdminFeedbackPage"));
const AdminFeedTagPage = async(() => import("./pages/admin/feed-tag/AdminFeedTagPage"));
const AdminSystemLogPage = async(() => import("./pages/admin/system-log/AdminSystemLogPage"));
const AdminApproveMissionPage = async(() => import("./pages/admin/approve-mission/AdminApproveMissionPage"));
const AdminEmployeePage = async(() => import("./pages/admin/employee/AdminEmployeePage"));
const AdminUploadRankPage = async(() => import("./pages/admin/upload-rank/AdminUploadRankPage"));
const AdminTemplateTaskPage = async(() => import("./pages/admin/template-task/AdminTemplateTaskPage"));
const AdminCreatedTaskPage = async(() => import("./pages/admin/created-task/AdminCreatedTaskPage"));


// Marketing routes
const MarketingProfilePage = async(() => import("./pages/marketing/profile/MarketingProfilePage"));
const MarketingArticlePage = async(() => import("./pages/marketing/article/MarketingArticlePage"));
const MarketingNotifPage = async(() => import("./pages/marketing/notification/MarketingNotifPage"));
const MarketingGroupPage = async(() => import("./pages/marketing/group/MarketingGroupPage"));
const MarketingBookingPage = async(() => import("./pages/marketing/booking/MarketingBookingPage"));
const MarketingMissionPage = async(() => import("./pages/marketing/mission/MarketingMissionPage"));
const MarketingVoucherPage = async(() => import("./pages/marketing/voucher/MarketingVoucherPage"));
const MarketingFeedbackPage = async(() => import("./pages/marketing/feed-back/MarketingFeedbackPage"));
const MarketingApproveMissionList = async(() => import("./pages/marketing/approve-mission/MarketingApproveMissionList"));

// Manager router
const ManagerProfilePage = async(() => import("./pages/manager/profile/ManagerProfilePage"));
const ManagerArticlePage = async(() => import("./pages/manager/article/ManagerArticlePage"));
const ManagerNotifPage = async(() => import("./pages/manager/notification/ManagerNotifPage"));
const ManagerGroupPage = async(() => import("./pages/manager/group/ManagerGroupPage"));
const ManagerBookingPage = async(() => import("./pages/manager/booking/ManagerBookingPage"));
const ManagerMissionPage = async(() => import("./pages/manager/mission/ManagerMissionPage"));
const ManagerVoucherPage = async(() => import("./pages/manager/voucher/ManagerVoucherPage"));
const ManagerFeedbackPage = async(() => import("./pages/manager/feed-back/ManagerFeedbackPage"));
const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "otp",
        element: <MaOTP />,
      },
      {
        path: "password",
        element: <Password />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "admin-layout",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <AdminProfilePage />,
      },
      {
        path: "users",
        element: <AdminUserPage />,
      },
      {
        path: "plus-points",
        element: <AdminPlusPointPage />,
      },
      {
        path: "devices",
        element: <AdminDevicePage />,
      },
      {
        path: "articles",
        element: <AdminArticlePage />,
      },
      {
        path: "notifications",
        element: <AdminNotifPage />,
      },
      {
        path: "groups",
        element: <AdminGroupPage />,
      },
      {
        path: "customers",
        element: <AdminCustomerPage />,
      },
      {
        path: "history-invoices",
        element: <AdminInvoicePage />,
      },
      {
        path: "tables",
        element: <AdminBilliardTablePage />,
      },
      {
        path: "bookings",
        element: <AdminBookingPage />,
      },
      {
        path: "invoice-details",
        element: <AdminInvoiceDetailPage />,
      },
      {
        path: "activitys",
        element: <AdminActivityPage />,
      },
      {
        path: "missions",
        element: <AdminMissionPage />,
      },
      {
        path: "vouchers",
        element: <AdminVoucherPage />,
      },
      {
        path: "releases",
        element: <AdminReleasePage />,
      },
      {
        path: "feedbacks",
        element: <AdminFeedbackPage />,
      },
      {
        path: "clubs",
        element: <AdminClubPage />,
      },
      {
        path: "tags",
        element: <AdminFeedTagPage />,
      },
      {
        path: "system-logs",
        element: <AdminSystemLogPage />,
      },
      {
        path: "approve-missions",
        element: <AdminApproveMissionPage />,
      },
      {
        path: "employees",
        element: <AdminEmployeePage />,
      },
      {
        path: "transactions",
        element: <AdminTransactionPage />,
      },
      {
        path: "upload-rank",
        element: <AdminUploadRankPage />,
      },
      {
        path: "template-tasks",
        element: <AdminTemplateTaskPage />,
      },
      {
        path: "created-tasks",
        element: <AdminCreatedTaskPage />,
      },
    ],
  },
  {
    path: "marketing-layout",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <MarketingProfilePage />,
      },
      {
        path: "articles",
        element: <MarketingArticlePage />,
      },
      {
        path: "notifications",
        element: <MarketingNotifPage />,
      },
      {
        path: "groups",
        element: <MarketingGroupPage />,
      },
      {
        path: "bookings",
        element: <MarketingBookingPage />,
      },
      {
        path: "missions",
        element: <MarketingMissionPage />,
      },
      {
        path: "vouchers",
        element: <MarketingVoucherPage />,
      },
      {
        path: "feedbacks",
        element: <MarketingFeedbackPage />,
      },
      {
        path: "approve-missions",
        element: <MarketingApproveMissionList />,
      },
    ],
  },
  // Managerr
  {
    path: "manager-layout",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <ManagerProfilePage />,
      },
      {
        path: "articles",
        element: <ManagerArticlePage />,
      },
      {
        path: "notifications",
        element: <ManagerNotifPage />,
      },
      {
        path: "groups",
        element: <ManagerGroupPage />,
      },
      {
        path: "bookings",
        element: <ManagerBookingPage />,
      },
      {
        path: "missions",
        element: <ManagerMissionPage />,
      },
      {
        path: "vouchers",
        element: <ManagerVoucherPage />,
      },
      {
        path: "feedbacks",
        element: <ManagerFeedbackPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  
];

export default routes;
