import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { INotificationCreate, Notification } from "../../types/notification";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface NotificationState {
  fetchNotificationsStatus: StatusEnums;
  fetchMyNotificationsStatus: StatusEnums;
  deleteNotificationsStatus: StatusEnums;
  addNotificationStatus: StatusEnums;
  quickNotifications: Notification[] | null,
  myNotification: Notification | null,
  myNotifications: IPagination<Notification> | null,
  notifications: IPagination<Notification> | null,
  notification: Notification | null,
  resError: IResError | null,
  showFilters: boolean,
  shouldReloadProfile: boolean

}

const initialState: NotificationState = {
  deleteNotificationsStatus: StatusEnums.IDLE,
  fetchNotificationsStatus: StatusEnums.IDLE,
  fetchMyNotificationsStatus: StatusEnums.IDLE,
  addNotificationStatus: StatusEnums.IDLE,
  quickNotifications: null,
  myNotification: null,
  myNotifications: null,
  notifications: null,
  notification: null,
  resError: null,
  showFilters: false,
  shouldReloadProfile: false
};
export const deleteNotificationsAsync = createAsyncThunk(
  "notification/deleteNotifications",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/notifications/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const fetchMyNotificationsAsync = createAsyncThunk(
  "callstatus/fetchMyNotifications",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/notifications/search-mine", filters);
    return response.data;
  }
);

export const fetchNotificationsAsync = createAsyncThunk(
  "callstatus/fetchNotifications",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/notifications/search-admin", filters);
    return response.data;
  }
);

export const addNotificationAsync = createAsyncThunk(
  "notification/addNotification",
  async (notification: INotificationCreate, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("avatar", notification.avatar);
      formData.append("title", notification.title);
      formData.append("content", notification.content);
      formData.append("toUserId", notification.toUserId);
      formData.append("toGroupId", notification.toGroupId);
      formData.append("ctaScreen", notification.ctaScreen);
      formData.append("ctaText", notification.ctaText);
      formData.append("ctaId", notification.ctaId);
      formData.append("linkText", notification.linkText);
      formData.append("linkUrl", notification.linkUrl);
      formData.append("isPopup",Boolean(notification.isPopup).toString());
      const response = await axiosInstance.post("/api/notifications/add", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotificationFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetNotificationStatus: (state) => {
      state.fetchMyNotificationsStatus = StatusEnums.IDLE;
      state.fetchNotificationsStatus = StatusEnums.IDLE;
      state.addNotificationStatus = StatusEnums.IDLE;
      state.shouldReloadProfile = false;
    },
    resetNotifications: (state) => {
      state.myNotifications = null;
    },
    appenQuickdNotification: (state, action) => {
      const notification = action.payload as Notification;
      state.myNotification = notification;
      if (notification.type === "DEPOSIT")
        state.shouldReloadProfile = true;
      if (!state.myNotifications)
        state.myNotifications = { data: [], count: 0, pages: 1, filters: {} };
      if (state.myNotifications)
        state.myNotifications = { ...state.myNotifications, count: state.myNotifications.count + 1, data: [action.payload, ...(state.myNotifications?.data || [])] };
    },
    resetDeleteNotificationsStatus: (state) => {
      state.deleteNotificationsStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder 
    // Delete Notification
    .addCase(deleteNotificationsAsync.pending, (state) => {
      state.deleteNotificationsStatus = StatusEnums.LOADING;
    })
    .addCase(deleteNotificationsAsync.fulfilled, (state) => {
      state.deleteNotificationsStatus = StatusEnums.SUCCESS;
    })
    .addCase(deleteNotificationsAsync.rejected, (state, action) => {
      state.deleteNotificationsStatus = StatusEnums.FAILED;
      state.resError = action.payload as IResError;
    })
      // fetch my notifications 
      .addCase(fetchMyNotificationsAsync.pending, (state) => {
        state.fetchMyNotificationsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchMyNotificationsAsync.fulfilled, (state, action) => {
        state.fetchMyNotificationsStatus = StatusEnums.SUCCESS;
        const notifData = action.payload as IPagination<Notification>;
        if (!state.myNotifications || notifData.filters.page === 0) state.myNotifications = notifData;
        else
          state.myNotifications = { ...state.myNotifications, ...notifData, data: [...state.myNotifications.data, ...(notifData.data || [])] };
      })
      .addCase(fetchMyNotificationsAsync.rejected, (state) => {
        state.fetchMyNotificationsStatus = StatusEnums.FAILED;
      })
      // fetch notifications 
      .addCase(fetchNotificationsAsync.pending, (state) => {
        state.fetchNotificationsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.fetchNotificationsStatus = StatusEnums.SUCCESS;
      })
      .addCase(fetchNotificationsAsync.rejected, (state) => {
        state.fetchNotificationsStatus = StatusEnums.FAILED;
      })
      // add notification
      .addCase(addNotificationAsync.pending, (state) => {
        state.addNotificationStatus = StatusEnums.LOADING;
      })
      .addCase(addNotificationAsync.fulfilled, (state, action) => {
        state.addNotificationStatus = StatusEnums.SUCCESS;
      })
      .addCase(addNotificationAsync.rejected, (state, action) => {
        state.addNotificationStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetNotifications, appenQuickdNotification, resetNotificationStatus, showNotificationFilters, resetDeleteNotificationsStatus } = notificationSlice.actions;

export const selectMyNotification = (state: RootState) => state.notification.myNotification;
export const selectMyNotifications = (state: RootState) => state.notification.myNotifications;
export const selectFetchMyNotificationsStatus = (state: RootState) => state.notification.fetchMyNotificationsStatus;
export const selectNotifications = (state: RootState) => state.notification.notifications;
export const selectNotification = (state: RootState) => state.notification.notification;
export const selectFetchNotificationsStatus = (state: RootState) => state.notification.fetchNotificationsStatus;
export const selectAddNotificationStatus = (state: RootState) => state.notification.addNotificationStatus;
export const selectShowNotificationFilters = (state: RootState) => state.notification.showFilters;
export const selectShouldReloadProfile = (state: RootState) => state.notification.shouldReloadProfile;
export const selectDeleteNotificationsStatus = (state: RootState) => state.notification.deleteNotificationsStatus;
export const selectNotificationResError = (state: RootState) => state.notification.resError;

export default notificationSlice.reducer;
