import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IBooking, IBookingCreate } from "../../types/booking";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface BookingState {
  addBookingStatus: StatusEnums;
  cancelledBookingsStatus: StatusEnums;
  receivedBookingStatus: StatusEnums;
  fetchBookingsStatus: StatusEnums;
  deleteBookingsStatus: StatusEnums;
  updateBookingStatus: StatusEnums;
  fetchBookingStatus: StatusEnums;
  bookings: IPagination<IBooking> | null,
  booking: IBooking | null,
  resError: IResError | null,
  addedResult: { id: string, paht: string } | null
}

const initialState: BookingState = {
  addBookingStatus: StatusEnums.IDLE,
  receivedBookingStatus: StatusEnums.IDLE,
  cancelledBookingsStatus: StatusEnums.IDLE,
  fetchBookingsStatus: StatusEnums.IDLE,
  deleteBookingsStatus: StatusEnums.IDLE,
  updateBookingStatus: StatusEnums.IDLE,
  fetchBookingStatus: StatusEnums.IDLE,
  bookings: null,
  booking: null,
  resError: null,
  addedResult: null
};
export const cancelledBookingsAsync = createAsyncThunk(
  "booking/cancelledBookings",
  async (params: any, thunkApi) => {
    try {
      const id = params[0];
      const response = await axiosInstance.put("/api/bookings/cancelled/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const receivedBookingAsync = createAsyncThunk(
  "booking/receivedBooking",
  async (params: any, thunkApi) => {
    try {
      const id = params[0];
      const response = await axiosInstance.put("/api/bookings/received/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const addBookingAsync = createAsyncThunk(
  "booking/addBooking",
  async (booking: IBookingCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/bookings/add", booking);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const deleteBookingsAsync = createAsyncThunk(
  "booking/deleteBookings",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/bookings/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const updateBookingAsync = createAsyncThunk(
  "booking/updateBooking",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/bookings/update/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const fetchBookingAsync = createAsyncThunk(
  "booking/fetchBooking",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/bookings/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const fetchBookingsAsync = createAsyncThunk(
  "booking/fetchBookings",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/bookings/search-admin", filters);
    return response.data;
  }
);
export const bookingsSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {

    resetFetchBookingsStatus: (state) => {
      state.fetchBookingsStatus = StatusEnums.IDLE;
    },
    resetReceivedBookingStatus: (state) => {
      state.receivedBookingStatus = StatusEnums.IDLE;
    },
    resetFetchBookingStatus: (state) => {
      state.fetchBookingStatus = StatusEnums.IDLE;
    },
    resetUpdateBookingStatus: (state) => {
      state.updateBookingStatus = StatusEnums.IDLE;
    },
    resetDeleteBookingsStatus: (state) => {
      state.deleteBookingsStatus = StatusEnums.IDLE;
    },
    resetAddBookingStatus: (state) => {
      state.addBookingStatus = StatusEnums.IDLE;
    },
    resetCancelledBookingsStatus: (state) => {
      state.cancelledBookingsStatus = StatusEnums.IDLE;
    },
    resetBookingStatus: (state) => {
      state.fetchBookingsStatus = StatusEnums.IDLE;
      state.fetchBookingStatus = StatusEnums.IDLE;
      state.updateBookingStatus = StatusEnums.IDLE;
      state.deleteBookingsStatus = StatusEnums.IDLE;
      state.addBookingStatus = StatusEnums.IDLE;
      state.cancelledBookingsStatus = StatusEnums.IDLE;
      state.receivedBookingStatus = StatusEnums.IDLE;
    }
  },
  extraReducers: (builder) => {
    builder
      // add booking
      .addCase(addBookingAsync.pending, (state) => {
        state.addBookingStatus = StatusEnums.LOADING;
      })
      .addCase(addBookingAsync.fulfilled, (state, action) => {
        state.addBookingStatus = StatusEnums.SUCCESS;
      })
      .addCase(addBookingAsync.rejected, (state, action) => {
        state.addBookingStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch bookings
      .addCase(fetchBookingsAsync.pending, (state, action) => {
        state.fetchBookingsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchBookingsAsync.fulfilled, (state, action) => {
        state.fetchBookingsStatus = StatusEnums.SUCCESS;
        state.bookings = action.payload as IPagination<IBooking>;
      })
      .addCase(fetchBookingsAsync.rejected, (state, action) => {
        state.fetchBookingsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch booking
      .addCase(fetchBookingAsync.pending, (state) => {
        state.fetchBookingStatus = StatusEnums.LOADING;
      })
      .addCase(fetchBookingAsync.fulfilled, (state, action) => {
        state.fetchBookingStatus = StatusEnums.SUCCESS;
        state.booking = action.payload;
      })
      .addCase(fetchBookingAsync.rejected, (state, action) => {
        state.fetchBookingStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update booking
      .addCase(updateBookingAsync.pending, (state) => {
        state.updateBookingStatus = StatusEnums.LOADING;
      })
      .addCase(updateBookingAsync.fulfilled, (state) => {
        state.updateBookingStatus = StatusEnums.SUCCESS;
        state.bookings = null;
      })
      .addCase(updateBookingAsync.rejected, (state, action) => {
        state.updateBookingStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // cancelled booking
      .addCase(cancelledBookingsAsync.pending, (state) => {
        state.cancelledBookingsStatus = StatusEnums.LOADING;
      })
      .addCase(cancelledBookingsAsync.fulfilled, (state) => {
        state.cancelledBookingsStatus = StatusEnums.SUCCESS;
        state.bookings = null;
      })
      .addCase(cancelledBookingsAsync.rejected, (state, action) => {
        state.cancelledBookingsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // received booking
      .addCase(receivedBookingAsync.pending, (state) => {
        state.receivedBookingStatus = StatusEnums.LOADING;
      })
      .addCase(receivedBookingAsync.fulfilled, (state) => {
        state.receivedBookingStatus = StatusEnums.SUCCESS;
        state.bookings = null;
      })
      .addCase(receivedBookingAsync.rejected, (state, action) => {
        state.receivedBookingStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchBookingsStatus, resetBookingStatus, resetDeleteBookingsStatus, resetAddBookingStatus, resetCancelledBookingsStatus, resetReceivedBookingStatus } = bookingsSlice.actions;
export const selectBookings = (state: RootState) => state.booking.bookings;
export const selectBooking = (state: RootState) => state.booking.booking;
export const selectUpdateBookingStatus = (state: RootState) => state.booking.updateBookingStatus;
export const selectFetchBookingsStatus = (state: RootState) => state.booking.fetchBookingsStatus;
export const selectFetchBookingStatus = (state: RootState) => state.booking.fetchBookingStatus;
export const selectDeleteBookingsStatus = (state: RootState) => state.booking.deleteBookingsStatus;
export const selectAddBookingStatus = (state: RootState) => state.booking.addBookingStatus;
export const selectCancelledBookingsStatus = (state: RootState) => state.booking.cancelledBookingsStatus;
export const selectReceivedBookingStatus = (state: RootState) => state.booking.receivedBookingStatus;

export default bookingsSlice.reducer;
