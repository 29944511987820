import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { INotificationCreate, Notification } from "../../types/notification";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IRelease, IReleaseCreate } from "../../types/release";

export interface ReleaseState {
  fetchReleasesStatus: StatusEnums;
  addReleaseStatus: StatusEnums;
  releases: IPagination<IRelease> | null,
  release: IRelease | null,
  resError: IResError | null,
  showFilters: boolean,

}

const initialState: ReleaseState = {
  fetchReleasesStatus: StatusEnums.IDLE,
  addReleaseStatus: StatusEnums.IDLE,
  release: null,
  releases: null,
  resError: null,
  showFilters: false,
};

export const fetchReleasesAsync = createAsyncThunk(
  "release/fetchReleases",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/releases/search", filters);
    return response.data;
  }
);
export const addReleaseAsync = createAsyncThunk(
  "release/addRelease",
  async (release: IReleaseCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/releases/add", release);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);



export const releaseSlice = createSlice({
  name: "release",
  initialState,
  reducers: {
    showReleaseFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetReleaseStatus: (state) => {
      state.fetchReleasesStatus = StatusEnums.IDLE;
      state.addReleaseStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
     
       // fetch activity 
       .addCase(fetchReleasesAsync.pending, (state, action) => {
        state.fetchReleasesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchReleasesAsync.fulfilled, (state, action) => {
        state.fetchReleasesStatus = StatusEnums.SUCCESS;
        state.releases = action.payload as IPagination<IRelease>;
      })
      .addCase(fetchReleasesAsync.rejected, (state, action) => {
        state.fetchReleasesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add notification
      .addCase(addReleaseAsync.pending, (state) => {
        state.addReleaseStatus = StatusEnums.LOADING;
      })
      .addCase(addReleaseAsync.fulfilled, (state, action) => {
        state.addReleaseStatus = StatusEnums.SUCCESS;
      })
      .addCase(addReleaseAsync.rejected, (state, action) => {
        state.addReleaseStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {  resetReleaseStatus, showReleaseFilters } = releaseSlice.actions;

export const selectReleases = (state: RootState) => state.release.releases;
export const selectRelease = (state: RootState) => state.release.release;
export const selectFetchReleasesStatus = (state: RootState) => state.release.fetchReleasesStatus;
export const selectAddReleaseStatus = (state: RootState) => state.release.addReleaseStatus;
export const selectShowReleaseFilters = (state: RootState) => state.release.showFilters;

export default releaseSlice.reducer;
