import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import { ITask, ITaskCreate } from "../../types/task";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface TaskState {
    deleteCreatedTasksStatus: StatusEnums;
    fetchCreatedTasksStatus: StatusEnums;
    fetchCreatedTaskStatus: StatusEnums;
    updateCreatedTaskStatus: StatusEnums;
    addCreatedTaskStatus: StatusEnums;
    createdTasks: IPagination<ITask> | null,
    createdTask: ITask | null,
    resError: IResError | null,
}

const initialState: TaskState = {
    deleteCreatedTasksStatus: StatusEnums.IDLE,
    fetchCreatedTasksStatus: StatusEnums.IDLE,
    fetchCreatedTaskStatus: StatusEnums.IDLE,
    addCreatedTaskStatus: StatusEnums.IDLE,
    updateCreatedTaskStatus: StatusEnums.IDLE,
    createdTasks: null,
    createdTask: null,
    resError: null,

};

export const deleteCreatedTasksAsync = createAsyncThunk(
    "createdTasks/deleteCreatedTasks",
    async (ids: string[], thunkApi) => {
        try {
            const response = await axiosInstance.post("/api/tasks/delete/many", { ids });
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const fetchCreatedTaskAsync = createAsyncThunk(
    "createdTask/fetchCreatedTask",
    async (id: string = "", thunkApi) => {
        try {
            const response = await axiosInstance.get("/api/tasks/detail/" + id);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addTaskAsync = createAsyncThunk(
    "createdTask/addTask",
    async (task: ITaskCreate, thunkApi) => {
        try {
            const response = await axiosInstance.post("/api/tasks/add", task);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const fetchCreatedTasksAsync = createAsyncThunk(
    "createdTasks/fetchCreatedTasks",
    async (filters?: IFilters) => {
        console.log("filters", filters)
        const response = await axiosInstance.post("/api/tasks/search", filters);
        return response.data;
    }
);

export const updateCreatedTaskAsync = createAsyncThunk(
    "createdTask/updateCreatedTask",
    async (params: any, thunkApi) => {
        try {
            const response = await axiosInstance.put("/api/tasks/update/" + params.id, params.payload);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const createdTasksSlice = createSlice({
    name: "createdTasks",
    initialState,
    reducers: {
        resetDeleteCreatedTasksStatus: (state) => {
            state.deleteCreatedTasksStatus = StatusEnums.IDLE;
        },
        resetFetchCreatedTasksStatus: (state) => {
            state.fetchCreatedTasksStatus = StatusEnums.IDLE;
        },
        resetAddCreatedTaskStatus: (state) => {
            state.addCreatedTaskStatus = StatusEnums.IDLE;
        },
        resetFetchCreatedTaskStatus: (state) => {
            state.fetchCreatedTaskStatus = StatusEnums.IDLE;
        },
        resetUpdateCreatedTaskStatus: (state) => {
            state.updateCreatedTaskStatus = StatusEnums.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder
            // delete Task 
            .addCase(deleteCreatedTasksAsync.pending, (state) => {
                state.deleteCreatedTasksStatus = StatusEnums.LOADING;
            })
            .addCase(deleteCreatedTasksAsync.fulfilled, (state) => {
                state.deleteCreatedTasksStatus = StatusEnums.SUCCESS;
            })
            .addCase(deleteCreatedTasksAsync.rejected, (state, action) => {
                state.deleteCreatedTasksStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // fetch Task
            .addCase(fetchCreatedTaskAsync.pending, (state, action) => {
                state.fetchCreatedTaskStatus = StatusEnums.LOADING;
            })
            .addCase(fetchCreatedTaskAsync.fulfilled, (state, action) => {
                state.fetchCreatedTaskStatus = StatusEnums.SUCCESS;
                state.createdTask = action.payload;
            })
            .addCase(fetchCreatedTaskAsync.rejected, (state, action) => {
                state.fetchCreatedTaskStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // add Task
            .addCase(addTaskAsync.pending, (state) => {
                state.addCreatedTaskStatus = StatusEnums.LOADING;
            })
            .addCase(addTaskAsync.fulfilled, (state, action) => {
                state.addCreatedTaskStatus = StatusEnums.SUCCESS;
            })
            .addCase(addTaskAsync.rejected, (state, action) => {
                state.addCreatedTaskStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // fetch Task
            .addCase(fetchCreatedTasksAsync.pending, (state, action) => {
                state.fetchCreatedTasksStatus = StatusEnums.LOADING;
            })
            .addCase(fetchCreatedTasksAsync.fulfilled, (state, action) => {
                state.fetchCreatedTasksStatus = StatusEnums.SUCCESS;
                state.createdTasks = action.payload as IPagination<ITask>;
            })
            .addCase(fetchCreatedTasksAsync.rejected, (state, action) => {
                state.fetchCreatedTasksStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // update Task
            .addCase(updateCreatedTaskAsync.pending, (state) => {
                state.updateCreatedTaskStatus = StatusEnums.LOADING;
            })
            .addCase(updateCreatedTaskAsync.fulfilled, (state, action) => {
                state.updateCreatedTaskStatus = StatusEnums.SUCCESS;
            })
            .addCase(updateCreatedTaskAsync.rejected, (state, action) => {
                state.updateCreatedTaskStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
    },
});

export const { resetDeleteCreatedTasksStatus, resetFetchCreatedTaskStatus, resetFetchCreatedTasksStatus, resetAddCreatedTaskStatus, resetUpdateCreatedTaskStatus } = createdTasksSlice.actions;


export const selectCreatedTasks = (state: RootState) => state.createdTask.createdTasks;
export const selectCreatedTask = (state: RootState) => state.createdTask.createdTask;
export const selectCreatedTaskCount = (state: RootState) => state.createdTask.createdTasks?.count;
export const selectFetchCreatedTasksStatus = (state: RootState) => state.createdTask.fetchCreatedTasksStatus;
export const selectUpdateCreatedTaskStatus = (state: RootState) => state.createdTask.updateCreatedTaskStatus;
export const selectAddCreatedTaskStatus = (state: RootState) => state.createdTask.addCreatedTaskStatus;
export const selectFetchCreatedTaskStatus = (state: RootState) => state.createdTask.fetchCreatedTaskStatus;
export const selectDeleteCreatedTasksStatus = (state: RootState) => state.createdTask.deleteCreatedTasksStatus;
export const selectTaskResError = (state: RootState) => state.createdTask.resError;

export default createdTasksSlice.reducer;
