import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IVoucher, IVoucherCreate } from "../../types/voucher";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface MissonState {
  deleteVouchersStatus: StatusEnums;
  fetchVouchersStatus: StatusEnums;
  fetchVoucherCrmsStatus: StatusEnums;
  fetchVoucherTypesStatus: StatusEnums;
  fetchVoucherStatus: StatusEnums;
  updateVoucherStatus: StatusEnums;
  addVoucherStatus: StatusEnums;
  addVoucherUserStatus: StatusEnums;
  vouchers: IPagination<IVoucher> | null,
  voucherTypes: IVoucher | null,
  voucher: IVoucher | null,
  resError: IResError | null,
}

const initialState: MissonState = {
  deleteVouchersStatus: StatusEnums.IDLE,
  fetchVouchersStatus: StatusEnums.IDLE,
  fetchVoucherCrmsStatus: StatusEnums.IDLE,
  fetchVoucherStatus: StatusEnums.IDLE,
  fetchVoucherTypesStatus: StatusEnums.IDLE,
  updateVoucherStatus: StatusEnums.IDLE,
  addVoucherStatus: StatusEnums.IDLE,
  addVoucherUserStatus: StatusEnums.IDLE,
  vouchers: null,
  voucherTypes: null,
  voucher: null,
  resError: null,

};

export const updateVoucherAsync = createAsyncThunk(
  "voucher/updateVoucher",
  async (params: any, thunkApi) => {
    try {
      const voucher = params.payload;
      const formData = new FormData();
      if (voucher.startedAt) {
        voucher.startedAt = new Date(voucher.startedAt.replace("T", " ")).toISOString();
        formData.append("startedAt", voucher.startedAt);

      }
      if (voucher.endedAt) {
        voucher.endedAt = new Date(voucher.endedAt.replace("T", " ")).toISOString();
        formData.append("endedAt", voucher.endedAt);
      }
      formData.append("name", voucher.name);
      formData.append("avatar", voucher.avatar);
      formData.append("description", voucher.description);
      formData.append("shortDes", voucher.shortDes);
      formData.append("toGroupId", voucher.toGroupId);
      formData.append("type", voucher.type);
      formData.append("discountType", voucher.discountType);
      formData.append("voucherBranch", JSON.stringify(voucher.voucherBranch));
      formData.append("voucherItem", JSON.stringify(voucher.voucherItem));
      formData.append("voucherRank", JSON.stringify(voucher.voucherRank));
      formData.append("totalAmount", voucher.totalAmount.toString());
      formData.append("playTimeAmount", voucher.playTimeAmount.toString());
      formData.append("maxDiscount", voucher.maxDiscount.toString());
      const response = await axiosInstance.put("/api/vouchers/update/" + params.id, formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteVouchersAsync = createAsyncThunk(
  "voucher/deleteVouchers",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/vouchers/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchVoucherAsync = createAsyncThunk(
  "voucher/fetchvoucher",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/vouchers/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addVoucherAsync = createAsyncThunk(
  "voucher/addVoucher",
  async (voucher: IVoucherCreate, thunkApi) => {
    try {
      const formData = new FormData();
      if (voucher.startedAt) {
        voucher.startedAt = new Date(voucher.startedAt.replace("T", " ")).toISOString();
        formData.append("startedAt", voucher.startedAt);

      }
      if (voucher.endedAt) {
        voucher.endedAt = new Date(voucher.endedAt.replace("T", " ")).toISOString();
        formData.append("endedAt", voucher.endedAt);
      }
      formData.append("name", voucher.name);
      formData.append("avatar", voucher.avatar);
      formData.append("description", voucher.description);
      formData.append("shortDes", voucher.shortDes);
      formData.append("toGroupId", voucher.toGroupId);
      formData.append("type", voucher.type);
      formData.append("discountType", voucher.discountType);
      formData.append("voucherBranch", JSON.stringify(voucher.voucherBranch));
      formData.append("voucherItem", JSON.stringify(voucher.voucherItem));
      formData.append("voucherRank", JSON.stringify(voucher.voucherRank));
      formData.append("totalAmount", voucher.totalAmount.toString());
      formData.append("playTimeAmount", voucher.playTimeAmount.toString());
      formData.append("maxDiscount", voucher.maxDiscount.toString());
      const response = await axiosInstance.post("/api/vouchers/add", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addVoucherUserAsync = createAsyncThunk(
  "voucher/addVoucherUser",
  async (voucher: IVoucherCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/vouchers/add-voucher", voucher);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const fetchVouchersAsync = createAsyncThunk(
  "voucher/fetchVoucher",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/vouchers/search-admin", filters);
    return response.data;
  }
);

export const fetchVoucherCrmsAsync = createAsyncThunk(
  "voucher/fetchVoucherCrm",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/vouchers/search-voucher-crm", filters);
    return response.data;
  }
);
export const fetchVoucherTypesAsync = createAsyncThunk(
  "voucher/fetchVoucherTypes",
  async () => {
    const response = await axiosInstance.get("/api/vouchers/get-voucher-type");
    return response.data;
  }
);

export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    resetDeleteVouchersStatus: (state) => {
      state.deleteVouchersStatus = StatusEnums.IDLE;
    },
    resetFetchVouchersStatus: (state) => {
      state.fetchVouchersStatus = StatusEnums.IDLE;
    },
    resetAddVoucherStatus: (state) => {
      state.addVoucherStatus = StatusEnums.IDLE;
    },
    resetAddVoucherUserStatus: (state) => {
      state.addVoucherUserStatus = StatusEnums.IDLE;
    },
    resetFetchVoucherStatus: (state) => {
      state.fetchVoucherStatus = StatusEnums.IDLE;
    },
    resetFetchVoucherCrmsStatus: (state) => {
      state.fetchVoucherCrmsStatus = StatusEnums.IDLE;
    },
    resetUpdateVoucherStatus: (state) => {
      state.updateVoucherStatus = StatusEnums.IDLE;
    },
    resetFetchVoucherTypesStatus: (state) => {
      state.fetchVoucherTypesStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // ipdate voucher
      .addCase(updateVoucherAsync.pending, (state) => {
        state.updateVoucherStatus = StatusEnums.LOADING;
      })
      .addCase(updateVoucherAsync.fulfilled, (state) => {
        state.updateVoucherStatus = StatusEnums.SUCCESS;
        state.vouchers = null;
      })
      .addCase(updateVoucherAsync.rejected, (state, action) => {
        state.updateVoucherStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete voucher 
      .addCase(deleteVouchersAsync.pending, (state) => {
        state.deleteVouchersStatus = StatusEnums.LOADING;
      })
      .addCase(deleteVouchersAsync.fulfilled, (state) => {
        state.deleteVouchersStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteVouchersAsync.rejected, (state, action) => {
        state.deleteVouchersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch voucher
      .addCase(fetchVoucherAsync.pending, (state, action) => {
        state.fetchVoucherStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVoucherAsync.fulfilled, (state, action) => {
        state.fetchVoucherStatus = StatusEnums.SUCCESS;
        state.voucher = action.payload;
      })
      .addCase(fetchVoucherAsync.rejected, (state, action) => {
        state.fetchVoucherStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add voucher
      .addCase(addVoucherAsync.pending, (state) => {
        state.addVoucherStatus = StatusEnums.LOADING;
      })
      .addCase(addVoucherAsync.fulfilled, (state, action) => {
        state.addVoucherStatus = StatusEnums.SUCCESS;
      })
      .addCase(addVoucherAsync.rejected, (state, action) => {
        state.addVoucherStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add voucher user
      .addCase(addVoucherUserAsync.pending, (state) => {
        state.addVoucherUserStatus = StatusEnums.LOADING;
      })
      .addCase(addVoucherUserAsync.fulfilled, (state, action) => {
        state.addVoucherUserStatus = StatusEnums.SUCCESS;
      })
      .addCase(addVoucherUserAsync.rejected, (state, action) => {
        state.addVoucherUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch voucher 
      .addCase(fetchVouchersAsync.pending, (state, action) => {
        state.fetchVouchersStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVouchersAsync.fulfilled, (state, action) => {
        state.fetchVouchersStatus = StatusEnums.SUCCESS;
        state.vouchers = action.payload as IPagination<IVoucher>;
      })
      .addCase(fetchVouchersAsync.rejected, (state, action) => {
        state.fetchVouchersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch voucher  crm
      .addCase(fetchVoucherCrmsAsync.pending, (state, action) => {
        state.fetchVoucherCrmsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVoucherCrmsAsync.fulfilled, (state, action) => {
        state.fetchVoucherCrmsStatus = StatusEnums.SUCCESS;
        state.vouchers = action.payload as IPagination<IVoucher>;
      })
      .addCase(fetchVoucherCrmsAsync.rejected, (state, action) => {
        state.fetchVoucherCrmsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch voucher  type
      .addCase(fetchVoucherTypesAsync.pending, (state, action) => {
        state.fetchVoucherTypesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVoucherTypesAsync.fulfilled, (state, action) => {
        state.fetchVoucherTypesStatus = StatusEnums.SUCCESS;
        state.voucherTypes = action.payload as IVoucher;
      })
      .addCase(fetchVoucherTypesAsync.rejected, (state, action) => {
        state.fetchVoucherTypesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchVoucherTypesStatus, resetDeleteVouchersStatus, resetFetchVoucherStatus, resetFetchVouchersStatus, resetUpdateVoucherStatus, resetAddVoucherStatus, resetFetchVoucherCrmsStatus, resetAddVoucherUserStatus } = voucherSlice.actions;

export const selectVouchers = (state: RootState) => state.voucher.vouchers;
export const selectVoucherTypes = (state: RootState) => state.voucher.voucherTypes;
export const selectVoucher = (state: RootState) => state.voucher.voucher;
export const selectVoucherCount = (state: RootState) => state.voucher.vouchers?.count;
export const selectFetchVouchersStatus = (state: RootState) => state.voucher.fetchVouchersStatus;
export const selectFetchVoucherTypesStatus = (state: RootState) => state.voucher.fetchVoucherTypesStatus;
export const selectFetchVoucherCrmsStatus = (state: RootState) => state.voucher.fetchVoucherCrmsStatus;
export const selectAddVoucherStatus = (state: RootState) => state.voucher.addVoucherStatus;
export const selectAddVoucherUserStatus = (state: RootState) => state.voucher.addVoucherUserStatus;
export const selectFetchVoucherStatus = (state: RootState) => state.voucher.fetchVoucherStatus;
export const selectDeleteVouchersStatus = (state: RootState) => state.voucher.deleteVouchersStatus;
export const selectVoucherResError = (state: RootState) => state.voucher.resError;
export const selectUpdateVoucherStatus = (state: RootState) => state.voucher.updateVoucherStatus;

export default voucherSlice.reducer;
