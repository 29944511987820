import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import {
  Alert,
  Autocomplete,
  Button, Checkbox, DialogActions,
  DialogContent, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Divider as MuiDivider, TextField as MuiTextField, Select
} from "@mui/material";
import { Loader } from "../../../components/LoaderContainer";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { StatusEnums } from "../../../enums/status-enum";
import useAppSelector from "../../../hooks/useAppSelector";
import { ITransactionCreate } from "../../../types/transaction";
import { addTransactionAsync, resetAddTransactionStatus, selectAddTransactionStatus, selectTransactionResError } from "../../../redux/slices/transaction";
import { useSnackbar } from "notistack";

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function AdminTransactionPage() {
  const { t } = useTranslation();
  const title = t("transaction")
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const addTransactionStatus = useAppSelector(selectAddTransactionStatus);
  const loading = addTransactionStatus === StatusEnums.LOADING;
  const resError = useAppSelector(selectTransactionResError);

  const validationSchema = Yup.object().shape({
    uploadedFile: Yup.mixed().required("Vui lòng chọn một file"),
    bankAccount: Yup.string().required("Vui lòng nhập chọn Bank Account"),
  });
  const formik = useFormik<any>({
    initialValues: {
      uploadedFile: undefined,
      bankAccount: "",
    },
    validationSchema,
    onSubmit: values => {
      dispatch(addTransactionAsync(values as ITransactionCreate));
    },
    enableReinitialize: true
  });

  useEffect(() => {
    if (addTransactionStatus === StatusEnums.SUCCESS) {
      dispatch(resetAddTransactionStatus());
      enqueueSnackbar('Tải lên dữ liệu thành công', { variant: 'success', preventDuplicate: true });
      formik.resetForm();
    }
    else if (addTransactionStatus === StatusEnums.FAILED) {
      enqueueSnackbar(resError?.error || t('createFailed', { name: t('transaction') }), { variant: 'error' });
    }
    if (addTransactionStatus !== StatusEnums.IDLE) {
      dispatch(resetAddTransactionStatus());
    }
  }, [resError, addTransactionStatus, dispatch, enqueueSnackbar])

  return (
    <React.Fragment>
      <Helmet title={title} />
      <PageTitle>
        {title}
      </PageTitle>
      <form noValidate onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <DialogContent sx={{ minWidth: 300, minHeight: 165 }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={3}>
              <FormControl fullWidth sx={{ margin: "12px 0 12px 0" }}
                error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)}>
                <InputLabel id="demo-simple-select-label" size="small">{t('Bank Account')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="bankAccount"
                  name="bankAccount"
                  value={formik.values.bankAccount}
                  onChange={formik.handleChange}
                  size="small"
                >
                  <MenuItem value="3703100941">{t('3703100941')}</MenuItem>
                  <MenuItem value="370310094002">{t('370310094002')}</MenuItem>
                  <MenuItem value="370310094003">{t('370310094003')}</MenuItem>
                  <MenuItem value="370310094004">{t('370310094004')}</MenuItem>
                  <MenuItem value="370310094005">{t('370310094005')}</MenuItem>
                  <MenuItem value="370310094006">{t('370310094006')}</MenuItem>
                </Select>
                <FormHelperText>
                  {`${formik.touched.bankAccount ? (formik.errors.bankAccount || '') : ''}`}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="file"
                name="uploaded_file"
                label="Tải lên file dữ liệu"
                value={formik.values.file}
                fullWidth
                onBlur={formik.handleBlur}
                onChange={event => formik.setFieldValue("uploadedFile", (event.target as HTMLInputElement).files?.[0])}
                my={3}
                size="small"
                InputLabelProps={{ shrink: true }}
                error={formik.touched.uploadedFile && Boolean(formik.errors.uploadedFile)}
                helperText={`${formik.touched.uploadedFile ? (formik.errors.uploadedFile || '') : ''}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {loading && <Loader size="small" />}&nbsp;
              <Button disabled={loading} size="small" type="submit" color="info" variant="contained" autoFocus>
                {t('import')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </React.Fragment>
  );
}

export default AdminTransactionPage;
