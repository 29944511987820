import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IArticle, IArticleCreate } from "../../types/article";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface ArticleState {
  deleteArticlesStatus: StatusEnums;
  fetchArticlesStatus: StatusEnums;
  addArticleStatus: StatusEnums;
  updateArticleStatus: StatusEnums;
  fetchArticleStatus: StatusEnums;
  articles: IPagination<IArticle> | null,
  article: IArticle | null,
  filters: IFilters | null,
  resError: IResError | null,
  showFilters: boolean,
}

const initialState: ArticleState = {
  deleteArticlesStatus: StatusEnums.IDLE,
  fetchArticlesStatus: StatusEnums.IDLE,
  addArticleStatus: StatusEnums.IDLE,
  updateArticleStatus: StatusEnums.IDLE,
  fetchArticleStatus: StatusEnums.IDLE,
  articles: null,
  article: null,
  filters: null,
  resError: null,
  showFilters: false,
};
export const deleteArticlesAsync = createAsyncThunk(
  "article/deleteArticles",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/articles/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchArticleAsync = createAsyncThunk(
  "article/fetchArticle",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/articles/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchArticlesAsync = createAsyncThunk(
  "article/fetchArticles",
  async (filters?: IFilters | null) => {
    const response = await axiosInstance.post("/api/articles/search", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const addArticleAsync = createAsyncThunk(
  "article/addArticle",
  async (article: IArticleCreate, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("categoryId", article.categoryId);
      formData.append("title", article.title);
      formData.append("shortDesc", article.shortDesc);
      formData.append("ctaScreen", article.ctaScreen);
      formData.append("ctaId", article.ctaId);
      formData.append("ctaText", article.ctaText);
      formData.append("description", article.description);
      formData.append("avatar", article.avatar);
      formData.append("linkText", article.linkText);
      formData.append("linkUrl", article.linkUrl);
      const response = await axiosInstance.post("/api/articles/add", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateArticleAsync = createAsyncThunk(
  "article/updateArticle",
  async (params: any, thunkApi) => {
    try {
      const article = params.payload;
      const formData = new FormData();
      formData.append("avatar", article.avatar);
      formData.append("title", article.title);
      formData.append("shortDesc", article.shortDesc);
      formData.append("description", article.description);
      formData.append("ctaScreen", article.ctaScreen);
      formData.append("ctaId", article.ctaId);
      formData.append("ctaText", article.ctaText);
      formData.append("linkText", article.linkText);
      formData.append("linkUrl", article.linkUrl);
      const response = await axiosInstance.put("/api/articles/update/" + params.id, formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    showArticleFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetArticleStatus: (state) => {
      state.fetchArticleStatus = StatusEnums.IDLE;
      state.addArticleStatus = StatusEnums.IDLE;
      state.fetchArticlesStatus = StatusEnums.IDLE;
      state.updateArticleStatus = StatusEnums.IDLE;
      state.deleteArticlesStatus = StatusEnums.IDLE;
    },
    resetFetchArticleStatus: (state) => {
      state.fetchArticleStatus = StatusEnums.IDLE;
    },
    resetUpdateArticleStatus: (state) => {
      state.updateArticleStatus = StatusEnums.IDLE;
    },
    resetAddArticleStatus: (state) => {
      state.addArticleStatus = StatusEnums.IDLE;
    },
    setArticleFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // delete article 
      .addCase(deleteArticlesAsync.pending, (state) => {
        state.deleteArticlesStatus = StatusEnums.LOADING;
      })
      .addCase(deleteArticlesAsync.fulfilled, (state) => {
        state.deleteArticlesStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteArticlesAsync.rejected, (state, action) => {
        state.deleteArticlesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch article 
      .addCase(fetchArticlesAsync.pending, (state) => {
        state.fetchArticlesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchArticlesAsync.fulfilled, (state, action) => {
        state.fetchArticlesStatus = StatusEnums.SUCCESS;
        state.articles = action.payload as IPagination<IArticle>;
      })
      .addCase(fetchArticlesAsync.rejected, (state, action) => {
        state.fetchArticlesStatus = StatusEnums.FAILED;
      })
      // add article
      .addCase(addArticleAsync.pending, (state) => {
        state.addArticleStatus = StatusEnums.LOADING;
      })
      .addCase(addArticleAsync.fulfilled, (state, action) => {
        state.addArticleStatus = StatusEnums.SUCCESS;
      })
      .addCase(addArticleAsync.rejected, (state, action) => {
        state.addArticleStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch article
      .addCase(fetchArticleAsync.pending, (state) => {
        state.fetchArticleStatus = StatusEnums.LOADING;
      })
      .addCase(fetchArticleAsync.fulfilled, (state, action) => {
        state.fetchArticleStatus = StatusEnums.SUCCESS;
        state.article = action.payload;
      })
      .addCase(fetchArticleAsync.rejected, (state, action) => {
        state.fetchArticleStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update article
      .addCase(updateArticleAsync.pending, (state) => {
        state.updateArticleStatus = StatusEnums.LOADING;
      })
      .addCase(updateArticleAsync.fulfilled, (state) => {
        state.updateArticleStatus = StatusEnums.SUCCESS;
        state.article = null;
      })
      .addCase(updateArticleAsync.rejected, (state, action) => {
        state.updateArticleStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      ;
  },
});

export const { resetArticleStatus, resetUpdateArticleStatus, resetFetchArticleStatus, setArticleFilters, showArticleFilters, resetAddArticleStatus } = articleSlice.actions;


export const selectArticles = (state: RootState) => state.article.articles;
export const selectArticleFilters = (state: RootState) => state.article.articles?.filters;
export const selectDeleteArticlesStatus = (state: RootState) => state.article.deleteArticlesStatus;
export const selectFetchArticlesStatus = (state: RootState) => state.article.fetchArticlesStatus;
export const selectAddArticleStatus = (state: RootState) => state.article.addArticleStatus;
export const selectFetchArticleStatus = (state: RootState) => state.article.fetchArticleStatus;
export const selectUpdateArticleStatus = (state: RootState) => state.article.updateArticleStatus;
export const selectArticle = (state: RootState) => state.article.article;
export const selectArticleResError = (state: RootState) => state.article.resError;
export const selectShowArticleFilters = (state: RootState) => state.article.showFilters;

export default articleSlice.reducer;
