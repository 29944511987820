import { DevicesOtherOutlined, Feed, Notifications } from "@mui/icons-material";
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FlagIcon from '@mui/icons-material/Flag';
import GroupsIcon from '@mui/icons-material/Groups';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PlaceIcon from '@mui/icons-material/Place';
import { Users } from "react-feather";
import DiscountIcon from '@mui/icons-material/Discount';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
const gneralSection = [
  {
    href: "/manager-layout/articles",
    icon: Feed,
    title: "article",
  },
  {
    href: "/manager-layout/notifications",
    icon: Notifications,
    title: "notification",
  },
  {
    href: "/manager-layout/missions",
    icon: FlagIcon,
    title: "mission",
  },
  {
    href: "/manager-layout/vouchers",
    icon: ConfirmationNumberIcon,
    title: "voucher",
  },
  {
    href: "/manager-layout/groups",
    icon: GroupsIcon,
    title: "group",
  },
  {
    href: "/manager-layout/bookings",
    icon: AddShoppingCartIcon,
    title: "booking",
  },
  {
    href: "/manager-layout/feedbacks",
    icon: FeedbackIcon,
    title: "feedback",
  },
]
const navItems = [
  {
    title: "",
    pages: gneralSection,
  },
];

export default navItems;
