import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IPoint } from "../../types/point";

export interface RankPointState {
  fetchRankPointsStatus: StatusEnums;
  rankPoints: IPagination<IPoint> | null,
  rankPoint: IPoint | null,
  filters: IFilters | null,
  resError: IResError | null,
}

const initialState: RankPointState = {
  fetchRankPointsStatus: StatusEnums.IDLE,
  rankPoints: null,
  rankPoint: null,
  filters: null,
  resError: null,
};

export const fetchRankPointsAsync = createAsyncThunk(
  "point/fetchRankPoints",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/rank-points/search-admin", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const rankPointSlice = createSlice({
  name: "rankPoint",
  initialState,
  reducers: {
    resetFetchRankPointsStatus: (state) => {
      state.fetchRankPointsStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // validate user
      .addCase(fetchRankPointsAsync.pending, (state) => {
        state.fetchRankPointsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchRankPointsAsync.fulfilled, (state, action) => {
        state.fetchRankPointsStatus = StatusEnums.SUCCESS;
        state.rankPoints = action.payload as IPagination<IPoint>;
      })
      .addCase(fetchRankPointsAsync.rejected, (state, action) => {
        state.fetchRankPointsStatus = StatusEnums.FAILED;
      })
      ;
  },
});

export const { resetFetchRankPointsStatus } = rankPointSlice.actions;

export const selectRankPoints = (state: RootState) => state.rankPoint.rankPoints;
export const selectRankPoint = (state: RootState) => state.rankPoint.rankPoint;
export const selectFetchRankPointsStatus = (state: RootState) => state.rankPoint.fetchRankPointsStatus;
export const selectRankPointResError = (state: RootState) => state.rankPoint.resError;

export default rankPointSlice.reducer;
