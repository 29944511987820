import styled from "@emotion/styled";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import {
  Button, DialogActions,
  DialogContent, IconButton, InputAdornment, TextField as MuiTextField
} from "@mui/material";
import { spacing } from "@mui/system";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StatusEnums } from "../enums/status-enum";
import useAppDispatch from "../hooks/useAppDispatch";
import useAppSelector from "../hooks/useAppSelector";
import { changePasswordAsync, fetchUsersAsync, resetChangePasswordStatus, selectChangePasswordStatus, selectUserResError } from "../redux/slices/user";
import CustomDialog from "./CustomDialog";
import { Loader } from "./LoaderContainer";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


type Props = {
  open: boolean;
  onClose: () => void;
}
function ChangePassDialog({ open, onClose }: Props) {
  const { t } = useTranslation();
  const [showPass, setShowPass] = useState() as any;
  const showOldPass = showPass === "oldPass";
  const showNewPass = showPass === "newPass";
  const showConfirmPass = showPass === "confirmPass";
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const changePasswordStatus = useAppSelector(selectChangePasswordStatus);
  const resError = useAppSelector(selectUserResError);
  const loading = changePasswordStatus === StatusEnums.LOADING;
  const validationSchema = Yup.object().shape({
    oldPass: Yup.string().required(t('oldPasswordRequired')),
    newPass: Yup.string().required(t('newPasswordRequired')),
    confirmPass: Yup.string().required(t('retypeNewPasswordRequired')).when("newPass", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPass")],
        t('passwordNotMatch')
      ),
    }),
  });
  const formik = useFormik<any>({
    initialValues: {
      oldPass: "",
      newPass: "",
      confirmPass: "",
    },
    validationSchema,
    onSubmit: values => {
      dispatch(changePasswordAsync(values));
    },
    enableReinitialize: true
  });

  useEffect(() => {
    if (changePasswordStatus === StatusEnums.SUCCESS) {
      dispatch(fetchUsersAsync());
      enqueueSnackbar(t('changePasswordSuccess'), { variant: 'success' });
      onClose();
    }
    else if (changePasswordStatus === StatusEnums.FAILED) {
      enqueueSnackbar(resError?.error || t('changePasswordFailed'), { variant: 'error' });
    }
    if (![StatusEnums.LOADING, StatusEnums.IDLE].includes(changePasswordStatus))
      dispatch(resetChangePasswordStatus())
  }, [changePasswordStatus, resError, dispatch, onClose, enqueueSnackbar])

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={t('changePassword')}
      width={500}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ minWidth: 300, minHeight: 100 }}>
          <TextField
            type={showOldPass ? "text" : "password"}
            name="oldPass"
            label={t('oldPassword')}
            value={formik.values.password}
            error={Boolean(formik.touched.oldPass && formik.errors.oldPass)}
            fullWidth
            helperText={`${formik.touched.oldPass ? (formik.errors.oldPass || '') : ''}`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            my={3}
            size="small"
            InputProps={{
              endAdornment:
                < InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(showOldPass ? "" : "oldPass")}
                    edge="end"
                  >
                    {showOldPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>

            }}
          />
          <TextField
            type={showNewPass ? "text" : "password"}
            name="newPass"
            label={t('newPassword')}
            value={formik.values.newPass}
            error={Boolean(formik.touched.newPass && formik.errors.newPass)}
            fullWidth
            helperText={`${formik.touched.newPass ? (formik.errors.newPass || '') : ''}`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            my={3}
            size="small"
            InputProps={{
              endAdornment:
                < InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(showNewPass ? "" : "newPass")}
                    edge="end"
                  >
                    {showNewPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          <TextField
            type={showConfirmPass ? "text" : "password"}
            name="confirmPass"
            label={t('retypeNewPassword')}
            value={formik.values.confirmPass}
            error={Boolean(formik.touched.confirmPass && formik.errors.confirmPass)}
            fullWidth
            helperText={`${formik.touched.confirmPass ? (formik.errors.confirmPass || '') : ''}`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            my={3}
            size="small"
            InputProps={{
              endAdornment:
                < InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(showConfirmPass ? "" : "confirmPass")}
                    edge="end"
                  >
                    {showConfirmPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
        </DialogContent>
        <DialogActions>
          {loading && <Loader size="small" />}&nbsp;
          <Button disabled={loading} onClick={onClose} variant="text" size="small">
            {t('cancel')}
          </Button>
          <Button disabled={loading} type="submit" color="info" variant="contained" autoFocus size="small">
            {t('change')}
          </Button>
        </DialogActions>
      </form>
    </CustomDialog >
  );
}

export default ChangePassDialog;
