import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IFeedback, IFeedbackCreate } from "../../types/feedback";

export interface FeedbackState {
  addFeedbackStatus: StatusEnums;
  fetchFeedbacksStatus: StatusEnums;
  feedbacks: IPagination<IFeedback> | null,
  feedback: IFeedback | null,
  resError: IResError | null,
}

const initialState: FeedbackState = {
  addFeedbackStatus: StatusEnums.IDLE,
  fetchFeedbacksStatus: StatusEnums.IDLE,
  feedbacks: null,
  feedback: null,
  resError: null,

};
export const fetchFeedbacksAsync = createAsyncThunk(
  "feedback/fetchFeedbacks",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/feed-backs/search", filters);
    return response.data;
  }
);
export const addFeedbackAsync = createAsyncThunk(
  "feedback/addFeedback",
  async (feedback: IFeedbackCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/feed-backs/add", feedback);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    resetAddFeedbackStatus: (state) => {
      state.addFeedbackStatus = StatusEnums.IDLE;
    },
    resetFetchFeedbacksStatus: (state) => {
      state.fetchFeedbacksStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch feedback 
      .addCase(fetchFeedbacksAsync.pending, (state, action) => {
        state.fetchFeedbacksStatus = StatusEnums.LOADING;
      })
      .addCase(fetchFeedbacksAsync.fulfilled, (state, action) => {
        state.fetchFeedbacksStatus = StatusEnums.SUCCESS;
        state.feedbacks = action.payload as IPagination<IFeedback>;
      })
      .addCase(fetchFeedbacksAsync.rejected, (state, action) => {
        state.fetchFeedbacksStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add feedback
      .addCase(addFeedbackAsync.pending, (state) => {
        state.addFeedbackStatus = StatusEnums.LOADING;
      })
      .addCase(addFeedbackAsync.fulfilled, (state, action) => {
        state.addFeedbackStatus = StatusEnums.SUCCESS;
      })
      .addCase(addFeedbackAsync.rejected, (state, action) => {
        state.addFeedbackStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetAddFeedbackStatus, resetFetchFeedbacksStatus } = feedbackSlice.actions;


export const selectFeedbacks = (state: RootState) => state.feedback.feedbacks;
export const selectFeedback = (state: RootState) => state.feedback.feedback;
export const selectAddFeedbackStatus = (state: RootState) => state.feedback.addFeedbackStatus;
export const selectFetchFeedbacksStatus = (state: RootState) => state.feedback.fetchFeedbacksStatus;
export const selectFeedbackResError = (state: RootState) => state.feedback.resError;

export default feedbackSlice.reducer;
