import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IMissionProcess } from "../../types/mission-process";
import { IApproveMission } from "../../types/approve-mission";

export interface ApproveMissonsState {
  fetchApproveMissionsStatus: StatusEnums;
  updateApproveMissionStatus: StatusEnums;
  rejectApproveMissionStatus: StatusEnums;
  approveMissions: IPagination<IApproveMission> | null,
  approveMission: IApproveMission | null,
  resError: IResError | null,
  filters: IFilters | null,

}

const initialState: ApproveMissonsState = {
  fetchApproveMissionsStatus: StatusEnums.IDLE,
  updateApproveMissionStatus: StatusEnums.IDLE,
  rejectApproveMissionStatus: StatusEnums.IDLE,
  approveMissions: null,
  approveMission: null,
  resError: null,
  filters: null,

};

export const fetchApproveMissionsAsync = createAsyncThunk(
  "missionProcess/fetchApproveMissions",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/missions/search-mission-manual-admin", filters);
    return response.data;
  }
);


export const updateApproveMissionAsync = createAsyncThunk(
  "missionProcess/updateApproveMission",
  async (id: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/missions/update-manual/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const rejectApproveMissionAsync = createAsyncThunk(
  "missionProcess/rejectApproveMission",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/missions/reject-manual/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const approveMissionSlice = createSlice({
  name: "approveMission",
  initialState,
  reducers: {
    resetFetchApproveMissionsStatus: (state) => {
      state.fetchApproveMissionsStatus = StatusEnums.IDLE;
    },
    resetUpdateApproveMissionStatus: (state) => {
      state.updateApproveMissionStatus = StatusEnums.IDLE;
    },
    resetRejectApproveMissionStatus: (state) => {
      state.rejectApproveMissionStatus = StatusEnums.IDLE;
    },
    setMissionFilters: (state, action) => {
      state.filters = { ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      // update apprve mission
      .addCase(updateApproveMissionAsync.pending, (state) => {
        state.updateApproveMissionStatus = StatusEnums.LOADING;
      })
      .addCase(updateApproveMissionAsync.fulfilled, (state) => {
        state.updateApproveMissionStatus = StatusEnums.SUCCESS;
        state.approveMissions = null;
      })
      .addCase(updateApproveMissionAsync.rejected, (state, action) => {
        state.updateApproveMissionStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
       // fetch approve mission 
       .addCase(fetchApproveMissionsAsync.pending, (state, action) => {
        state.fetchApproveMissionsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchApproveMissionsAsync.fulfilled, (state, action) => {
        state.fetchApproveMissionsStatus = StatusEnums.SUCCESS;
        state.approveMissions = action.payload;
      })
      .addCase(fetchApproveMissionsAsync.rejected, (state, action) => {
        state.fetchApproveMissionsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // reject apprve mission
      .addCase(rejectApproveMissionAsync.pending, (state) => {
        state.rejectApproveMissionStatus = StatusEnums.LOADING;
      })
      .addCase(rejectApproveMissionAsync.fulfilled, (state) => {
        state.rejectApproveMissionStatus = StatusEnums.SUCCESS;
        state.approveMissions = null;
      })
      .addCase(rejectApproveMissionAsync.rejected, (state, action) => {
        state.rejectApproveMissionStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetRejectApproveMissionStatus, resetUpdateApproveMissionStatus, resetFetchApproveMissionsStatus, setMissionFilters } = approveMissionSlice.actions;

export const selectApproveMissions = (state: RootState) => state.approveMission.approveMissions;
export const selectApproveMission = (state: RootState) => state.approveMission.approveMission;
export const selectApproveMissionCount = (state: RootState) => state.approveMission.approveMissions?.count;
export const selectFetchApproveMissionsStatus = (state: RootState) => state.approveMission.fetchApproveMissionsStatus;
export const selectMissionProcessResError = (state: RootState) => state.approveMission.resError;
export const selectUpdateApproveMissionStatus = (state: RootState) => state.approveMission.updateApproveMissionStatus;
export const selectRejectApproveMissionStatus = (state: RootState) => state.approveMission.rejectApproveMissionStatus;
export default approveMissionSlice.reducer;
