import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { ITransactionCreate } from "../../types/transaction";

export interface TransactionState {
  addTransactionStatus: StatusEnums;
  resError: IResError | null,
  addedResult: { id: string, paht: string } | null
}

const initialState: TransactionState = {
  addTransactionStatus: StatusEnums.IDLE,
  resError: null,
  addedResult: null
};

export const addTransactionAsync = createAsyncThunk(
  "transaction/addTransaction",
  async (transaction: ITransactionCreate, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("uploadedFile", transaction.uploadedFile);
      formData.append("bankAccount", transaction.bankAccount);
      const response = await axiosInstance.post("/api/transactions/add", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const transactionsSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    resetAddTransactionStatus: (state) => {
      state.addTransactionStatus = StatusEnums.IDLE;
    }
  },
  extraReducers: (builder) => {
    builder
      // add booking
      .addCase(addTransactionAsync.pending, (state) => {
        state.addTransactionStatus = StatusEnums.LOADING;
      })
      .addCase(addTransactionAsync.fulfilled, (state, action) => {
        state.addTransactionStatus = StatusEnums.SUCCESS;
      })
      .addCase(addTransactionAsync.rejected, (state, action) => {
        state.addTransactionStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetAddTransactionStatus } = transactionsSlice.actions;
export const selectAddTransactionStatus = (state: RootState) => state.transaction.addTransactionStatus;
export const selectTransactionResError = (state: RootState) => state.transaction.resError;

export default transactionsSlice.reducer;
