import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IPoint, IPointCreate } from "../../types/point";

export interface PointState {
  fetchPointsStatus: StatusEnums;
  fetchManualPointsStatus: StatusEnums;
  addPointStatus: StatusEnums;
  points: IPagination<IPoint> | null,
  point: IPoint | null,
  filters: IFilters | null,
  resError: IResError | null,
}

const initialState: PointState = {
  fetchPointsStatus: StatusEnums.IDLE,
  fetchManualPointsStatus: StatusEnums.IDLE,
  addPointStatus: StatusEnums.IDLE,
  points: null,
  point: null,
  filters: null,
  resError: null,
};

export const fetchPointsAsync = createAsyncThunk(
  "point/fetchPoints",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/points/search-admin", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchManualPointsAsync = createAsyncThunk(
  "point/fetchManualPoints",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/points/search-manual", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const addPointAsync = createAsyncThunk(
  "point/addPoint",
  async (point: IPointCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/points/add", point);
      console.log("response.data",response.data)
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const pointSlice = createSlice({
  name: "point",
  initialState,
  reducers: {
    resetFetchPointsStatus: (state) => {
      state.fetchPointsStatus = StatusEnums.IDLE;
    },
    resetFetchManualPointsStatus: (state) => {
      state.fetchManualPointsStatus = StatusEnums.IDLE;
    },
    resetAddPointStatus: (state) => {
      state.addPointStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // points
      .addCase(fetchPointsAsync.pending, (state) => {
        state.fetchPointsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchPointsAsync.fulfilled, (state, action) => {
        state.fetchPointsStatus = StatusEnums.SUCCESS;
        state.points = action.payload as IPagination<IPoint>;
      })
      .addCase(fetchPointsAsync.rejected, (state, action) => {
        state.fetchPointsStatus = StatusEnums.FAILED;
      })
      // manual points
      .addCase(fetchManualPointsAsync.pending, (state) => {
        state.fetchManualPointsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchManualPointsAsync.fulfilled, (state, action) => {
        state.fetchManualPointsStatus = StatusEnums.SUCCESS;
        state.points = action.payload as IPagination<IPoint>;
      })
      .addCase(fetchManualPointsAsync.rejected, (state, action) => {
        state.fetchManualPointsStatus = StatusEnums.FAILED;
      })
      // add point
      .addCase(addPointAsync.pending, (state) => {
        state.addPointStatus = StatusEnums.LOADING;
      })
      .addCase(addPointAsync.fulfilled, (state, action) => {
        state.addPointStatus = StatusEnums.SUCCESS;
      })
      .addCase(addPointAsync.rejected, (state, action) => {
        state.addPointStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchPointsStatus, resetFetchManualPointsStatus, resetAddPointStatus } = pointSlice.actions;

export const selectPoints = (state: RootState) => state.point.points;
export const selectPoint = (state: RootState) => state.point.point;
export const selectFetchPointsStatus = (state: RootState) => state.point.fetchPointsStatus;
export const selectFetchManualPointsStatus = (state: RootState) => state.point.fetchManualPointsStatus;
export const selectAddPointStatus = (state: RootState) => state.point.addPointStatus;
export const selectPointResError = (state: RootState) => state.point.resError;

export default pointSlice.reducer;
