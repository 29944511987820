import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IAttachment } from "../../types/attachment";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface AttachmentState {
  addAttachmentStatus: StatusEnums;
  deleteAttachmentsStatus: StatusEnums;
  fetchAttachmentsStatus: StatusEnums;
  attachments: IPagination<IAttachment> | null,
  resError: IResError | null,
  addedResult: { id: string, paht: string } | null
}

const initialState: AttachmentState = {
  addAttachmentStatus: StatusEnums.IDLE,
  deleteAttachmentsStatus: StatusEnums.IDLE,
  fetchAttachmentsStatus: StatusEnums.IDLE,
  attachments: null,
  resError: null,
  addedResult: null

};

export const addAttachmentAsync = createAsyncThunk(
  "attachments/addAttachment",
  async (payload: FormData, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/attachments/add", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteAttachmentsAsync = createAsyncThunk(
  "attachments/deleteAttachments",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/attachments/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchAttachmentsAsync = createAsyncThunk(
  "attachments/fetchAttachments",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/attachments/search", filters);
    return response.data;
  }
);


export const attachmentSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    resetAddAttachmentStatus: (state) => {
      state.addAttachmentStatus = StatusEnums.IDLE;
      state.addedResult = null;
    },
    resetFetchAttachmentsStatus: (state) => {
      state.fetchAttachmentsStatus = StatusEnums.IDLE;
    },
    resetDeleteAttachmentsStatus: (state) => {
      state.deleteAttachmentsStatus = StatusEnums.IDLE;
    },

  },
  extraReducers: (builder) => {
    builder
      // add attachments
      .addCase(addAttachmentAsync.pending, (state) => {
        state.addAttachmentStatus = StatusEnums.LOADING;
      })
      .addCase(addAttachmentAsync.fulfilled, (state, action) => {
        state.addAttachmentStatus = StatusEnums.SUCCESS;
        state.addedResult = action.payload
      })
      .addCase(addAttachmentAsync.rejected, (state, action) => {
        state.addAttachmentStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete attachments 
      .addCase(deleteAttachmentsAsync.pending, (state) => {
        state.deleteAttachmentsStatus = StatusEnums.LOADING;
      })
      .addCase(deleteAttachmentsAsync.fulfilled, (state) => {
        state.deleteAttachmentsStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteAttachmentsAsync.rejected, (state, action) => {
        state.deleteAttachmentsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch attachments 
      .addCase(fetchAttachmentsAsync.pending, (state, action) => {
        state.fetchAttachmentsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchAttachmentsAsync.fulfilled, (state, action) => {
        state.fetchAttachmentsStatus = StatusEnums.SUCCESS;
        state.attachments = action.payload as IPagination<IAttachment>;
      })
      .addCase(fetchAttachmentsAsync.rejected, (state, action) => {
        state.fetchAttachmentsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetDeleteAttachmentsStatus, resetFetchAttachmentsStatus } = attachmentSlice.actions;


export const selectAttachments = (state: RootState) => state.attachment.attachments;
export const selectAttachmentCount = (state: RootState) => state.attachment.attachments?.count;
export const selectAddedResult = (state: RootState) => state.attachment.addedResult;

export const selectDeleteAttachmentsStatus = (state: RootState) => state.attachment.deleteAttachmentsStatus;
export const selectFetchAttachmentsStatus = (state: RootState) => state.attachment.fetchAttachmentsStatus;
export const selectAddAttachmentStatus = (state: RootState) => state.attachment.addAttachmentStatus;

export default attachmentSlice.reducer;
