import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IActivity, IActivityCreate } from "../../types/activity";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface ActivityState {
  deleteActivitysStatus: StatusEnums;
  fetchActivitysStatus: StatusEnums;
  fetchActivityStatus: StatusEnums;
  addActivityStatus: StatusEnums;
  activitys: IPagination<IActivity> | null,
  activity: IActivity | null,
  resError: IResError | null,
}

const initialState: ActivityState = {
  deleteActivitysStatus: StatusEnums.IDLE,
  fetchActivitysStatus: StatusEnums.IDLE,
  fetchActivityStatus: StatusEnums.IDLE,
  addActivityStatus: StatusEnums.IDLE,
  activitys: null,
  activity: null,
  resError: null,

};

export const deleteActivitysAsync = createAsyncThunk(
  "activitys/deleteActivitys",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/activities/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchActivityAsync = createAsyncThunk(
  "activity/fetchActivity",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/activities/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addActivityAsync = createAsyncThunk(
  "activity/addActivity",
  async (activity: IActivityCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/activities/add", activity);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchActivitysAsync = createAsyncThunk(
  "activitys/fetchActivitys",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/activities/search", filters);
    return response.data;
  }
);


export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    resetDeleteActivitysStatus: (state) => {
      state.deleteActivitysStatus = StatusEnums.IDLE;
    },
    resetFetchActivitysStatus: (state) => {
      state.fetchActivitysStatus = StatusEnums.IDLE;
    },
    resetAddActivityStatus: (state) => {
      state.addActivityStatus = StatusEnums.IDLE;
    },
    resetFetchActivityStatus: (state) => {
      state.fetchActivityStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // delete activity 
      .addCase(deleteActivitysAsync.pending, (state) => {
        state.deleteActivitysStatus = StatusEnums.LOADING;
      })
      .addCase(deleteActivitysAsync.fulfilled, (state) => {
        state.deleteActivitysStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteActivitysAsync.rejected, (state, action) => {
        state.deleteActivitysStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch activity
      .addCase(fetchActivityAsync.pending, (state, action) => {
        state.fetchActivityStatus = StatusEnums.LOADING;
      })
      .addCase(fetchActivityAsync.fulfilled, (state, action) => {
        state.fetchActivityStatus = StatusEnums.SUCCESS;
        state.activity = action.payload;
      })
      .addCase(fetchActivityAsync.rejected, (state, action) => {
        state.fetchActivityStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add activity
      .addCase(addActivityAsync.pending, (state) => {
        state.addActivityStatus = StatusEnums.LOADING;
      })
      .addCase(addActivityAsync.fulfilled, (state, action) => {
        state.addActivityStatus = StatusEnums.SUCCESS;
      })
      .addCase(addActivityAsync.rejected, (state, action) => {
        state.addActivityStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch activity 
      .addCase(fetchActivitysAsync.pending, (state, action) => {
        state.fetchActivitysStatus = StatusEnums.LOADING;
      })
      .addCase(fetchActivitysAsync.fulfilled, (state, action) => {
        state.fetchActivitysStatus = StatusEnums.SUCCESS;
        state.activitys = action.payload as IPagination<IActivity>;
      })
      .addCase(fetchActivitysAsync.rejected, (state, action) => {
        state.fetchActivitysStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetDeleteActivitysStatus, resetFetchActivityStatus, resetFetchActivitysStatus, resetAddActivityStatus } = activitySlice.actions;

export const selectActivitys = (state: RootState) => state.activity.activitys;
export const selectActivity = (state: RootState) => state.activity.activity;
export const selectActivityCount = (state: RootState) => state.activity.activitys?.count;
export const selectFetchActivitysStatus = (state: RootState) => state.activity.fetchActivitysStatus;
export const selectAddActivityStatus = (state: RootState) => state.activity.addActivityStatus;
export const selectFetchActivityStatus = (state: RootState) => state.activity.fetchActivityStatus;
export const selectDeleteActivitysStatus = (state: RootState) => state.activity.deleteActivitysStatus;
export const selectActivityResError = (state: RootState) => state.activity.resError;

export default activitySlice.reducer;
