import { io, Socket } from "socket.io-client";
import { AppDispatch } from "../redux/store";
let socket: Socket | null = null;
const connect = (dispatch: AppDispatch) => {
    socket = io(process.env.REACT_APP_SOCKET + "/socket" || "", {
        transports: ['websocket'],
        reconnectionDelayMax: 10000,
        auth: {
            token: localStorage.getItem("ArenaToken")
        }
    });

    socket.on("billard-tables", (data) => {
        console.log("socket data", data);
    })

    socket.io.on("open", () => {
        console.log('Socket connected 2')
    })

}

const disconnect = () => {
    socket?.disconnect();
}

const getSocket = () => {
    return socket;
}

export default {
    getSocket,
    connect,
    disconnect
}