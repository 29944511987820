import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IVacationType } from "../../types/vacation-type";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface VacationTypeState {
  fetchVacationTypesStatus: StatusEnums;
  vacationTypes: IPagination<IVacationType> | null,
  resError: IResError | null,
}

const initialState: VacationTypeState = {
  fetchVacationTypesStatus: StatusEnums.IDLE,
  vacationTypes: null,
  resError: null,

};

export const fetchVacationTypesAsync = createAsyncThunk(
  "vacationTypes/fetchVacationTypes",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/vacation-types/search", filters);
    return response.data;
  }
);


export const vacationTypeSlice = createSlice({
  name: "vacationTypes",
  initialState,
  reducers: {
    resetFetchVacationTypesStatus: (state) => {
      state.fetchVacationTypesStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch vacationTypes 
      .addCase(fetchVacationTypesAsync.pending, (state, action) => {
        state.fetchVacationTypesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVacationTypesAsync.fulfilled, (state, action) => {
        state.fetchVacationTypesStatus = StatusEnums.SUCCESS;
        state.vacationTypes = action.payload as IPagination<IVacationType>;
      })
      .addCase(fetchVacationTypesAsync.rejected, (state, action) => {
        state.fetchVacationTypesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchVacationTypesStatus } = vacationTypeSlice.actions;


export const selectVacationTypes = (state: RootState) => state.vacationType.vacationTypes;
export const selectVacationTypeCount = (state: RootState) => state.vacationType.vacationTypes?.count;
export const selectFetchVacationTypesStatus = (state: RootState) => state.vacationType.fetchVacationTypesStatus;

export default vacationTypeSlice.reducer;
