import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IResError } from "../../types/res-error";
import { IShift } from "../../types/shift";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface ShiftState {
  fetchShiftsStatus: StatusEnums;
  fetchFilterShiftsStatus: StatusEnums;
  shifts: IShift[] | null,
  filterShifts: IShift[] | null,
  shift: IShift | null,
  resError: IResError | null,
}

const initialState: ShiftState = {
  fetchShiftsStatus: StatusEnums.IDLE,
  fetchFilterShiftsStatus:StatusEnums.IDLE,
  shifts: null,
  filterShifts:null,
  shift: null,
  resError: null,

};

export const fetchShiftsAsync = createAsyncThunk(
  "shifts/fetchShifts",
  async (filters? : {}) => {
    const response = await axiosInstance.get("/api/shifts/all", { params: filters });
    console.log("dk1")
    return response.data;
  }
);

export const fetchFilterShiftsAsync = createAsyncThunk(
  "shifts/fetchFilterShifts",
  async (filters? : {}) => {
    console.log('filters', filters);
    const response = await axiosInstance.get("/api/shifts/all", { params: filters });
    return response.data;
  }
);
export const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    resetFetchShiftsStatus: (state) => {
      state.fetchShiftsStatus = StatusEnums.IDLE;
    },
    resetFetchFilterShiftsStatus: (state) => {
      state.fetchFilterShiftsStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch shift 
      .addCase(fetchShiftsAsync.pending, (state, action) => {
        state.fetchShiftsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchShiftsAsync.fulfilled, (state, action) => {
        state.fetchShiftsStatus = StatusEnums.SUCCESS;
        state.shifts = action.payload as IShift[];
      })
      .addCase(fetchShiftsAsync.rejected, (state, action) => {
        state.fetchShiftsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
       // fetch filter shift 
       .addCase(fetchFilterShiftsAsync.pending, (state, action) => {
        state.fetchFilterShiftsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchFilterShiftsAsync.fulfilled, (state, action) => {
        state.fetchFilterShiftsStatus = StatusEnums.SUCCESS;
        state.filterShifts = action.payload as IShift[];
      })
      .addCase(fetchFilterShiftsAsync.rejected, (state, action) => {
        state.fetchFilterShiftsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetFetchShiftsStatus, resetFetchFilterShiftsStatus} = shiftSlice.actions;

export const selectShifts = (state: RootState) => state.shift.shifts;
export const selectShift = (state: RootState) => state.shift.shift;
export const selectFilterShifts = (state: RootState) => state.shift.filterShifts;
export const selectFetchShiftsStatus = (state: RootState) => state.shift.fetchShiftsStatus;
export const selectShiftResError = (state: RootState) => state.shift.resError;

export default shiftSlice.reducer;
