import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IGroup, IGroupCreate } from "../../types/group";

export interface GroupState {
  addGroupStatus: StatusEnums;
  addGroupUserStatus:StatusEnums;
  deleteGroupUsersStatus: StatusEnums;
  deleteGroupsStatus: StatusEnums;
  fetchGroupsStatus: StatusEnums;
  fetchGroupStatus: StatusEnums;
  updateGroupStatus: StatusEnums;
  groups: IPagination<IGroup> | null,
  group: IGroup | null,
  resError: IResError | null,
  addedResult: { id: string, path: string } | null
}

const initialState: GroupState = {
  addGroupStatus: StatusEnums.IDLE,
  addGroupUserStatus:StatusEnums.IDLE,
  deleteGroupsStatus: StatusEnums.IDLE,
  deleteGroupUsersStatus: StatusEnums.IDLE,
  fetchGroupsStatus: StatusEnums.IDLE,
  fetchGroupStatus: StatusEnums.IDLE,
  updateGroupStatus: StatusEnums.IDLE,
  groups: null,
  group: null,
  resError: null,
  addedResult: null
};

export const addGroupAsync = createAsyncThunk(
  "group/addGroup",
  async (group: IGroupCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/customer-groups/add", group);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteGroupsAsync = createAsyncThunk(
  "group/deleteGroups",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/customer-groups/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const updateGroupAsync = createAsyncThunk(
  "group/updateGroup",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/customer-groups/update/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const fetchGroupsAsync = createAsyncThunk(
  "group/fetchGroups",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/customer-groups/search", filters);
    return response.data;
  }
);
export const fetchGroupAsync = createAsyncThunk(
  "group/fetchGroup",
  async (id: string = "",  thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/customer-groups/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const addGroupUserAsync = createAsyncThunk(
  "group/addGroupUser",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/customer-groups/add-user/" +params.id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const deleteGroupUsersAsync = createAsyncThunk(
  "group/deleteGroupUsers",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.delete("/api/customer-groups/delete-user"+ params.id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    resetAddGroupStatus: (state) => {
      state.addGroupStatus = StatusEnums.IDLE;
      state.addedResult = null;
    },
    resetFetchGroupsStatus: (state) => {
      state.fetchGroupsStatus = StatusEnums.IDLE;
    },
    resetDeleteGroupsStatus: (state) => {
      state.deleteGroupsStatus = StatusEnums.IDLE;
    },
    resetDeleteGroupUsersStatus: (state) => {
      state.deleteGroupUsersStatus = StatusEnums.IDLE;
    },
    resetUpdateGroupStatus: (state) => {
      state.updateGroupStatus = StatusEnums.IDLE;
    },
    resetaddGroupUserStatus: (state) => {
      state.addGroupUserStatus = StatusEnums.IDLE;
    },
    resetGroupStatus: (state) => {
      state.addGroupStatus = StatusEnums.IDLE;
      state.fetchGroupsStatus = StatusEnums.IDLE;
      state.fetchGroupStatus = StatusEnums.IDLE;
      state.updateGroupStatus = StatusEnums.IDLE;
      state.deleteGroupsStatus = StatusEnums.IDLE;
      state.addGroupUserStatus = StatusEnums.IDLE;
      state.deleteGroupUsersStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // add group
      .addCase(addGroupAsync.pending, (state) => {
        state.addGroupStatus = StatusEnums.LOADING;
      })
      .addCase(addGroupAsync.fulfilled, (state, action) => {
        state.addGroupStatus = StatusEnums.SUCCESS;
        state.addedResult = action.payload
      })
      .addCase(addGroupAsync.rejected, (state, action) => {
        state.addGroupStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete group 
      .addCase(deleteGroupsAsync.pending, (state) => {
        state.deleteGroupsStatus = StatusEnums.LOADING;
      })
      .addCase(deleteGroupsAsync.fulfilled, (state) => {
        state.deleteGroupsStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteGroupsAsync.rejected, (state, action) => {
        state.deleteGroupsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch group 
      .addCase(fetchGroupsAsync.pending, (state, action) => {
        state.fetchGroupsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchGroupsAsync.fulfilled, (state, action) => {
        state.fetchGroupsStatus = StatusEnums.SUCCESS;
        state.groups = action.payload as IPagination<IGroup>;
      })
      .addCase(fetchGroupsAsync.rejected, (state, action) => {
        state.fetchGroupsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
       // update group
       .addCase(updateGroupAsync.pending, (state) => {
        state.updateGroupStatus = StatusEnums.LOADING;
      })
      .addCase(updateGroupAsync.fulfilled, (state) => {
        state.updateGroupStatus = StatusEnums.SUCCESS;
        state.groups = null;
      })
      .addCase(updateGroupAsync.rejected, (state, action) => {
        state.updateGroupStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
        // fetch group
        .addCase(fetchGroupAsync.pending, (state) => {
          state.fetchGroupStatus = StatusEnums.LOADING;
        })
        .addCase(fetchGroupAsync.fulfilled, (state, action) => {
          state.fetchGroupStatus = StatusEnums.SUCCESS;
          state.group = action.payload;
        })
        .addCase(fetchGroupAsync.rejected, (state, action) => {
          state.fetchGroupStatus = StatusEnums.FAILED;
          state.resError = action.payload as IResError;
        })
       // add groupuser
      .addCase(addGroupUserAsync.pending, (state) => {
        state.addGroupUserStatus = StatusEnums.LOADING;
      })
      .addCase(addGroupUserAsync.fulfilled, (state, action) => {
        state.addGroupUserStatus = StatusEnums.SUCCESS;
        state.addedResult = action.payload
      })
      .addCase(addGroupUserAsync.rejected, (state, action) => {
        state.addGroupUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
       // delete group user 
       .addCase(deleteGroupUsersAsync.pending, (state) => {
        state.deleteGroupUsersStatus = StatusEnums.LOADING;
      })
      .addCase(deleteGroupUsersAsync.fulfilled, (state) => {
        state.deleteGroupUsersStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteGroupUsersAsync.rejected, (state, action) => {
        state.deleteGroupUsersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetGroupStatus, resetDeleteGroupsStatus, resetFetchGroupsStatus, resetAddGroupStatus, resetUpdateGroupStatus, resetaddGroupUserStatus, resetDeleteGroupUsersStatus } = groupSlice.actions;
export const selectGroups = (state: RootState) => state.group.groups;
export const selectGroup = (state: RootState) => state.group.group;
export const selectAddedResult = (state: RootState) => state.group.addedResult;
export const selectDeleteGroupsStatus = (state: RootState) => state.group.deleteGroupsStatus;
export const selectDeleteGroupUSersStatus = (state: RootState) => state.group.deleteGroupUsersStatus;
export const selectFetchGroupsStatus = (state: RootState) => state.group.fetchGroupsStatus;
export const selectAddGroupStatus = (state: RootState) => state.group.addGroupStatus;
export const selectAddGroupUserStatus = (state: RootState) => state.group.addGroupUserStatus;
export const selectUpdateGroupStatus = (state: RootState) => state.group.updateGroupStatus;
export const selectGroupResError = (state: RootState) => state.group.resError;
export const selectFetchGroupStatus = (state: RootState) => state.group.fetchGroupStatus;
export default groupSlice.reducer;
