import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IChat, IChatCreate } from "../../types/chat";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface ChatState {
  deleteChatsStatus: StatusEnums;
  fetchChatsStatus: StatusEnums;
  fetchChatStatus: StatusEnums;
  addChatStatus: StatusEnums;
  chats: IPagination<IChat> | null,
  chat: IChat | null,
  resError: IResError | null,
}

const initialState: ChatState = {
  deleteChatsStatus: StatusEnums.IDLE,
  fetchChatsStatus: StatusEnums.IDLE,
  fetchChatStatus: StatusEnums.IDLE,
  addChatStatus: StatusEnums.IDLE,
  chats: null,
  chat: null,
  resError: null,

};

export const deleteChatsAsync = createAsyncThunk(
  "attachments/deleteChats",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/chats/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchChatAsync = createAsyncThunk(
  "chat/fetchChat",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/chats/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addChatAsync = createAsyncThunk(
  "chat/addChat",
  async (chat: IChatCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/chats/add", chat);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchChatsAsync = createAsyncThunk(
  "chats/fetchChats",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/chats/search", filters);
    return response.data;
  }
);


export const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    resetDeleteChatsStatus: (state) => {
      state.deleteChatsStatus = StatusEnums.IDLE;
    },
    resetFetchChatsStatus: (state) => {
      state.fetchChatsStatus = StatusEnums.IDLE;
    },
    resetAddChatStatus: (state) => {
      state.addChatStatus = StatusEnums.IDLE;
    },
    resetFetchChatStatus: (state) => {
      state.fetchChatStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // delete chats 
      .addCase(deleteChatsAsync.pending, (state) => {
        state.deleteChatsStatus = StatusEnums.LOADING;
      })
      .addCase(deleteChatsAsync.fulfilled, (state) => {
        state.deleteChatsStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteChatsAsync.rejected, (state, action) => {
        state.deleteChatsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch chat
      .addCase(fetchChatAsync.pending, (state, action) => {
        state.fetchChatStatus = StatusEnums.LOADING;
      })
      .addCase(fetchChatAsync.fulfilled, (state, action) => {
        state.fetchChatStatus = StatusEnums.SUCCESS;
        state.chat = action.payload;
      })
      .addCase(fetchChatAsync.rejected, (state, action) => {
        state.fetchChatStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add chat
      .addCase(addChatAsync.pending, (state) => {
        state.addChatStatus = StatusEnums.LOADING;
      })
      .addCase(addChatAsync.fulfilled, (state, action) => {
        state.addChatStatus = StatusEnums.SUCCESS;
      })
      .addCase(addChatAsync.rejected, (state, action) => {
        state.addChatStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch chats 
      .addCase(fetchChatsAsync.pending, (state, action) => {
        state.fetchChatsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchChatsAsync.fulfilled, (state, action) => {
        state.fetchChatsStatus = StatusEnums.SUCCESS;
        state.chats = action.payload as IPagination<IChat>;
      })
      .addCase(fetchChatsAsync.rejected, (state, action) => {
        state.fetchChatsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetDeleteChatsStatus, resetFetchChatStatus, resetFetchChatsStatus, resetAddChatStatus } = chatSlice.actions;


export const selectChats = (state: RootState) => state.chat.chats;
export const selectChat = (state: RootState) => state.chat.chat;
export const selectChatCount = (state: RootState) => state.chat.chats?.count;
export const selectFetchChatsStatus = (state: RootState) => state.chat.fetchChatsStatus;
export const selectAddChatStatus = (state: RootState) => state.chat.addChatStatus;
export const selectFetchChatStatus = (state: RootState) => state.chat.fetchChatStatus;
export const selectDeleteChatsStatus = (state: RootState) => state.chat.deleteChatsStatus;
export const selectChatResError = (state: RootState) => state.chat.resError;

export default chatSlice.reducer;
