import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IInvoice } from "../../types/history-invoice";

export interface InvoiceState {
  fetchInvoicesStatus: StatusEnums;
  fetchUserInvoicesStatus: StatusEnums;
  invoices: IPagination<IInvoice> | null,
  resError: IResError | null
}
const initialState: InvoiceState = {
  fetchInvoicesStatus: StatusEnums.IDLE,
  fetchUserInvoicesStatus: StatusEnums.IDLE,
  invoices: null,
  resError: null
};

export const fetchInvoicesAsync = createAsyncThunk(
  "invoice/fetchInvoices",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/invoices/search", filters);
    return response.data;
  }
);

export const fetchUserInvoicesAsync = createAsyncThunk(
  "invoice/fetchUserInvoices",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/invoices/search-user", filters);
    return response.data;
  }
);


export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resetFetchInvoicesStatus: (state) => {
      state.fetchInvoicesStatus = StatusEnums.IDLE;
    },
    resetFetchUserInvoicesStatus: (state) => {
      state.fetchUserInvoicesStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch invoice 
      .addCase(fetchInvoicesAsync.pending, (state, action) => {
        state.fetchInvoicesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchInvoicesAsync.fulfilled, (state, action) => {
        state.fetchInvoicesStatus = StatusEnums.SUCCESS;
        state.invoices = action.payload as IPagination<IInvoice>;
      })
      .addCase(fetchInvoicesAsync.rejected, (state, action) => {
        state.fetchInvoicesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch user invoice 
      .addCase(fetchUserInvoicesAsync.pending, (state, action) => {
        state.fetchUserInvoicesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUserInvoicesAsync.fulfilled, (state, action) => {
        state.fetchUserInvoicesStatus = StatusEnums.SUCCESS;
        state.invoices = action.payload as IPagination<IInvoice>;
      })
      .addCase(fetchUserInvoicesAsync.rejected, (state, action) => {
        state.fetchUserInvoicesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchInvoicesStatus, resetFetchUserInvoicesStatus } = invoiceSlice.actions;
export const selectInvoices = (state: RootState) => state.invoice.invoices;
export const selectFetchInvoicesStatus = (state: RootState) => state.invoice.fetchInvoicesStatus;
export const selectFetchUserInvoicesStatus = (state: RootState) => state.invoice.fetchUserInvoicesStatus;
export const selectInvoiceResError = (state: RootState) => state.invoice.resError;

export default invoiceSlice.reducer;
