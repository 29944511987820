import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IMissionGroup } from "../../types/mission-group";
import { IFilters } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface MissionGroupState {
  fetchMissionGroupsStatus: StatusEnums;
  missionGroups: IMissionGroup | null,
  missionGroup: IMissionGroup | null,
  filters: IFilters | null,
  resError: IResError | null,
}

const initialState: MissionGroupState = {
  fetchMissionGroupsStatus: StatusEnums.IDLE,
  missionGroups: null,
  missionGroup: null,
  filters: null,
  resError: null,
};

export const fetchMissionGroupsAsync = createAsyncThunk(
  "missionGroups/fetchMissionGroups",
  async () => {
    const response = await axiosInstance.get("/api/mission-groups/all");
    return response.data;
  }
);

export const missionGroupSlice = createSlice({
  name: "missionGroups",
  initialState,
  reducers: {
    resetFetchMissionGroupsStatus: (state) => {
      state.fetchMissionGroupsStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // points
      .addCase(fetchMissionGroupsAsync.pending, (state) => {
        state.fetchMissionGroupsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchMissionGroupsAsync.fulfilled, (state, action) => {
        state.fetchMissionGroupsStatus = StatusEnums.SUCCESS;
        state.missionGroups = action.payload as IMissionGroup;
      })
      .addCase(fetchMissionGroupsAsync.rejected, (state, action) => {
        state.fetchMissionGroupsStatus = StatusEnums.FAILED;
      })
  },
});

export const {resetFetchMissionGroupsStatus} = missionGroupSlice.actions;

export const selectMissionGroups = (state: RootState) => state.missionGroup.missionGroups;
export const selectMissionGroup = (state: RootState) => state.missionGroup.missionGroup;
export const selectFetchMissionGroupsStatus = (state: RootState) => state.missionGroup.fetchMissionGroupsStatus;
export const selectMissionGroupResError = (state: RootState) => state.missionGroup.resError;

export default missionGroupSlice.reducer;
