import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters } from "../../types/pagination";
import { IRank } from "../../types/rank";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface RankState {
  fetchRanksStatus: StatusEnums;
  ranks: IRank | null,
  rank: IRank | null,
  filters: IFilters | null,
  resError: IResError | null,
}

const initialState: RankState = {
  fetchRanksStatus: StatusEnums.IDLE,
  ranks: null,
  rank: null,
  filters: null,
  resError: null,
};

export const fetchRanksAsync = createAsyncThunk(
  "ranks/fetchRanksAsync",
  async () => {
    const response = await axiosInstance.get("/api/ranks/all");
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const rankSlice = createSlice({
  name: "rank",
  initialState,
  reducers: {
    resetFetchRanksStatus: (state) => {
      state.fetchRanksStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch ranks
      .addCase(fetchRanksAsync.pending, (state) => {
        state.fetchRanksStatus = StatusEnums.LOADING;
      })
      .addCase(fetchRanksAsync.fulfilled, (state, action) => {
        state.fetchRanksStatus = StatusEnums.SUCCESS;
        state.ranks = action.payload as IRank;
      })
      .addCase(fetchRanksAsync.rejected, (state, action) => {
        state.fetchRanksStatus = StatusEnums.FAILED;
      })
      ;
  },
});

export const { resetFetchRanksStatus } = rankSlice.actions;

export const selectRanks = (state: RootState) => state.rank.ranks;
export const selectRank = (state: RootState) => state.rank.rank;
export const selectFetchRanksStatus = (state: RootState) => state.rank.fetchRanksStatus;
export const selectRankResError = (state: RootState) => state.rank.resError;

export default rankSlice.reducer;
