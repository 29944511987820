import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IMission, IMissionCreate } from "../../types/mission";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface MissonState {
  deleteMissionsStatus: StatusEnums;
  fetchMissionsStatus: StatusEnums;
  fetchMissionStatus: StatusEnums;
  updateMissionStatus: StatusEnums;
  addMissionStatus: StatusEnums;
  missions: IPagination<IMission> | null,
  mission: IMission | null,
  resError: IResError | null,
}

const initialState: MissonState = {
  deleteMissionsStatus: StatusEnums.IDLE,
  fetchMissionsStatus: StatusEnums.IDLE,
  fetchMissionStatus: StatusEnums.IDLE,
  updateMissionStatus: StatusEnums.IDLE,
  addMissionStatus: StatusEnums.IDLE,
  missions: null,
  mission: null,
  resError: null,

};

export const updateMissionAsync = createAsyncThunk(
  "mission/updateMission",
  async (params: any, thunkApi) => {
    try {
      const mission = params.payload;
      const formData = new FormData();
      formData.append("avatar", mission.avatar);
      formData.append("name", mission.name);
      formData.append("description", mission.description);
      formData.append("missionGroupId", mission.missionGroupId);
      const response = await axiosInstance.put("/api/missions/update/" + params.id, formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteMissionsAsync = createAsyncThunk(
  "mission/deleteMissions",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/missions/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchMissionAsync = createAsyncThunk(
  "mission/fetchmission",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/missions/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addMissionAsync = createAsyncThunk(
  "mission/addMission",
  async (mission: IMissionCreate, thunkApi) => {
    try {
      const formData = new FormData();
      mission.startedAt = new Date(mission.startedAt.replace("T"," ")).toISOString();
      mission.endedAt = new Date(mission.endedAt.replace("T"," ")).toISOString();
      formData.append("name", mission.name);
      formData.append("startedAt", mission.startedAt);
      formData.append("endedAt", mission.endedAt);
      formData.append("arenaPoint", mission.arenaPoint.toString());
      formData.append("description", mission.description);
      formData.append("type", mission.type);
      formData.append("avatar", mission.avatar);
      if (mission.toGroupId) {
        formData.append("toGroupId", mission.toGroupId);
      }
      if (mission.missionGroupId) {
        formData.append("missionGroupId", mission.missionGroupId);
      }
      if (mission.missionType) {
        formData.append("missionType", mission.missionType);
      }
      if (mission.missionRank) {
        formData.append("missionRank", JSON.stringify(mission.missionRank));
      }
      if (mission.missionAutoTypeId) {
        formData.append("missionAutoTypeId", mission.missionAutoTypeId);
      }
      if (mission.value) {
        formData.append("value", mission.value.toString());
      }
      const response = await axiosInstance.post("/api/missions/add", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchMissionsAsync = createAsyncThunk(
  "mission/fetchMission",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/missions/search-admin", filters);
    return response.data;
  }
);


export const missionSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {
    resetDeleteMissionsStatus: (state) => {
      state.deleteMissionsStatus = StatusEnums.IDLE;
    },
    resetFetchMissionsStatus: (state) => {
      state.fetchMissionsStatus = StatusEnums.IDLE;
    },
    resetAddMissionStatus: (state) => {
      state.addMissionStatus = StatusEnums.IDLE;
    },
    resetFetchMissionStatus: (state) => {
      state.fetchMissionStatus = StatusEnums.IDLE;
    },
    resetUpdateMissionStatus: (state) => {
      state.updateMissionStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // update mission
      .addCase(updateMissionAsync.pending, (state) => {
        state.updateMissionStatus = StatusEnums.LOADING;
      })
      .addCase(updateMissionAsync.fulfilled, (state) => {
        state.updateMissionStatus = StatusEnums.SUCCESS;
        state.missions = null;
      })
      .addCase(updateMissionAsync.rejected, (state, action) => {
        state.updateMissionStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete mission 
      .addCase(deleteMissionsAsync.pending, (state) => {
        state.deleteMissionsStatus = StatusEnums.LOADING;
      })
      .addCase(deleteMissionsAsync.fulfilled, (state) => {
        state.deleteMissionsStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteMissionsAsync.rejected, (state, action) => {
        state.deleteMissionsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch mission
      .addCase(fetchMissionAsync.pending, (state, action) => {
        state.fetchMissionStatus = StatusEnums.LOADING;
      })
      .addCase(fetchMissionAsync.fulfilled, (state, action) => {
        state.fetchMissionStatus = StatusEnums.SUCCESS;
        state.mission = action.payload;
      })
      .addCase(fetchMissionAsync.rejected, (state, action) => {
        state.fetchMissionStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add mission
      .addCase(addMissionAsync.pending, (state) => {
        state.addMissionStatus = StatusEnums.LOADING;
      })
      .addCase(addMissionAsync.fulfilled, (state, action) => {
        state.addMissionStatus = StatusEnums.SUCCESS;
      })
      .addCase(addMissionAsync.rejected, (state, action) => {
        state.addMissionStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch mission 
      .addCase(fetchMissionsAsync.pending, (state, action) => {
        state.fetchMissionsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchMissionsAsync.fulfilled, (state, action) => {
        state.fetchMissionsStatus = StatusEnums.SUCCESS;
        state.missions = action.payload as IPagination<IMission>;
      })
      .addCase(fetchMissionsAsync.rejected, (state, action) => {
        state.fetchMissionsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetDeleteMissionsStatus, resetFetchMissionStatus, resetFetchMissionsStatus, resetUpdateMissionStatus, resetAddMissionStatus } = missionSlice.actions;

export const selectMissions = (state: RootState) => state.mission.missions;
export const selectMission = (state: RootState) => state.mission.mission;
export const selectMissionCount = (state: RootState) => state.mission.missions?.count;
export const selectFetchMissionsStatus = (state: RootState) => state.mission.fetchMissionsStatus;
export const selectAddMissionStatus = (state: RootState) => state.mission.addMissionStatus;
export const selectFetchMissionStatus = (state: RootState) => state.mission.fetchMissionStatus;
export const selectDeleteMissionsStatus = (state: RootState) => state.mission.deleteMissionsStatus;
export const selectMissionResError = (state: RootState) => state.mission.resError;
export const selectUpdateMissionStatus = (state: RootState) => state.mission.updateMissionStatus;

export default missionSlice.reducer;
