import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFeedTag, IFeedTagCreate } from "../../types/feed-tag";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface FeedTagState {
  fetchFeedTagsStatus: StatusEnums;
  deleteFeedTagsStatus: StatusEnums;
  updateFeedTagStatus: StatusEnums;
  fetchFeedTagStatus: StatusEnums;
  addFeedTagStatus: StatusEnums;
  fetchFeedTagAllsStatus: StatusEnums;
  feedtags: IPagination<IFeedTag> | null,
  feedtag: IFeedTag | null,
  filters: IFilters | null,
  resError: IResError | null,
  showFilters: boolean,
}

const initialState: FeedTagState = {
  fetchFeedTagsStatus: StatusEnums.IDLE,
  fetchFeedTagStatus: StatusEnums.IDLE,
  deleteFeedTagsStatus: StatusEnums.IDLE,
  updateFeedTagStatus: StatusEnums.IDLE,
  addFeedTagStatus: StatusEnums.IDLE,
  fetchFeedTagAllsStatus: StatusEnums.IDLE,
  feedtags: null,
  feedtag: null,
  resError: null,
  filters: null,
  showFilters: false,

};

export const deleteFeedTagsAsync = createAsyncThunk(
  "feedtags/deleteFeedTags",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/feed-tags/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateFeedTagAsync = createAsyncThunk(
  "feedtag/updateFeedTag",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/feed-tags/update/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchFeedTagAsync = createAsyncThunk(
  "feedtag/fetchFeedTag",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/feed-tags/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchFeedTagAllsAsync = createAsyncThunk(
  "feedtags/fetchFeedTags",
  async () => {
    const response = await axiosInstance.get("/api/feed-tags/all");
    return response.data;
  }
);

export const addFeedTagAsync = createAsyncThunk(
  "feedtag/addFeedTag",
  async (feedtag: IFeedTagCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/feed-tags/add", feedtag);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchFeedTagsAsync = createAsyncThunk(
  "feedtag/fetchFeedTags",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/feed-tags/search", filters);
    return response.data;
  }
);


export const feedtagSlice = createSlice({
  name: "feedtags",
  initialState,
  reducers: {
    resetFetchFeedTagsStatus: (state) => {
      state.fetchFeedTagsStatus = StatusEnums.IDLE;
    },
    resetAddFeedTagStatus: (state) => {
      state.addFeedTagStatus = StatusEnums.IDLE;
    },
    resetFetchFeedTagStatus: (state) => {
      state.fetchFeedTagStatus = StatusEnums.IDLE;
    },
    resetFetchFeedTagAllsStatus: (state) => {
      state.fetchFeedTagAllsStatus = StatusEnums.IDLE;
    },
    resetDeleteFeedsStatus: (state) => {
      state.deleteFeedTagsStatus = StatusEnums.IDLE;
    },
    resetUpdateFeedTagStatus: (state) => {
      state.updateFeedTagStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
     // delete feed-tag 
     .addCase(deleteFeedTagsAsync.pending, (state) => {
      state.deleteFeedTagsStatus = StatusEnums.LOADING;
    })
    .addCase(deleteFeedTagsAsync.fulfilled, (state) => {
      state.deleteFeedTagsStatus = StatusEnums.SUCCESS;
    })
    .addCase(deleteFeedTagsAsync.rejected, (state, action) => {
      state.deleteFeedTagsStatus = StatusEnums.FAILED;
      state.resError = action.payload as IResError;
    })
     // update feed-tag
     .addCase(updateFeedTagAsync.pending, (state) => {
      state.updateFeedTagStatus = StatusEnums.LOADING;
    })
    .addCase(updateFeedTagAsync.fulfilled, (state) => {
      state.updateFeedTagStatus = StatusEnums.SUCCESS;
      state.feedtags = null;
    })
    .addCase(updateFeedTagAsync.rejected, (state, action) => {
      state.updateFeedTagStatus = StatusEnums.FAILED;
      state.resError = action.payload as IResError;
    })
      // fetch feed-tag
      .addCase(fetchFeedTagAsync.pending, (state, action) => {
        state.fetchFeedTagStatus = StatusEnums.LOADING;
      })
      .addCase(fetchFeedTagAsync.fulfilled, (state, action) => {
        state.fetchFeedTagStatus = StatusEnums.SUCCESS;
        state.feedtag = action.payload;
      })
      .addCase(fetchFeedTagAsync.rejected, (state, action) => {
        state.fetchFeedTagStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add feed-tag
      .addCase(addFeedTagAsync.pending, (state) => {
        state.addFeedTagStatus = StatusEnums.LOADING;
      })
      .addCase(addFeedTagAsync.fulfilled, (state, action) => {
        state.addFeedTagStatus = StatusEnums.SUCCESS;
      })
      .addCase(addFeedTagAsync.rejected, (state, action) => {
        state.addFeedTagStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch feed-tags 
      .addCase(fetchFeedTagsAsync.pending, (state, action) => {
        state.fetchFeedTagsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchFeedTagsAsync.fulfilled, (state, action) => {
        state.fetchFeedTagsStatus = StatusEnums.SUCCESS;
        state.feedtags = action.payload as IPagination<IFeedTag>;
      })
      .addCase(fetchFeedTagsAsync.rejected, (state, action) => {
        state.fetchFeedTagsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
       // fetch feed-tag-alls
       .addCase(fetchFeedTagAllsAsync.pending, (state, action) => {
        state.fetchFeedTagAllsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchFeedTagAllsAsync.fulfilled, (state, action) => {
        state.fetchFeedTagAllsStatus = StatusEnums.SUCCESS;
        state.feedtags = action.payload as IPagination<IFeedTag>;
      })
      .addCase(fetchFeedTagAllsAsync.rejected, (state, action) => {
        state.fetchFeedTagAllsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {  resetFetchFeedTagStatus, resetFetchFeedTagsStatus, resetAddFeedTagStatus, resetFetchFeedTagAllsStatus, resetUpdateFeedTagStatus } = feedtagSlice.actions;


export const selectFeedTags = (state: RootState) => state.feedtag.feedtags;
export const selectFeedTag = (state: RootState) => state.feedtag.feedtag;
export const selectFetchFeedTagsStatus = (state: RootState) => state.feedtag.fetchFeedTagsStatus;
export const selectDeleteFeedTagsStatus = (state: RootState) => state.feedtag.deleteFeedTagsStatus;
export const selectUpdateFeedTagStatus = (state: RootState) => state.feedtag.updateFeedTagStatus;
export const selectAddFeedTagStatus = (state: RootState) => state.feedtag.addFeedTagStatus;
export const selectFetchFeedTagStatus = (state: RootState) => state.feedtag.fetchFeedTagStatus;
export const selectFetchFeedTagAllsStatus = (state: RootState) => state.feedtag.fetchFeedTagAllsStatus;
export const selectFeedTagResError = (state: RootState) => state.feedtag.resError;

export default feedtagSlice.reducer;
