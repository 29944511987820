import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IJurisdiction } from "../../types/jurisdiction";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface JurisdictionState {
  fetchJurisdictionsStatus: StatusEnums;
  jurisdictionss: IPagination<IJurisdiction> | null,
  resError: IResError | null,
}

const initialState: JurisdictionState = {
  fetchJurisdictionsStatus: StatusEnums.IDLE,
  jurisdictionss: null,
  resError: null,

};

export const fetchJurisdictionsAsync = createAsyncThunk(
  "jurisdictions/fetchJurisdictions",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/jurisdictions/search", filters);
    return response.data;
  }
);


export const jurisdictionSlice = createSlice({
  name: "jurisdictions",
  initialState,
  reducers: {
    resetFetchJurisdictionsStatus: (state) => {
      state.fetchJurisdictionsStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch jurisdictionss 
      .addCase(fetchJurisdictionsAsync.pending, (state, action) => {
        state.fetchJurisdictionsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchJurisdictionsAsync.fulfilled, (state, action) => {
        state.fetchJurisdictionsStatus = StatusEnums.SUCCESS;
        state.jurisdictionss = action.payload as IPagination<IJurisdiction>;
      })
      .addCase(fetchJurisdictionsAsync.rejected, (state, action) => {
        state.fetchJurisdictionsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchJurisdictionsStatus } = jurisdictionSlice.actions;


export const selectJurisdictions = (state: RootState) => state.jurisdiction.jurisdictionss;
export const selectJurisdictionCount = (state: RootState) => state.jurisdiction.jurisdictionss?.count;
export const selectFetchJurisdictionsStatus = (state: RootState) => state.jurisdiction.fetchJurisdictionsStatus;

export default jurisdictionSlice.reducer;
