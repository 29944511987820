import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IDevice, IDeviceCreate } from "../../types/device";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface DeviceState {
  deleteDevicesStatus: StatusEnums;
  fetchDevicesStatus: StatusEnums;
  fetchDeviceStatus: StatusEnums;
  addDeviceStatus: StatusEnums;
  devices: IPagination<IDevice> | null,
  device: IDevice | null,
  resError: IResError | null,
}

const initialState: DeviceState = {
  deleteDevicesStatus: StatusEnums.IDLE,
  fetchDevicesStatus: StatusEnums.IDLE,
  fetchDeviceStatus: StatusEnums.IDLE,
  addDeviceStatus: StatusEnums.IDLE,
  devices: null,
  device: null,
  resError: null,

};

export const deleteDevicesAsync = createAsyncThunk(
  "device/deleteDevices",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/devices/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchDeviceAsync = createAsyncThunk(
  "device/fetchDevice",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/devices/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addDeviceAsync = createAsyncThunk(
  "device/addDevice",
  async (device: IDeviceCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/devices/add", device);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchDevicesAsync = createAsyncThunk(
  "devices/fetchDevices",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/devices/search", filters);
    return response.data;
  }
);


export const deviceSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    resetDeleteDevicesStatus: (state) => {
      state.deleteDevicesStatus = StatusEnums.IDLE;
    },
    resetFetchDevicesStatus: (state) => {
      state.fetchDevicesStatus = StatusEnums.IDLE;
    },
    resetAddDeviceStatus: (state) => {
      state.addDeviceStatus = StatusEnums.IDLE;
    },
    resetFetchDeviceStatus: (state) => {
      state.fetchDeviceStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // delete devices 
      .addCase(deleteDevicesAsync.pending, (state) => {
        state.deleteDevicesStatus = StatusEnums.LOADING;
      })
      .addCase(deleteDevicesAsync.fulfilled, (state) => {
        state.deleteDevicesStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteDevicesAsync.rejected, (state, action) => {
        state.deleteDevicesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch device
      .addCase(fetchDeviceAsync.pending, (state, action) => {
        state.fetchDeviceStatus = StatusEnums.LOADING;
      })
      .addCase(fetchDeviceAsync.fulfilled, (state, action) => {
        state.fetchDeviceStatus = StatusEnums.SUCCESS;
        state.device = action.payload;
      })
      .addCase(fetchDeviceAsync.rejected, (state, action) => {
        state.fetchDeviceStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add device
      .addCase(addDeviceAsync.pending, (state) => {
        state.addDeviceStatus = StatusEnums.LOADING;
      })
      .addCase(addDeviceAsync.fulfilled, (state, action) => {
        state.addDeviceStatus = StatusEnums.SUCCESS;
      })
      .addCase(addDeviceAsync.rejected, (state, action) => {
        state.addDeviceStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch devices 
      .addCase(fetchDevicesAsync.pending, (state, action) => {
        state.fetchDevicesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchDevicesAsync.fulfilled, (state, action) => {
        state.fetchDevicesStatus = StatusEnums.SUCCESS;
        state.devices = action.payload as IPagination<IDevice>;
      })
      .addCase(fetchDevicesAsync.rejected, (state, action) => {
        state.fetchDevicesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetDeleteDevicesStatus, resetFetchDeviceStatus, resetFetchDevicesStatus, resetAddDeviceStatus } = deviceSlice.actions;


export const selectDevices = (state: RootState) => state.device.devices;
export const selectDevice = (state: RootState) => state.device.device;
export const selectDeviceCount = (state: RootState) => state.device.devices?.count;
export const selectFetchDevicesStatus = (state: RootState) => state.device.fetchDevicesStatus;
export const selectAddDeviceStatus = (state: RootState) => state.device.addDeviceStatus;
export const selectFetchDeviceStatus = (state: RootState) => state.device.fetchDeviceStatus;
export const selectDeleteDevicesStatus = (state: RootState) => state.device.deleteDevicesStatus;
export const selectDeviceResError = (state: RootState) => state.device.resError;

export default deviceSlice.reducer;
