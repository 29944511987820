import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";
import SignInComponent from "../../components/auth/SignIn";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { useTranslation } from "react-i18next";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
function SignIn() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div style={{paddingBottom: 20, paddingTop:40}}>
      <img style={{width:"100px", height: "100Px",  paddingTop:"10px" }} src="/static/img/logo.png"/>
      </div>
      <Wrapper>
        <Helmet title={t('login')} />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("welcomeTitle")}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t("loginTitle")}
        </Typography>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}
export default SignIn;
