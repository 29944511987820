import styled from "@emotion/styled";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonIcon from "@mui/icons-material/Person";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SettingsPowerOutlinedIcon from '@mui/icons-material/SettingsPowerOutlined';
import {
  Avatar, IconButton as MuiIconButton,
  ListItemIcon,
  ListItemText, Menu,
  MenuItem, Tooltip
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import ChangePassDialog from "../ChangePassDialog";
import Settings from "../Settings";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
function NavbarUserDropdown() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { pathname } = useLocation();
  const [openChangePass, setOpenChangePass] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleViewProfile = async () => {
    closeMenu();
    navigate(`/${pathname.split("/")[1]}/profile`);
  };

  return (
    <React.Fragment>
      <Tooltip title="Tài khoản">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          {!!user && (
            <Avatar alt={user.firstName} src={`${process.env.REACT_APP_API}/${user?.avatar}`}>
              <PersonIcon />
            </Avatar>
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleViewProfile}>
          <ListItemIcon>
            <Person2OutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('viewProfile')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { closeMenu(); setOpenChangePass(true) }}>
          <ListItemIcon>
            <LockOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('changePassword')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { closeMenu(); setOpen(true); }}>
          <ListItemIcon>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('setting')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSignOut}><ListItemIcon>
          <SettingsPowerOutlinedIcon fontSize="small" />
        </ListItemIcon>
          <ListItemText>{t('logout')}</ListItemText></MenuItem>
      </Menu>
      {openChangePass && <ChangePassDialog open={openChangePass} onClose={() => setOpenChangePass(false)} />}
      <Settings open={open} onClose={() => setOpen(false)} />
    </React.Fragment >
  );
}

export default NavbarUserDropdown;
