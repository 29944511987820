import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IDevice } from "../../types/device";
import { IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IArea } from "../../types/area";

export interface AreaState {
  fetchAreasStatus: StatusEnums;
  areas: IArea[] | null,
  area: IDevice | null,
  resError: IResError | null,
}

const initialState: AreaState = {
  fetchAreasStatus: StatusEnums.IDLE,
  areas: null,
  area: null,
  resError: null,

};

export const fetchAreasAsync = createAsyncThunk(
  "areas/fetchAreas",
  async (filters? : {}) => {
    const response = await axiosInstance.get("/api/areas/all", { params: filters });
    return response.data;
  }
);

export const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    resetFetchAreasStatus: (state) => {
      state.fetchAreasStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch area 
      .addCase(fetchAreasAsync.pending, (state, action) => {
        state.fetchAreasStatus = StatusEnums.LOADING;
      })
      .addCase(fetchAreasAsync.fulfilled, (state, action) => {
        state.fetchAreasStatus = StatusEnums.SUCCESS;
        state.areas = action.payload as IArea[];
      })
      .addCase(fetchAreasAsync.rejected, (state, action) => {
        state.fetchAreasStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetFetchAreasStatus} = areaSlice.actions;

export const selectAreas = (state: RootState) => state.area.areas;
export const selectArea = (state: RootState) => state.area.area;
export const selectFetchAreasStatus = (state: RootState) => state.area.fetchAreasStatus;
export const selectAreaResError = (state: RootState) => state.area.resError;

export default areaSlice.reducer;
