import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import { IRole } from "../../types/role";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface IRoleState {
  fetchRolesStatus: StatusEnums;
  updateRoleStatus: StatusEnums;
  roles: IPagination<IRole> | null,
  role: IRole | null,
  filters: IFilters | null,
  resError: IResError | null,
  showFilters: boolean,
}

const initialState: IRoleState = {
  fetchRolesStatus: StatusEnums.IDLE,
  updateRoleStatus: StatusEnums.IDLE,
  roles: null,
  role: null,
  filters: null,
  resError: null,
  showFilters: false,
};

export const fetchRolesAsync = createAsyncThunk(
  "role/fetchRoles",
  async (filters?: IFilters | null) => {
    const response = await axiosInstance.post("/api/roles/search", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const updateRoleAsync = createAsyncThunk(
  "role/updateRole",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/roles/update/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    showRoleFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetFetchRolesStatus: (state) => {
      state.fetchRolesStatus = StatusEnums.IDLE;
    },
    resetUpdateRoleStatus: (state) => {
      state.updateRoleStatus = StatusEnums.IDLE;
    },
    setRoleFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch role
      .addCase(fetchRolesAsync.pending, (state) => {
        state.fetchRolesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchRolesAsync.fulfilled, (state, action) => {
        state.fetchRolesStatus = StatusEnums.SUCCESS;
        state.roles = action.payload;
      })
      .addCase(fetchRolesAsync.rejected, (state, action) => {
        state.fetchRolesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update role
      .addCase(updateRoleAsync.pending, (state) => {
        state.updateRoleStatus = StatusEnums.LOADING;
      })
      .addCase(updateRoleAsync.fulfilled, (state) => {
        state.updateRoleStatus = StatusEnums.SUCCESS;
        state.role = null;
      })
      .addCase(updateRoleAsync.rejected, (state, action) => {
        state.updateRoleStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      ;
  },
});

export const {  resetUpdateRoleStatus, resetFetchRolesStatus, setRoleFilters, showRoleFilters } = roleSlice.actions;


export const selectRoles = (state: RootState) => state.role.roles;
export const selectRoleFilters = (state: RootState) => state.role.roles?.filters;
export const selectFetchRolessStatus = (state: RootState) => state.role.fetchRolesStatus;
export const selectUpdateRoleStatus = (state: RootState) => state.role.updateRoleStatus;
export const selectRole = (state: RootState) => state.role.role;
export const selectRoleResError = (state: RootState) => state.role.resError;
export const selectShowRoleFilters = (state: RootState) => state.role.showFilters;

export default roleSlice.reducer;
