import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IInvoiceDetail } from "../../types/invoice-detail";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface InvoiceDetailState {
  fetchInvoiceDetailsStatus: StatusEnums;
  invoiceDetails: IPagination<IInvoiceDetail> | null,
  invoiceDetail: IInvoiceDetail | null,
  resError: IResError | null,
  addedResult: { id: string, paht: string } | null
}
const initialState: InvoiceDetailState = {
  fetchInvoiceDetailsStatus: StatusEnums.IDLE,
  invoiceDetails: null,
  invoiceDetail: null,
  resError: null,
  addedResult: null

};
export const fetchInvoiceDetailsAsync = createAsyncThunk(
  "invoiceDetail/fetchInvoiceDetails",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/invoice-details/search", filters);
    return response.data;
  }
);


export const invoiceDetailSlice = createSlice({
  name: "invoiceDetail",
  initialState,
  reducers: {
    resetFetchInvoiceDetailsStatus: (state) => {
      state.fetchInvoiceDetailsStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch invoicedetail 
      .addCase(fetchInvoiceDetailsAsync.pending, (state, action) => {
        state.fetchInvoiceDetailsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchInvoiceDetailsAsync.fulfilled, (state, action) => {
        state.fetchInvoiceDetailsStatus = StatusEnums.SUCCESS;
        state.invoiceDetails = action.payload as IPagination<IInvoiceDetail>;
      })
      .addCase(fetchInvoiceDetailsAsync.rejected, (state, action) => {
        state.fetchInvoiceDetailsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetFetchInvoiceDetailsStatus } = invoiceDetailSlice.actions;
export const selectInvoiceDetails = (state: RootState) => state.invoiceDetail.invoiceDetails;
export const selectInvoiceDetail = (state: RootState) => state.invoiceDetail.invoiceDetail;
export const selectFetchInvoiceDetailsStatus = (state: RootState) => state.invoiceDetail.fetchInvoiceDetailsStatus;
export const selectInvoiceDetailResError = (state: RootState) => state.invoiceDetail.resError;
export default invoiceDetailSlice.reducer;
