// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
export enum ScenarioStatusEnums {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export const ScenarioStatuses: any = {
  DRAFT: "Bản nháp",
  PENDING: "Đang chờ duyệt",
  APPROVED: "Đã duyệt",
  REJECTED: "Từ chối"
}

export const ScenarioStatusColors: any = {
  DRAFT: "warning",
  PENDING: "info",
  APPROVED: "success",
  REJECTED: "error"
}

export const CallHistoryStatuses: any = {
  CALLING: "Đang gọi",
  USER_BUSY: "Máy bận",
  NOT_PICKUP: "Không nhấc máy",
  PLAYING: "Đang phát ghi âm",
  CONNECTING: "Đang nối máy",
  CONNECTED: "Đã nối máy",
  EXT_BUSY: "Nối máy bận",
  HANGUP: "Cúp ngang",
  ERROR: "Kết nối lỗi"
}

export const CallHistoryStatusColors: any = {
  CALLING: "info",
  USER_BUSY: "error",
  NOT_PICKUP: "error",
  PLAYING: "success",
  CONNECTING: "success",
  CONNECTED: "success",
  EXT_BUSY: "error",
  HANGUP: "error",
  ERROR: "error"
}

export const CallStatusColors: any = {
  CALLING: "info",
  USER_BUSY: "warning",
  NOT_PICKUP: "error",
  PLAYING: "info",
  CONNECTING: "info",
  CONNECTED: "success",
  EXT_BUSY: "error",
  HANGUP: "error",
  ERROR: "error"
}
export const DATETIME_FORMAT = "HH:mm:ss DD/MM/YYYY";

export enum JobStatusEnums {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS"
}

export const JobStatuses: any = {
  ENABLED: "Đã mở",
  DISABLED: "Đã tắt",
  PENDING: "Đang chờ",
  RUNNING: "Đang chạy",
  FAILED: "Thất bại",
  SUCCESS: "Thành công",
}

export const JobStatusColors: any = {
  ENABLED: "info",
  DISABLED: "error",
  PENDING: "warning",
  RUNNING: "info",
  FAILED: "error",
  SUCCESS: "success",
}

export enum AudioProcessedStatusEnums {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

export const AudioProcessedStatusColors: any = {
  PENDING: "warning",
  PROCESSING: "info",
  FAILED: "error",
  SUCCESS: "success",
}

export const AudioProcessedStatusTranslation: any = {
  PENDING: "Đang chờ",
  PROCESSING: "Đang xử",
  FAILED: "Thất bại",
  SUCCESS: "Thành công",
}

export const DataGridStyle = {
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'normal !important',
    lineHeight: "20px !important"
  },
  '& .MuiDataGrid-columnHeaderTitle > span': {
    whiteSpace: 'normal !important',
    lineHeight: "20px !important"
  },
  '& .MuiDataGrid-cell': {
    whiteSpace: 'normal !important',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  }
}

export const UserStatusColors: any = {
  just_created: "info",
  wait_for_verification: "warning",
  verified: "success",
  rejected: 'error'
}

export enum UserStatusEnums {
  JUST_CREATED = "just_created",
  WAIT_FOR_VERIFICATION = "wait_for_verification",
  VERIFIED = "verified",
  REJECTED = "rejected",
}

export enum ArticleStatusEnums {
  PROMOTION = "promotion",
  NEWS_FEED = "newsFeed",
  ABC = "ABC",
}

export const StatusColors: any = {
  active: "success",
  deactive: "warning",
  expired: 'error'
}
