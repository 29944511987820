import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { ICustomer } from "../../types/customer";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface CustomerState {
  fetchCustomersStatus: StatusEnums;
  customers: IPagination<ICustomer> | null,
  resError: IResError | null,
  addedResult: { id: string, paht: string } | null
}

const initialState: CustomerState = {
  fetchCustomersStatus: StatusEnums.IDLE,
  customers: null,
  resError: null,
  addedResult: null

};

export const fetchCustomersAsync = createAsyncThunk(
  "customer/fetchCustormers",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/customers/search", filters);
    return response.data;
  }
);


export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    resetFetchCustomersStatus: (state) => {
      state.fetchCustomersStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch customer 
      .addCase(fetchCustomersAsync.pending, (state, action) => {
        state.fetchCustomersStatus = StatusEnums.LOADING;
      })
      .addCase(fetchCustomersAsync.fulfilled, (state, action) => {
        state.fetchCustomersStatus = StatusEnums.SUCCESS;
        state.customers = action.payload as IPagination<ICustomer>;
      })
      .addCase(fetchCustomersAsync.rejected, (state, action) => {
        state.fetchCustomersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetFetchCustomersStatus } = customerSlice.actions;
export const selectCustomers = (state: RootState) => state.customer.customers;
export const selectFetchCustomersStatus = (state: RootState) => state.customer.fetchCustomersStatus;

export default customerSlice.reducer;
