import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IOffer, IOfferCreate } from "../../types/offer";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface OfferState {
  deleteOffersStatus: StatusEnums;
  fetchOffersStatus: StatusEnums;
  fetchOfferStatus: StatusEnums;
  addOfferStatus: StatusEnums;
  offers: IPagination<IOffer> | null,
  offer: IOffer | null,
  resError: IResError | null,
}

const initialState: OfferState = {
  deleteOffersStatus: StatusEnums.IDLE,
  fetchOffersStatus: StatusEnums.IDLE,
  fetchOfferStatus: StatusEnums.IDLE,
  addOfferStatus: StatusEnums.IDLE,
  offers: null,
  offer: null,
  resError: null,

};

export const deleteOffersAsync = createAsyncThunk(
  "attachments/deleteOffers",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/offers/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchOfferAsync = createAsyncThunk(
  "offer/fetchOffer",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/offers/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addOfferAsync = createAsyncThunk(
  "offer/addOffer",
  async (offer: IOfferCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/offers/add", offer);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchOffersAsync = createAsyncThunk(
  "offers/fetchOffers",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/offers/search", filters);
    return response.data;
  }
);


export const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    resetDeleteOffersStatus: (state) => {
      state.deleteOffersStatus = StatusEnums.IDLE;
    },
    resetFetchOffersStatus: (state) => {
      state.fetchOffersStatus = StatusEnums.IDLE;
    },
    resetAddOfferStatus: (state) => {
      state.addOfferStatus = StatusEnums.IDLE;
    },
    resetFetchOfferStatus: (state) => {
      state.fetchOfferStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // delete offers 
      .addCase(deleteOffersAsync.pending, (state) => {
        state.deleteOffersStatus = StatusEnums.LOADING;
      })
      .addCase(deleteOffersAsync.fulfilled, (state) => {
        state.deleteOffersStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteOffersAsync.rejected, (state, action) => {
        state.deleteOffersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch offer
      .addCase(fetchOfferAsync.pending, (state, action) => {
        state.fetchOfferStatus = StatusEnums.LOADING;
      })
      .addCase(fetchOfferAsync.fulfilled, (state, action) => {
        state.fetchOfferStatus = StatusEnums.SUCCESS;
        state.offer = action.payload;
      })
      .addCase(fetchOfferAsync.rejected, (state, action) => {
        state.fetchOfferStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add offer
      .addCase(addOfferAsync.pending, (state) => {
        state.addOfferStatus = StatusEnums.LOADING;
      })
      .addCase(addOfferAsync.fulfilled, (state, action) => {
        state.addOfferStatus = StatusEnums.SUCCESS;
      })
      .addCase(addOfferAsync.rejected, (state, action) => {
        state.addOfferStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch offers 
      .addCase(fetchOffersAsync.pending, (state, action) => {
        state.fetchOffersStatus = StatusEnums.LOADING;
      })
      .addCase(fetchOffersAsync.fulfilled, (state, action) => {
        state.fetchOffersStatus = StatusEnums.SUCCESS;
        state.offers = action.payload as IPagination<IOffer>;
      })
      .addCase(fetchOffersAsync.rejected, (state, action) => {
        state.fetchOffersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetDeleteOffersStatus, resetFetchOfferStatus, resetFetchOffersStatus, resetAddOfferStatus } = offerSlice.actions;


export const selectOffers = (state: RootState) => state.offer.offers;
export const selectOffer = (state: RootState) => state.offer.offer;
export const selectOfferCount = (state: RootState) => state.offer.offers?.count;
export const selectFetchOffersStatus = (state: RootState) => state.offer.fetchOffersStatus;
export const selectAddOfferStatus = (state: RootState) => state.offer.addOfferStatus;
export const selectFetchOfferStatus = (state: RootState) => state.offer.fetchOfferStatus;
export const selectDeleteOffersStatus = (state: RootState) => state.offer.deleteOffersStatus;
export const selectOfferResError = (state: RootState) => state.offer.resError;

export default offerSlice.reducer;
