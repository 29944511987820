import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface INotificationCountSeen {
  fetchNotifiCountSeensStatus: StatusEnums;
  countSeens: IPagination<INotificationCountSeen> | null,
  resError: IResError | null,
}

const initialState: INotificationCountSeen = {
  fetchNotifiCountSeensStatus: StatusEnums.IDLE,
  countSeens: null,
  resError: null,
};

export const fetchNotifiCountSeensAsync = createAsyncThunk(
  "countSeen/fetchNotifiCountSeen",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/notifications/search-notif-user", filters);
    return response.data;
  }
);
export const countSeenSlice = createSlice({
  name: "countSeen",
  initialState,
  reducers: {
    resetFetchNotifiCountSeensStatus: (state) => {
      state.fetchNotifiCountSeensStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch notifi countSeen
      .addCase(fetchNotifiCountSeensAsync.pending, (state, action) => {
        state.fetchNotifiCountSeensStatus = StatusEnums.LOADING;
      })
      .addCase(fetchNotifiCountSeensAsync.fulfilled, (state, action) => {
        state.fetchNotifiCountSeensStatus = StatusEnums.SUCCESS;
        state.countSeens = action.payload as IPagination<INotificationCountSeen>;
      })
      .addCase(fetchNotifiCountSeensAsync.rejected, (state, action) => {
        state.fetchNotifiCountSeensStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchNotifiCountSeensStatus } = countSeenSlice.actions;

export const selectNotifiCountSeens = (state: RootState) => state.countSeen.countSeens;
export const selectNotifiCountSeenCount = (state: RootState) => state.countSeen.countSeens?.count;
export const selectFetchNotifiCountSeensStatus = (state: RootState) => state.countSeen.fetchNotifiCountSeensStatus;
export const selectNotifiCountSeenResError = (state: RootState) => state.countSeen.resError;

export default countSeenSlice.reducer;
