import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import { IBuyService, IChangePassword, IUserCreate, IUserProfileUpdate, IUser } from "../../types/user";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface UserState {
  value: number;
  fetchRanksStatus: StatusEnums;
  deleteUserStatus: StatusEnums;
  activeUsersStatus: StatusEnums;
  validateUserStatus: StatusEnums;
  addUserSipStatus: StatusEnums;
  resetPasswordStatus: StatusEnums;
  changePasswordStatus: StatusEnums;
  buyServiceStatus: StatusEnums;
  deleteUsersStatus: StatusEnums;
  fetchUsersStatus: StatusEnums;
  fetchUserAdminsStatus: StatusEnums;
  fetchUsersAutoCompleteStatus: StatusEnums;
  uploadAvatarStatus: StatusEnums;
  deleteAvatarStatus: StatusEnums;
  addUserStatus: StatusEnums;
  updateUserStatus: StatusEnums;
  updateUserProfileStatus: StatusEnums;
  fetchUserStatus: StatusEnums;
  addPointStatus: StatusEnums;
  addRankPointStatus: StatusEnums;
  users: IPagination<IUser> | null,
  autoCompleteUsers: IPagination<IUser> | null,
  user: IUser | null,
  filters: IFilters | null,
  resError: IResError | null,
  showFilters: boolean,

}

const initialState: UserState = {
  value: 0,
  fetchRanksStatus: StatusEnums.IDLE,
  deleteUserStatus: StatusEnums.IDLE,
  activeUsersStatus: StatusEnums.IDLE,
  validateUserStatus: StatusEnums.IDLE,
  addUserSipStatus: StatusEnums.IDLE,
  resetPasswordStatus: StatusEnums.IDLE,
  changePasswordStatus: StatusEnums.IDLE,
  buyServiceStatus: StatusEnums.IDLE,
  deleteUsersStatus: StatusEnums.IDLE,
  fetchUsersStatus: StatusEnums.IDLE,
  fetchUserAdminsStatus:StatusEnums.IDLE,
  fetchUsersAutoCompleteStatus: StatusEnums.IDLE,
  uploadAvatarStatus: StatusEnums.IDLE,
  deleteAvatarStatus: StatusEnums.IDLE,
  addUserStatus: StatusEnums.IDLE,
  updateUserStatus: StatusEnums.IDLE,
  updateUserProfileStatus: StatusEnums.IDLE,
  fetchUserStatus: StatusEnums.IDLE,
  addPointStatus: StatusEnums.IDLE,
  addRankPointStatus: StatusEnums.IDLE,
  users: null,
  autoCompleteUsers: null,
  user: null,
  filters: null,
  resError: null,
  showFilters: false,
};

export const fetchRanksAsync = createAsyncThunk(
  "user/fetchRanksAsync",
  async () => {
    const response = await axiosInstance.get("/api/ranks/all");
    return response.data;
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUser",
  async (id: string, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/delete/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const verifyUserAsync = createAsyncThunk(
  "user/verify",
  async (payload: { id: string, action: any }, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/verify", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const addUserSipAsync = createAsyncThunk(
  "user/addUserSip",
  async (id: string, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/sip/add", { id });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const uploadAvatarAsync = createAsyncThunk(
  "user/uploadAvatar",
  async (uploadedFile: any, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("uploadedFile", uploadedFile);
      const response = await axiosInstance.post("/api/users/avatar/upload", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteAvatarAsync = createAsyncThunk(
  "user/deleteAvatar",
  async (_, thunkApi) => {
    try {
      const response = await axiosInstance.delete("/api/users/avatar/delete");
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "user/resetPassword",
  async (payload: IBuyService, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/reset-password", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "user/changePassword",
  async (payload: IChangePassword, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/change-password", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const buyServiceAsync = createAsyncThunk(
  "user/buyService",
  async (payload: IBuyService, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/buy-service", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteUsersAsync = createAsyncThunk(
  "user/deleteUsers",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const activeUsersAsync = createAsyncThunk(
  "user/activeUsers",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/active/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const fetchUserAsync = createAsyncThunk(
  "user/fetchUser",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/users/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchUsersAsync = createAsyncThunk(
  "user/fetchUsers",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/users/search", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const fetchUserAdminsAsync = createAsyncThunk(
  "user/fetchUserAdmins",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/users/search-admin", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchUsersAutoCompleteAsync = createAsyncThunk(
  "user/fetchUsersAutoComplete",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/users/search", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const addUserAsync = createAsyncThunk(
  "user/addUser",
  async (user: IUserCreate, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/users/add", user);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/users/update/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateUserProfileAsync = createAsyncThunk(
  "user/updateUserProfile",
  async (payload: IUserProfileUpdate, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/users/me/update", payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addPointAsync = createAsyncThunk(
  "user/addPoint",
  async (params: any, thunkApi) => {
    try {
      params.payload.userId = params.id;
      params.payload.fromAt = new Date(params.payload.fromAt.replace("T", " ")).toISOString();
      params.payload.toAt = new Date(params.payload.toAt.replace("T", " ")).toISOString();

      const response = await axiosInstance.post("/api/users/add-point", params.payload);
      return;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addRankPointAsync = createAsyncThunk(
  "user/addRankPoint",
  async (params: any, thunkApi) => {
    try {
      params.payload.userId = params.id;
      params.payload.fromAt = new Date(params.payload.fromAt.replace("T", " ")).toISOString();
      params.payload.toAt = new Date(params.payload.toAt.replace("T", " ")).toISOString();

      const response = await axiosInstance.post("/api/users/add-rank-point", params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showUserFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetUserStatus: (state) => {
      state.addUserSipStatus = StatusEnums.IDLE;
      state.buyServiceStatus = StatusEnums.IDLE;
      state.addUserStatus = StatusEnums.IDLE;
      state.updateUserStatus = StatusEnums.IDLE;
      state.deleteUsersStatus = StatusEnums.IDLE;
      state.resetPasswordStatus = StatusEnums.IDLE;
      state.fetchUsersAutoCompleteStatus = StatusEnums.IDLE;
      state.uploadAvatarStatus = StatusEnums.IDLE;
      state.deleteAvatarStatus = StatusEnums.IDLE;
      state.activeUsersStatus = StatusEnums.IDLE;
    },
    resetUpdateUserProfileStatus: (state) => {
      state.updateUserProfileStatus = StatusEnums.IDLE;
    },
    resetDeleteUserStatus: (state) => {
      state.deleteUserStatus = StatusEnums.IDLE;
    },
    resetActiveUserStatus: (state) => {
      state.activeUsersStatus = StatusEnums.IDLE;
    },
    resetChangePasswordStatus: (state) => {
      state.changePasswordStatus = StatusEnums.IDLE;
    },
    resetFetchUserStatus: (state) => {
      state.fetchUserStatus = StatusEnums.IDLE;
    },
    resetFetchUserAdminsStatus: (state) => {
      state.fetchUserAdminsStatus = StatusEnums.IDLE;
    },
    resetValidaStatus: (state) => {
      state.validateUserStatus = StatusEnums.IDLE;
    },
    resetAddUserStatus: (state) => {
      state.addUserStatus = StatusEnums.IDLE;
    },
    setUserFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    resetFetchRanksStatus: (state) => {
      state.fetchRanksStatus = StatusEnums.IDLE;
    },
    resetAddPointStatus: (state) => {
      state.addPointStatus = StatusEnums.IDLE;
    },
    resetAddRankPointStatus: (state) => {
      state.addRankPointStatus = StatusEnums.IDLE;
    },
    resetUpdateUserStatus: (state) => {
      state.updateUserStatus = StatusEnums.IDLE;
    },
   
  },
  extraReducers: (builder) => {
    builder
      // validate user
      .addCase(fetchRanksAsync.pending, (state) => {
        state.fetchRanksStatus = StatusEnums.LOADING;
      })
      .addCase(fetchRanksAsync.fulfilled, (state) => {
        state.fetchRanksStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(fetchRanksAsync.rejected, (state, action) => {
        state.fetchRanksStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // validate user
      .addCase(deleteUserAsync.pending, (state) => {
        state.deleteUserStatus = StatusEnums.LOADING;
      })
      .addCase(deleteUserAsync.fulfilled, (state) => {
        state.deleteUserStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.deleteUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // validate user
      .addCase(verifyUserAsync.pending, (state) => {
        state.validateUserStatus = StatusEnums.LOADING;
      })
      .addCase(verifyUserAsync.fulfilled, (state) => {
        state.validateUserStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(verifyUserAsync.rejected, (state, action) => {
        state.validateUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // upload avatar 
      .addCase(addUserSipAsync.pending, (state) => {
        state.addUserSipStatus = StatusEnums.LOADING;
      })
      .addCase(addUserSipAsync.fulfilled, (state) => {
        state.addUserSipStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(addUserSipAsync.rejected, (state, action) => {
        state.addUserSipStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // upload avatar 
      .addCase(uploadAvatarAsync.pending, (state) => {
        state.uploadAvatarStatus = StatusEnums.LOADING;
      })
      .addCase(uploadAvatarAsync.fulfilled, (state) => {
        state.uploadAvatarStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(uploadAvatarAsync.rejected, (state, action) => {
        state.uploadAvatarStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete avatar 
      .addCase(deleteAvatarAsync.pending, (state) => {
        state.deleteAvatarStatus = StatusEnums.LOADING;
      })
      .addCase(deleteAvatarAsync.fulfilled, (state) => {
        state.deleteAvatarStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(deleteAvatarAsync.rejected, (state, action) => {
        state.deleteAvatarStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // change password 
      .addCase(changePasswordAsync.pending, (state) => {
        state.changePasswordStatus = StatusEnums.LOADING;
      })
      .addCase(changePasswordAsync.fulfilled, (state) => {
        state.changePasswordStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(changePasswordAsync.rejected, (state, action) => {
        state.changePasswordStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // reset service 
      .addCase(resetPasswordAsync.pending, (state) => {
        state.resetPasswordStatus = StatusEnums.LOADING;
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.resetPasswordStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.resetPasswordStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // buy service 
      .addCase(buyServiceAsync.pending, (state) => {
        state.buyServiceStatus = StatusEnums.LOADING;
      })
      .addCase(buyServiceAsync.fulfilled, (state) => {
        state.buyServiceStatus = StatusEnums.SUCCESS;
        state.resError = null;
      })
      .addCase(buyServiceAsync.rejected, (state, action) => {
        state.buyServiceStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete users 
      .addCase(deleteUsersAsync.pending, (state) => {
        state.deleteUsersStatus = StatusEnums.LOADING;
      })
      .addCase(deleteUsersAsync.fulfilled, (state) => {
        state.deleteUsersStatus = StatusEnums.SUCCESS;
      })
      .addCase(deleteUsersAsync.rejected, (state, action) => {
        state.deleteUsersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;

      })
      // active user
      .addCase(activeUsersAsync.pending, (state) => {
        state.activeUsersStatus = StatusEnums.LOADING;
      })
      .addCase(activeUsersAsync.fulfilled, (state) => {
        state.activeUsersStatus = StatusEnums.SUCCESS;
      })
      .addCase(activeUsersAsync.rejected, (state, action) => {
        state.activeUsersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;

      })
      // fetch users 
      .addCase(fetchUsersAsync.pending, (state, action) => {
        state.fetchUsersStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.fetchUsersStatus = StatusEnums.SUCCESS;
        state.users = action.payload as IPagination<IUser>;
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.fetchUsersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch user Admin
      .addCase(fetchUserAdminsAsync.pending, (state, action) => {
        state.fetchUserAdminsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUserAdminsAsync.fulfilled, (state, action) => {
        state.fetchUserAdminsStatus = StatusEnums.SUCCESS;
        state.users = action.payload as IPagination<IUser>;
      })
      .addCase(fetchUserAdminsAsync.rejected, (state, action) => {
        state.fetchUserAdminsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch users  auto complete
      .addCase(fetchUsersAutoCompleteAsync.pending, (state) => {
        state.fetchUsersAutoCompleteStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUsersAutoCompleteAsync.fulfilled, (state, action) => {
        state.fetchUsersAutoCompleteStatus = StatusEnums.SUCCESS;
        state.autoCompleteUsers = action.payload as IPagination<IUser>;
      })
      .addCase(fetchUsersAutoCompleteAsync.rejected, (state, action) => {
        state.fetchUsersAutoCompleteStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add user
      .addCase(addUserAsync.pending, (state) => {
        state.addUserStatus = StatusEnums.LOADING;
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.addUserStatus = StatusEnums.SUCCESS;
        setImmediate(() => {
          state.addUserStatus = StatusEnums.IDLE;
        })
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.addUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch user
      .addCase(fetchUserAsync.pending, (state) => {
        state.fetchUserStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.fetchUserStatus = StatusEnums.SUCCESS;
        state.user = action.payload;
      })
      .addCase(fetchUserAsync.rejected, (state, action) => {
        state.fetchUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update user
      .addCase(updateUserAsync.pending, (state) => {
        state.updateUserStatus = StatusEnums.LOADING;
      })
      .addCase(updateUserAsync.fulfilled, (state) => {
        state.updateUserStatus = StatusEnums.SUCCESS;
        state.user = null;
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.updateUserStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update user profile
      .addCase(updateUserProfileAsync.pending, (state) => {
        state.updateUserProfileStatus = StatusEnums.LOADING;
      })
      .addCase(updateUserProfileAsync.fulfilled, (state) => {
        state.updateUserProfileStatus = StatusEnums.SUCCESS;
      })
      .addCase(updateUserProfileAsync.rejected, (state, action) => {
        state.updateUserProfileStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add point
      .addCase(addPointAsync.pending, (state) => {
        state.addPointStatus = StatusEnums.LOADING;
      })
      .addCase(addPointAsync.fulfilled, (state) => {
        state.addPointStatus = StatusEnums.SUCCESS;
      })
      .addCase(addPointAsync.rejected, (state, action) => {
        state.addPointStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // add rank point
      .addCase(addRankPointAsync.pending, (state) => {
        state.addRankPointStatus = StatusEnums.LOADING;
      })
      .addCase(addRankPointAsync.fulfilled, (state) => {
        state.addRankPointStatus = StatusEnums.SUCCESS;
      })
      .addCase(addRankPointAsync.rejected, (state, action) => {
        state.addRankPointStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      ;
  },
});

export const { resetFetchUserAdminsStatus, resetUpdateUserProfileStatus, resetDeleteUserStatus, resetChangePasswordStatus, resetFetchUserStatus, resetValidaStatus, resetAddUserStatus, resetUserStatus, setUserFilters, showUserFilters, resetAddPointStatus, resetAddRankPointStatus, resetActiveUserStatus, resetUpdateUserStatus } = userSlice.actions;

export const selectUsers = (state: RootState) => state.user.users;
export const selectAutoCompleteUsers = (state: RootState) => state.user.autoCompleteUsers;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserFilters = (state: RootState) => state.user.filters;
export const selectUserCount = (state: RootState) => state.user.users?.count;
export const selectBuyServiceStatus = (state: RootState) => state.user.buyServiceStatus;
export const selectDeleteUsersStatus = (state: RootState) => state.user.deleteUsersStatus;
export const selectFetchUsersStatus = (state: RootState) => state.user.fetchUsersStatus;
export const selectFetchUserAdminsStatus = (state: RootState) => state.user.fetchUserAdminsStatus;
export const selectAddUserStatus = (state: RootState) => state.user.addUserStatus;
export const selectFetchUserStatus = (state: RootState) => state.user.fetchUserStatus;
export const selectUpdateUserStatus = (state: RootState) => state.user.updateUserStatus;
export const selectUpdateUserProfileStatus = (state: RootState) => state.user.updateUserProfileStatus;
export const selectResetPasswordStatus = (state: RootState) => state.user.resetPasswordStatus;
export const selectChangePasswordStatus = (state: RootState) => state.user.changePasswordStatus;
export const selectFetchUsersAutoCompleteStatus = (state: RootState) => state.user.fetchUsersAutoCompleteStatus;
export const selectUploadAvatarStatus = (state: RootState) => state.user.uploadAvatarStatus;
export const selectDeleteAvatarStatus = (state: RootState) => state.user.deleteAvatarStatus;
export const selectAddUserSipStatus = (state: RootState) => state.user.addUserSipStatus;
export const selectUserResError = (state: RootState) => state.user.resError;
export const selectShowUserFilters = (state: RootState) => state.user.showFilters;
export const selectVerifyUserStatus = (state: RootState) => state.user.validateUserStatus;
export const selectDeleteUserStatus = (state: RootState) => state.user.deleteUserStatus;
export const selectUserStatuses = (state: RootState) => state.user.users;
export const selectAddPointStatus = (state: RootState) => state.user.addPointStatus;
export const selectAddRankPointStatus = (state: RootState) => state.user.addRankPointStatus;
export const selectActiveUsersStatus = (state: RootState) => state.user.activeUsersStatus;

export default userSlice.reducer;
