import { DevicesOtherOutlined, Feed, Notifications } from "@mui/icons-material";
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FlagIcon from '@mui/icons-material/Flag';
import GroupsIcon from '@mui/icons-material/Groups';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PlaceIcon from '@mui/icons-material/Place';
import { Users } from "react-feather";
import DiscountIcon from '@mui/icons-material/Discount';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LabelIcon from '@mui/icons-material/Label';
import TaskIcon from '@mui/icons-material/Task';
const otherSection = [
    {
      href: "/admin-layout/template-tasks",
      icon: LabelIcon,
      title: "templateTask",
    },
    {
      href: "/admin-layout/created-tasks",
      icon: TaskIcon,
      title: "createdTask",
    },
]
const gneralSection = [
  {
    href: "/admin-layout/users",
    icon: Users,
    title: "accounts",
  },
  {
    href: "/admin-layout/employees",
    icon: PersonIcon,
    title: "employee",
  },
  {
    href: "/admin-layout/plus-points",
    icon: AddCardOutlinedIcon,
    title: "plus point",
  },
  {
    href: "/admin-layout/devices",
    icon: DevicesOtherOutlined,
    title: "devices",
  },
  {
    href: "/admin-layout/articles",
    icon: Feed,
    title: "article",
  },
  {
    href: "/admin-layout/notifications",
    icon: Notifications,
    title: "notification",
  },
  {
    href: "/admin-layout/missions",
    icon: FlagIcon,
    title: "mission",
  },
  {
    href: "/admin-layout/approve-missions",
    icon: CheckCircleIcon,
    title: "approvemission",
  },
  {
    href: "/admin-layout/vouchers",
    icon: ConfirmationNumberIcon,
    title: "voucher",
  },
  {
    href: "/admin-layout/groups",
    icon: GroupsIcon,
    title: "group",
  },
  {
    href: "/admin-layout/bookings",
    icon: AddShoppingCartIcon,
    title: "booking",
  },
  {
    href: "/admin-layout/activitys",
    icon: CellTowerIcon,
    title: "activity",
  },
  {
    href: "/admin-layout/releases",
    icon: NewReleasesIcon,
    title: "release",
  },
  {
    href: "/admin-layout/feedbacks",
    icon: FeedbackIcon,
    title: "feedback",
  },
  {
    href: "/admin-layout/clubs",
    icon: PlaceIcon,
    title: "club"
  },
  {
    href: "/admin-layout/tags",
    icon: DiscountIcon,
    title: "feedtag"
  },
  {
    href: "/admin-layout/system-logs",
    icon: SettingsSystemDaydreamOutlinedIcon,
    title: "systemLogs",
  },
  {
    href: "/admin-layout/transactions",
    icon: ReceiptLongIcon,
    title: "transaction",
  },
  {
    href: "/admin-layout/upload-rank",
    icon: UploadFileIcon,
    title: "uploadRank",
  },
  {
    href: "/admin-layout/task",
    icon: LabelIcon,
    title: "task",
    children: otherSection
  },
]

const navItems = [
  {
    title: "",
    pages: gneralSection,
  },
];

export default navItems;
