import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IBilliardTbale } from "../../types/billiard-table";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface BilliardTableState {
  fetchBilliardTablesStatus: StatusEnums;
  billiardTbales: IPagination<IBilliardTbale> | null,
  resError: IResError | null,
  addedResult: { id: string, paht: string } | null
}

const initialState: BilliardTableState = {
  fetchBilliardTablesStatus: StatusEnums.IDLE,
  billiardTbales: null,
  resError: null,
  addedResult: null

};

export const fetchBilliardTablesAsync = createAsyncThunk(
  "billiardTbales/fetchBilliardTables",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/billiard-tables/search", filters);
    return response.data;
  }
);


export const billiardTbalesSlice = createSlice({
  name: "billiardTbales",
  initialState,
  reducers: {
    resetFetchBilliardTablesStatus: (state) => {
      state.fetchBilliardTablesStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch attachments 
      .addCase(fetchBilliardTablesAsync.pending, (state, action) => {
        state.fetchBilliardTablesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchBilliardTablesAsync.fulfilled, (state, action) => {
        state.fetchBilliardTablesStatus = StatusEnums.SUCCESS;
        state.billiardTbales = action.payload as IPagination<IBilliardTbale>;
      })
      .addCase(fetchBilliardTablesAsync.rejected, (state, action) => {
        state.fetchBilliardTablesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetFetchBilliardTablesStatus } = billiardTbalesSlice.actions;
export const selectBilliardTables = (state: RootState) => state.billiardTbale.billiardTbales;
export const selectFetchBilliardTablesStatus = (state: RootState) => state.billiardTbale.fetchBilliardTablesStatus;

export default billiardTbalesSlice.reducer;
