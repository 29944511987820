import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IPosition } from "../../types/position";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface PositionState {
  fetchPositionsStatus: StatusEnums;
  fetchFilterPositionsStatus: StatusEnums;
  positions: IPosition[] | null,
  filterPositions: IPosition[] | null,
  position: IPosition | null,
  resError: IResError | null,
}

const initialState: PositionState = {
  fetchPositionsStatus: StatusEnums.IDLE,
  fetchFilterPositionsStatus: StatusEnums.IDLE,
  positions: null,
  filterPositions:null,
  position: null,
  resError: null,

};

export const fetchPositionsAsync = createAsyncThunk(
  "positions/fetchPositions",
  async (filters?: {}) => {
    const response = await axiosInstance.get("/api/positions/all", { params: filters });
    return response.data;
  }
);
export const fetchFilterPositionsAsync = createAsyncThunk(
  "positions/fetchFilterPositions",
  async (filters?: {}) => {
    const response = await axiosInstance.get("/api/positions/all", { params: filters });
    return response.data;
  }
);
export const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    resetFetchPositionsStatus: (state) => {
      state.fetchPositionsStatus = StatusEnums.IDLE;
    },
    resetFetchFilterPositionsStatus: (state) => {
      state.fetchFilterPositionsStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch position 
      .addCase(fetchPositionsAsync.pending, (state, action) => {
        state.fetchPositionsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchPositionsAsync.fulfilled, (state, action) => {
        state.fetchPositionsStatus = StatusEnums.SUCCESS;
        state.positions = action.payload as IPosition[];
      })
      .addCase(fetchPositionsAsync.rejected, (state, action) => {
        state.fetchPositionsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
       // fetch filter position 
       .addCase(fetchFilterPositionsAsync.pending, (state, action) => {
        state.fetchFilterPositionsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchFilterPositionsAsync.fulfilled, (state, action) => {
        state.fetchFilterPositionsStatus = StatusEnums.SUCCESS;
        state.filterPositions = action.payload as IPosition[];
      })
      .addCase(fetchFilterPositionsAsync.rejected, (state, action) => {
        state.fetchFilterPositionsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetFetchPositionsStatus, resetFetchFilterPositionsStatus} = positionSlice.actions;

export const selectPositions = (state: RootState) => state.position.positions;
export const selectPosition = (state: RootState) => state.position.position;
export const selectFilterPositions = (state: RootState) => state.position.filterPositions;
export const selectFetchPositionsStatus = (state: RootState) => state.position.fetchPositionsStatus;
export const selectPositionResError = (state: RootState) => state.position.resError;

export default positionSlice.reducer;
