import { Feed, Notifications } from "@mui/icons-material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FlagIcon from '@mui/icons-material/Flag';
import GroupsIcon from '@mui/icons-material/Groups';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const gneralSection = [
  {
    href: "/marketing-layout/articles",
    icon: Feed,
    title: "article",
  },
  {
    href: "/marketing-layout/notifications",
    icon: Notifications,
    title: "notification",
  },
  {
    href: "/marketing-layout/missions",
    icon: FlagIcon,
    title: "mission",
  },
  {
    href: "/admin-layout/approve-missions",
    icon: CheckCircleIcon,
    title: "approvemission",
  },
  {
    href: "/marketing-layout/vouchers",
    icon: ConfirmationNumberIcon,
    title: "voucher",
  },
  {
    href: "/marketing-layout/groups",
    icon: GroupsIcon,
    title: "group",
  },
  {
    href: "/marketing-layout/bookings",
    icon: AddShoppingCartIcon,
    title: "booking",
  },
  {
    href: "/marketing-layout/feedbacks",
    icon: FeedbackIcon,
    title: "feedback",
  },
]
const navItems = [
  {
    title: "",
    pages: gneralSection,
  },
];

export default navItems;
