import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IArticle, IArticleCreate } from "../../types/article";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IClub, IClubCreate } from "../../types/club";

export interface ClubState {
  fetchClubsStatus: StatusEnums;
  deleteClubsStatus: StatusEnums;
  addClubStatus: StatusEnums;
  updateClubStatus: StatusEnums;
  fetchClubStatus: StatusEnums;
  clubs: IPagination<IClub> | null,
  club: IClub | null,
  filters: IFilters | null,
  resError: IResError | null,
  showFilters: boolean,
}

const initialState: ClubState = {
  fetchClubsStatus: StatusEnums.IDLE,
  deleteClubsStatus: StatusEnums.IDLE,
  addClubStatus: StatusEnums.IDLE,
  updateClubStatus: StatusEnums.IDLE,
  fetchClubStatus: StatusEnums.IDLE,
  clubs: null,
  club: null,
  filters: null,
  resError: null,
  showFilters: false,
};
export const deleteClubsAsync = createAsyncThunk(
  "clubs/deleteClubs",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/branchs/delete/many", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);  
export const fetchClubAsync = createAsyncThunk(
  "club/fetchClub",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/branchs/detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchClubsAsync = createAsyncThunk(
  "club/fetchClubs",
  async (filters?: IFilters | null) => {
    const response = await axiosInstance.post("/api/branchs/search", filters);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const addClubAsync = createAsyncThunk(
  "club/addClub",
  async (club: IClubCreate, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("branchName", club.branchName);
      formData.append("address", club.address);
      formData.append("locationName", club.locationName);
      // formData.append("contactNumber", club.contactNumber);
      // formData.append("createdDate", club.createdDate);
      const response = await axiosInstance.post("/api/branchs/add", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateClubAsync = createAsyncThunk(
  "club/updateClub",
  async (params: any, thunkApi) => {
    try {
      const club = params.payload;
      const formData = new FormData();
      formData.append("branchName", club.branchName);
      formData.append("address", club.address);
      formData.append("locationName", club.locationName);
      formData.append("contactNumber", club.contactNumber);
      formData.append("createdDate", club.createdDate);
      const response = await axiosInstance.put("/api/clubs/update/" + params.id, formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const ClubSlice = createSlice({
  name: "club",
  initialState,
  reducers: {
    showClubFilters: (state, action) => {
      state.showFilters = action.payload as boolean;
    },
    resetClubStatus: (state) => {
      state.fetchClubStatus = StatusEnums.IDLE;
      state.addClubStatus = StatusEnums.IDLE;
      state.fetchClubsStatus = StatusEnums.IDLE;
      state.updateClubStatus = StatusEnums.IDLE;
      state.deleteClubsStatus = StatusEnums.IDLE;

    },
     resetFetchClubsStatus: (state) => {
      state.fetchClubsStatus = StatusEnums.IDLE;
    },
    resetFetchClubStatus: (state) => {
      state.fetchClubStatus = StatusEnums.IDLE;
    },
    resetUpdateClubStatus: (state) => {
      state.updateClubStatus = StatusEnums.IDLE;
    },
    resetAddClubStatus: (state) => {
      state.addClubStatus = StatusEnums.IDLE;
    },
    resetDeleteClubStatus: (state) => {
      state.deleteClubsStatus = StatusEnums.IDLE;
    },
    setClubFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
     // delete club 
     .addCase(deleteClubsAsync.pending, (state) => {
      state.deleteClubsStatus = StatusEnums.LOADING;
    })
    .addCase(deleteClubsAsync.fulfilled, (state) => {
      state.deleteClubsStatus = StatusEnums.SUCCESS;
    })
    .addCase(deleteClubsAsync.rejected, (state, action) => {
      state.deleteClubsStatus = StatusEnums.FAILED;
      state.resError = action.payload as IResError;
    })
      // fetch club 
      .addCase(fetchClubsAsync.pending, (state) => {
        state.fetchClubsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchClubsAsync.fulfilled, (state, action) => {
        state.fetchClubsStatus = StatusEnums.SUCCESS;
        state.clubs = action.payload as IPagination<IClub>;
      })
      .addCase(fetchClubsAsync.rejected, (state, action) => {
        state.fetchClubsStatus = StatusEnums.FAILED;
      })
      // add club
      .addCase(addClubAsync.pending, (state) => {
        state.addClubStatus = StatusEnums.LOADING;
      })
      .addCase(addClubAsync.fulfilled, (state, action) => {
        state.addClubStatus = StatusEnums.SUCCESS;
      })
      .addCase(addClubAsync.rejected, (state, action) => {
        state.addClubStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch club
      .addCase(fetchClubAsync.pending, (state) => {
        state.fetchClubStatus = StatusEnums.LOADING;
      })
      .addCase(fetchClubAsync.fulfilled, (state, action) => {
        state.fetchClubStatus = StatusEnums.SUCCESS;
        state.club = action.payload;
      })
      .addCase(fetchClubAsync.rejected, (state, action) => {
        state.fetchClubStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update club
      .addCase(updateClubAsync.pending, (state) => {
        state.updateClubStatus = StatusEnums.LOADING;
      })
      .addCase(updateClubAsync.fulfilled, (state) => {
        state.updateClubStatus = StatusEnums.SUCCESS;
        state.club = null;
      })
      .addCase(updateClubAsync.rejected, (state, action) => {
        state.updateClubStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      ;
  },
});

export const { resetClubStatus, resetUpdateClubStatus, resetFetchClubStatus, setClubFilters, showClubFilters, resetAddClubStatus, resetFetchClubsStatus } = ClubSlice.actions;


export const selectClubs = (state: RootState) => state.club.clubs;
export const selectClubFilters = (state: RootState) => state.club.clubs?.filters;
export const selectFetchClubsStatus = (state: RootState) => state.club.fetchClubsStatus;
export const selectAddClubStatus = (state: RootState) => state.club.addClubStatus;
export const selectFetchClubStatus = (state: RootState) => state.club.fetchClubStatus;
export const selectUpdateClubStatus = (state: RootState) => state.club.updateClubStatus;
export const selectDeleteClubsStatus = (state: RootState) => state.club.deleteClubsStatus;
export const selectClub = (state: RootState) => state.club.club;
export const selectClubResError = (state: RootState) => state.club.resError;
export const selectShowClubFilters = (state: RootState) => state.club.showFilters;

export default ClubSlice.reducer;
