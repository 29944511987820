import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { Iproject } from "../../types/project";
import { IResError } from "../../types/res-error";
import { ITask } from "../../types/task";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface ProjectState {
    fetchProjectsStatus: StatusEnums;
    fetchProjectStatus: StatusEnums;
    projects: IPagination<Iproject> | null,
    project: ITask | null,
    resError: IResError | null,
}

const initialState: ProjectState = {
    fetchProjectsStatus: StatusEnums.IDLE,
    fetchProjectStatus: StatusEnums.IDLE,
    projects: null,
    project: null,
    resError: null,

};
export const fetchProjectAsync = createAsyncThunk(
    "project/fetchProject",
    async (id: string = "", thunkApi) => {
        try {
            const response = await axiosInstance.get("/api/projects/detail/" + id);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const fetchProjectsAsync = createAsyncThunk(
    "projects/fetchProjects",
    async (filters?: IFilters) => {
        const response = await axiosInstance.post("/api/projects/search", filters);
        return response.data;
    }
);

export const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        resetFetchProjectsStatus: (state) => {
            state.fetchProjectsStatus = StatusEnums.IDLE;
        },
        resetFetchProjectStatus: (state) => {
            state.fetchProjectStatus = StatusEnums.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder
            // fetch project
            .addCase(fetchProjectAsync.pending, (state, action) => {
                state.fetchProjectStatus = StatusEnums.LOADING;
            })
            .addCase(fetchProjectAsync.fulfilled, (state, action) => {
                state.fetchProjectStatus = StatusEnums.SUCCESS;
                state.project = action.payload;
            })
            .addCase(fetchProjectAsync.rejected, (state, action) => {
                state.fetchProjectStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // fetch project
            .addCase(fetchProjectsAsync.pending, (state, action) => {
                state.fetchProjectsStatus = StatusEnums.LOADING;
            })
            .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
                state.fetchProjectsStatus = StatusEnums.SUCCESS;
                state.projects = action.payload as IPagination<Iproject>;
            })
            .addCase(fetchProjectsAsync.rejected, (state, action) => {
                state.fetchProjectsStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
    },
});

export const { resetFetchProjectStatus, resetFetchProjectsStatus } = projectsSlice.actions;


export const selectProjects = (state: RootState) => state.project.projects;
export const selectProject = (state: RootState) => state.project.project;
export const selectProjectCount = (state: RootState) => state.project.projects?.count;
export const selectFetchProjectsStatus = (state: RootState) => state.project.fetchProjectsStatus;
export const selectFetchProjectStatus = (state: RootState) => state.project.fetchProjectStatus;
export const selectProjectResError = (state: RootState) => state.project.resError;

export default projectsSlice.reducer;
