import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import attachmentReducer from "./slices/attachment";
import jurisdictionReducer from "./slices/jurisdiction";
import vacationReducer from "./slices/vacation";
import vacationTypeReducer from "./slices/vacation-type";
import offerReducer from "./slices/offer";
import chatReducer from "./slices/chat";
import deviceReducer from "./slices/device";
import cityReducer from "./slices/city";
import articleReducer from "./slices/article";
import notificationReducer from "./slices/notification";
import groupReducer from "./slices/group";
import userGroupReducer from "./slices/user-group";
import customerReducer from "./slices/customer";
import invoiceReducer from "./slices/invoice";
import billiardTbaleReducer from "./slices/billiard-table";
import bookingReducer from "./slices/booking";
import invoiceDetailReducer from "./slices/invoice-detail";
import activityReducer from "./slices/activity";
import missionReducer from "./slices/mission";
import missionProcessReducer from "./slices/mission-process";
import voucherReducer from "./slices/voucher";
import voucherUserReducer from "./slices/voucher-user";
import releaseReducer from "./slices/release";
import pointReducer from "./slices/point";
import rankPointReducer from "./slices/rank-point";
import feebackReducer from "./slices/feeback";
import clubReducer from "./slices/club";
import feedtagReducer from "./slices/feed-tag";
import systemLogReducer from "./slices/system-log";
import permissionReducer from "./slices/permission";
import countSeenReducer from "./slices/notidication-countseen";
import roleReducer from "./slices/role";
import approveMissionReducer from "./slices/approve-mission";
import employeeReducer from "./slices/employee";
import transactionReducer from "./slices/transaction";
import uploadRankReducer from "./slices/upload-rank";
import templateTaskReducer from "./slices/template-task";
import createdTaskReducer from "./slices/created-task";
import projectReducer from "./slices/project";
import positionReducer from "./slices/position";
import shiftReducer from "./slices/shift";
import areaReducer from "./slices/area";
import branchReducer from"./slices/branch";
import productReducer from "./slices/product";
import categoryReducer from"./slices/category";
import rankReducer from"./slices/rank";
import missionGroupReducer from"./slices/mission-group";
import missionAutoTypeReducer from"./slices/mission-auto-types";
export const store = configureStore({
  reducer: {
    user: userReducer,
    attachment: attachmentReducer,
    jurisdiction: jurisdictionReducer,
    vacation: vacationReducer,
    vacationType: vacationTypeReducer,
    offer: offerReducer,
    chat: chatReducer,
    device: deviceReducer,
    city: cityReducer,
    article: articleReducer,
    notification: notificationReducer,
    group: groupReducer,
    userGroup: userGroupReducer,
    customer: customerReducer,
    invoice: invoiceReducer,
    billiardTbale: billiardTbaleReducer,
    booking: bookingReducer,
    invoiceDetail: invoiceDetailReducer,
    activity: activityReducer,
    mission: missionReducer,
    missionProcess: missionProcessReducer,
    voucher: voucherReducer,
    voucherUser: voucherUserReducer,
    release: releaseReducer,
    point: pointReducer,
    rankPoint: rankPointReducer,
    feedback: feebackReducer,
    club: clubReducer,
    feedtag: feedtagReducer,
    systemLog: systemLogReducer,
    permission: permissionReducer,
    countSeen: countSeenReducer,
    role: roleReducer,
    approveMission: approveMissionReducer,
    employee: employeeReducer,
    transaction: transactionReducer,
    uploadRank:uploadRankReducer,
    templateTask: templateTaskReducer,
    createdTask: createdTaskReducer,
    project: projectReducer,
    position: positionReducer,
    shift: shiftReducer,
    area: areaReducer,
    branch: branchReducer,
    product: productReducer,
    category: categoryReducer,
    rank: rankReducer,
    missionGroup: missionGroupReducer,
    missionAutoType: missionAutoTypeReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


