import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { Permission } from "../../types/permission";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface PermissionState {
  fetchPermissionsStatus: StatusEnums;
  permissions: Permission[] | null;
  permissionMap: { [key: string]: string } | null;
}

const initialState: PermissionState = {
  fetchPermissionsStatus: StatusEnums.IDLE,
  permissions: null,
  permissionMap: null
};

export const fetchPermissionsAsync = createAsyncThunk(
  "permission/fetchPermissions",
  async (roleId?: string | null) => {
    const response = await axiosInstance.get("/api/roles/permissions", { params: { roleId } });
    return response.data;
  }
);

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionsAsync.pending, (state) => {
        state.fetchPermissionsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchPermissionsAsync.fulfilled, (state, action) => {
        state.fetchPermissionsStatus = StatusEnums.IDLE;
        state.permissions = action.payload;
        if (state.permissions) {
          state.permissionMap = {};
          for (let permission of state.permissions) {
            state.permissionMap[permission.path] = permission.name;
          }
        }
      })
      .addCase(fetchPermissionsAsync.rejected, (state, action) => {
        state.fetchPermissionsStatus = StatusEnums.FAILED;
      });
  },
});

export const selectPermissions = (state: RootState) => state.permission.permissions;
export const selectPermissionMap = (state: RootState) => state.permission.permissionMap;
export const selectFetchPermissionsStatus = (state: RootState) => state.permission.fetchPermissionsStatus;

export default permissionSlice.reducer;
