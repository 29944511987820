import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IVoucherUser } from "../../types/voucher-user";

export interface MissonState {
  fetchVoucherUsersStatus: StatusEnums;
  fetchVoucherUsersSeenStatus: StatusEnums;
  fetchVoucherUserUsedStatus: StatusEnums;
  deleteVoucherUsersStatus: StatusEnums;
  voucherUsers: IPagination<IVoucherUser> | null,
  resError: IResError | null,
}

const initialState: MissonState = {
  fetchVoucherUsersStatus: StatusEnums.IDLE,
  fetchVoucherUsersSeenStatus: StatusEnums.IDLE,
  fetchVoucherUserUsedStatus: StatusEnums.IDLE,
  deleteVoucherUsersStatus:StatusEnums.IDLE,
  voucherUsers: null,
  resError: null,
};

export const fetchVoucherUserUsedAsync = createAsyncThunk(
  "voucher/fetchVoucherUserUsed",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/vouchers/search-used", filters);
    return response.data;
  }
);

export const fetchVoucherUsersAsync = createAsyncThunk(
  "voucher/fetchVoucherUser",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/vouchers/search-account", filters);
    return response.data;
  }
);

export const fetchVoucherUsersSeenAsync = createAsyncThunk(
  "voucher/fetchVoucherUserSeen",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/vouchers/search-seen", filters);
    return response.data;
  }
);

export const deleteVoucherUsersAsync = createAsyncThunk(
  "voucher/deleteVouchers",
  async (ids: string[], thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/vouchers/delete/voucher-user", { ids });
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const voucherUserSlice = createSlice({
  name: "voucherUser",
  initialState,
  reducers: {
    resetFetchVoucherUsersStatus: (state) => {
      state.fetchVoucherUsersStatus = StatusEnums.IDLE;
    },
    resetDeleteVoucherUsersStatus: (state) => {
      state.deleteVoucherUsersStatus = StatusEnums.IDLE;
    },
    resetFetchVoucherUsersSeenStatus: (state) => {
      state.fetchVoucherUsersSeenStatus = StatusEnums.IDLE;
    },
    resetFetchVoucherUserUsedStatus: (state) => {
      state.fetchVoucherUserUsedStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch voucher user
      .addCase(fetchVoucherUsersAsync.pending, (state, action) => {
        state.fetchVoucherUsersStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVoucherUsersAsync.fulfilled, (state, action) => {
        state.fetchVoucherUsersStatus = StatusEnums.SUCCESS;
        state.voucherUsers = action.payload as IPagination<IVoucherUser>;
      })
      .addCase(fetchVoucherUsersAsync.rejected, (state, action) => {
        state.fetchVoucherUsersStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch voucher user
      .addCase(fetchVoucherUsersSeenAsync.pending, (state, action) => {
        state.fetchVoucherUsersSeenStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVoucherUsersSeenAsync.fulfilled, (state, action) => {
        state.fetchVoucherUsersSeenStatus = StatusEnums.SUCCESS;
        state.voucherUsers = action.payload as IPagination<IVoucherUser>;
      })
      .addCase(fetchVoucherUsersSeenAsync.rejected, (state, action) => {
        state.fetchVoucherUsersSeenStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch voucher user
      .addCase(fetchVoucherUserUsedAsync.pending, (state, action) => {
        state.fetchVoucherUserUsedStatus = StatusEnums.LOADING;
      })
      .addCase(fetchVoucherUserUsedAsync.fulfilled, (state, action) => {
        state.fetchVoucherUserUsedStatus = StatusEnums.SUCCESS;
        state.voucherUsers = action.payload as IPagination<IVoucherUser>;
      })
      .addCase(fetchVoucherUserUsedAsync.rejected, (state, action) => {
        state.fetchVoucherUserUsedStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
        // delete voucher  user
        .addCase(deleteVoucherUsersAsync.pending, (state) => {
          state.deleteVoucherUsersStatus = StatusEnums.LOADING;
        })
        .addCase(deleteVoucherUsersAsync.fulfilled, (state) => {
          state.deleteVoucherUsersStatus = StatusEnums.SUCCESS;
        })
        .addCase(deleteVoucherUsersAsync.rejected, (state, action) => {
          state.deleteVoucherUsersStatus = StatusEnums.FAILED;
          state.resError = action.payload as IResError;
        })
  },
});

export const { resetFetchVoucherUsersStatus,resetDeleteVoucherUsersStatus, resetFetchVoucherUsersSeenStatus, resetFetchVoucherUserUsedStatus } = voucherUserSlice.actions;

export const selectVoucherUsers = (state: RootState) => state.voucherUser.voucherUsers;
export const selectVoucherUserCount = (state: RootState) => state.voucherUser.voucherUsers?.count;
export const selectFetchVoucherUsersStatus = (state: RootState) => state.voucherUser.fetchVoucherUsersStatus;
export const selectDeleteVoucherUsersStatus = (state: RootState) => state.voucherUser.deleteVoucherUsersStatus;
export const selectFetchVoucherUsersSeenStatus = (state: RootState) => state.voucherUser.fetchVoucherUsersSeenStatus;
export const selectFetchVoucherUserUsedStatus = (state: RootState) => state.voucherUser.fetchVoucherUserUsedStatus;
export const selectVoucherUserResError = (state: RootState) => state.voucherUser.resError;

export default voucherUserSlice.reducer;
