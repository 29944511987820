import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import { ITask, ITaskCreate } from "../../types/task";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface TaskState {
    deleteTemplateTasksStatus: StatusEnums;
    fetchTemplateTasksStatus: StatusEnums;
    fetchTemplateTaskStatus: StatusEnums;
    updateTemplateTaskStatus: StatusEnums;
    addTemplateTaskStatus: StatusEnums;
    saveImageStatus: StatusEnums;
    templateTasks: IPagination<ITask> | null,
    templateTask: ITask | null,
    resError: IResError | null,
}

const initialState: TaskState = {
    deleteTemplateTasksStatus: StatusEnums.IDLE,
    fetchTemplateTasksStatus: StatusEnums.IDLE,
    fetchTemplateTaskStatus: StatusEnums.IDLE,
    addTemplateTaskStatus: StatusEnums.IDLE,
    updateTemplateTaskStatus: StatusEnums.IDLE,
    saveImageStatus: StatusEnums.IDLE,
    templateTasks: null,
    templateTask: null,
    resError: null,

};

export const deleteTasksAsync = createAsyncThunk(
    "templateTasks/deleteTasks",
    async (ids: string[], thunkApi) => {
        try {
            const response = await axiosInstance.post("/api/tasks/delete/many", { ids });
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const fetchTaskAsync = createAsyncThunk(
    "templateTask/fetchTask",
    async (id: string = "", thunkApi) => {
        try {
            const response = await axiosInstance.get("/api/tasks/detail/" + id);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addTemplateTaskAsync = createAsyncThunk(
    "templateTask/addTask",
    async (task: ITaskCreate, thunkApi) => {
        try {
            const response = await axiosInstance.post("/api/tasks/add-template", task);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const fetchTemplateTasksAsync = createAsyncThunk(
    "templateTasks/fetchTemplateTasks",
    async (filters?: IFilters) => {
        console.log("filters", filters)
        const response = await axiosInstance.post("/api/tasks/search-template", filters);
        return response.data;
    }
);

export const updateTemplateTaskAsync = createAsyncThunk(
    "templateTask/updateTemplateTask",
    async (params: any, thunkApi) => {
        try {
            const response = await axiosInstance.put("/api/tasks/update-template/" + params.id, params.payload);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const saveImageAsync = createAsyncThunk(
    "templateTask/saveImage",
    async (filters?: {}) => {
        const response = await axiosInstance.post("/api/tasks/save-image/", filters);
        return response.data;
    }
);
export const templateTasksSlice = createSlice({
    name: "templateTasks",
    initialState,
    reducers: {
        resetDeleteTemplateTasksStatus: (state) => {
            state.deleteTemplateTasksStatus = StatusEnums.IDLE;
        },
        resetFetchTemplateTasksStatus: (state) => {
            state.fetchTemplateTasksStatus = StatusEnums.IDLE;
        },
        resetAddTemplateTaskStatus: (state) => {
            state.addTemplateTaskStatus = StatusEnums.IDLE;
        },
        resetFetchTemplateTaskStatus: (state) => {
            state.fetchTemplateTaskStatus = StatusEnums.IDLE;
        },
        resetUpdateTemplateTaskStatus: (state) => {
            state.updateTemplateTaskStatus = StatusEnums.IDLE;
            state.templateTask = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // delete Task 
            .addCase(deleteTasksAsync.pending, (state) => {
                state.deleteTemplateTasksStatus = StatusEnums.LOADING;
            })
            .addCase(deleteTasksAsync.fulfilled, (state) => {
                state.deleteTemplateTasksStatus = StatusEnums.SUCCESS;
            })
            .addCase(deleteTasksAsync.rejected, (state, action) => {
                state.deleteTemplateTasksStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // fetch Task
            .addCase(fetchTaskAsync.pending, (state, action) => {
                state.fetchTemplateTaskStatus = StatusEnums.LOADING;
            })
            .addCase(fetchTaskAsync.fulfilled, (state, action) => {
                state.fetchTemplateTaskStatus = StatusEnums.SUCCESS;
                state.templateTask = action.payload;
            })
            .addCase(fetchTaskAsync.rejected, (state, action) => {
                state.fetchTemplateTaskStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // add Task
            .addCase(addTemplateTaskAsync.pending, (state) => {
                state.addTemplateTaskStatus = StatusEnums.LOADING;
            })
            .addCase(addTemplateTaskAsync.fulfilled, (state, action) => {
                state.addTemplateTaskStatus = StatusEnums.SUCCESS;
            })
            .addCase(addTemplateTaskAsync.rejected, (state, action) => {
                state.addTemplateTaskStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // fetch Task
            .addCase(fetchTemplateTasksAsync.pending, (state, action) => {
                state.fetchTemplateTasksStatus = StatusEnums.LOADING;
            })
            .addCase(fetchTemplateTasksAsync.fulfilled, (state, action) => {
                state.fetchTemplateTasksStatus = StatusEnums.SUCCESS;
                state.templateTasks = action.payload as IPagination<ITask>;
            })
            .addCase(fetchTemplateTasksAsync.rejected, (state, action) => {
                state.fetchTemplateTasksStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // update Task
            .addCase(updateTemplateTaskAsync.pending, (state) => {
                state.updateTemplateTaskStatus = StatusEnums.LOADING;
            })
            .addCase(updateTemplateTaskAsync.fulfilled, (state, action) => {
                state.updateTemplateTaskStatus = StatusEnums.SUCCESS;
            })
            .addCase(updateTemplateTaskAsync.rejected, (state, action) => {
                state.updateTemplateTaskStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // saveImageStatus Task
            .addCase(saveImageAsync.pending, (state) => {
                state.saveImageStatus = StatusEnums.LOADING;
            })
            .addCase(saveImageAsync.fulfilled, (state, action) => {
                state.saveImageStatus = StatusEnums.SUCCESS;
            })
            .addCase(saveImageAsync.rejected, (state, action) => {
                state.saveImageStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
    },
});

export const { resetDeleteTemplateTasksStatus, resetFetchTemplateTaskStatus, resetFetchTemplateTasksStatus, resetAddTemplateTaskStatus, resetUpdateTemplateTaskStatus } = templateTasksSlice.actions;


export const selectTemplateTasks = (state: RootState) => state.templateTask.templateTasks;
export const selectTemplateTask = (state: RootState) => state.templateTask.templateTask;
export const selectTaskCount = (state: RootState) => state.templateTask.templateTasks?.count;
export const selectFetchTasksStatus = (state: RootState) => state.templateTask.fetchTemplateTasksStatus;
export const selectUpdateTemplateTaskStatus = (state: RootState) => state.templateTask.updateTemplateTaskStatus;
export const selectAddTemplateTaskStatus = (state: RootState) => state.templateTask.addTemplateTaskStatus;
export const selectSaveImageStatus = (state: RootState) => state.templateTask.saveImageStatus;
export const selectFetchTaskStatus = (state: RootState) => state.templateTask.fetchTemplateTaskStatus;
export const selectDeleteTasksStatus = (state: RootState) => state.templateTask.deleteTemplateTasksStatus;
export const selectTaskResError = (state: RootState) => state.templateTask.resError;

export default templateTasksSlice.reducer;
