import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IResError } from "../../types/res-error";
import { IProduct } from "../../types/product";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IPagination } from "../../types/pagination";

export interface ProductState {
  fetchProductsStatus: StatusEnums;
  fetchProductTimesStatus: StatusEnums;
  products: IPagination<IProduct> | null,
  productTimes: IProduct[] | null,
  product: IProduct | null,
  resError: IResError | null,
}

const initialState: ProductState = {
  fetchProductsStatus: StatusEnums.IDLE,
  fetchProductTimesStatus:StatusEnums.IDLE,
  products: null,
  productTimes: null,
  product: null,
  resError: null,

};

export const fetchProductsAsync = createAsyncThunk(
  "products/fetchProducts",
  async (filters? : {}) => {
    const response = await axiosInstance.post("/api/products/search-voucher", filters);
    return response.data;
  }
);

export const fetchProductTimesAsync = createAsyncThunk(
  "products/fetchProductTimes",
  async () => {
    const response = await axiosInstance.get("/api/products/get-product-time");
    return response.data;
  }
);
export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetFetchProductsStatus: (state) => {
      state.fetchProductsStatus = StatusEnums.IDLE;
    },
    resetFetchProductTimesStatus: (state) => {
      state.fetchProductTimesStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch product 
      .addCase(fetchProductsAsync.pending, (state, action) => {
        state.fetchProductsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        state.fetchProductsStatus = StatusEnums.SUCCESS;
        state.products = action.payload as IPagination<IProduct>
        console.log("state.products",  state.products?.data.length)
      })
      .addCase(fetchProductsAsync.rejected, (state, action) => {
        state.fetchProductsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
        // fetch product time
        .addCase(fetchProductTimesAsync.pending, (state, action) => {
          state.fetchProductTimesStatus = StatusEnums.LOADING;
        })
        .addCase(fetchProductTimesAsync.fulfilled, (state, action) => {
          state.fetchProductTimesStatus = StatusEnums.SUCCESS;
          state.productTimes = action.payload as IProduct[];
        })
        .addCase(fetchProductTimesAsync.rejected, (state, action) => {
          state.fetchProductTimesStatus = StatusEnums.FAILED;
          state.resError = action.payload as IResError;
        })
  },
});

export const {resetFetchProductsStatus, resetFetchProductTimesStatus} = productSlice.actions;

export const selectProducts = (state: RootState) => state.product.products;
export const selectProductTimes = (state: RootState) => state.product.productTimes;
export const selectProduct = (state: RootState) => state.product.product;
export const selectFetchProductsStatus = (state: RootState) => state.product.fetchProductsStatus;
export const selectProductResError = (state: RootState) => state.product.resError;

export default productSlice.reducer;
