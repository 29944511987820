import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { ICategory } from "../../types/category";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface CategoryState {
  fetchCategoriesStatus: StatusEnums;
  categories: ICategory[] | null,
  category: ICategory | null,
  resError: IResError | null,
}

const initialState: CategoryState = {
  fetchCategoriesStatus: StatusEnums.IDLE,
  categories: null,
  category: null,
  resError: null,

};

export const fetchCategoriesAsync = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const response = await axiosInstance.get("/api/categories/all");
    return response.data;
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resetFetchCategoriesStatus: (state) => {
      state.fetchCategoriesStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch category 
      .addCase(fetchCategoriesAsync.pending, (state, action) => {
        state.fetchCategoriesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        state.fetchCategoriesStatus = StatusEnums.SUCCESS;
        state.categories = action.payload as ICategory[];
      })
      .addCase(fetchCategoriesAsync.rejected, (state, action) => {
        state.fetchCategoriesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const {resetFetchCategoriesStatus} = categorySlice.actions;

export const selectCategories = (state: RootState) => state.category.categories;
export const selectCategory = (state: RootState) => state.category.category;
export const selectFetchCategoriesStatus = (state: RootState) => state.category.fetchCategoriesStatus;
export const selectCategoryResError = (state: RootState) => state.category.resError;

export default categorySlice.reducer;
