import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import Footer from "../components/Footer";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import adminItems from "../components/sidebar/adminItems";
import marketingItems from "../components/sidebar/marketingItems";
import managerItems from "../components/sidebar/managerItems";
import Sidebar from "../components/sidebar/Sidebar";
import useAppDispatch from "../hooks/useAppDispatch";
import socketUtil from "../utils/socketUtil";
import useAuth from "../hooks/useAuth";

const layoutMap: any = {
  admin: adminItems,
  marketing: marketingItems,
  manager: managerItems,
}
const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

interface AdminType {
  children?: React.ReactNode;
}


const Admin: React.FC<AdminType> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    socketUtil.connect(dispatch);
    return () => socketUtil.disconnect();
  }, [])
  useEffect(() => {
    const adminLayoutPaths = [
      "/marketing-layout/articles",
      "/marketing-layout/notifications",
      "/marketing-layout/missions",
      "/marketing-layout/vouchers",
      "/marketing-layout/groups",
      "/marketing-layout/bookings",
      "/marketing-layout/feedbacks",
      "/manager-layout/articles",
      "/manager-layout/notifications",
      "/manager-layout/missions",
      "/manager-layout/vouchers",
      "/manager-layout/groups",
      "/manager-layout/bookings",
      "/manager-layout/feedbacks",
    ]; // Các đường dẫn của admin layout khác
    
    const marketingLayoutPaths = [
      "/admin-layout/users",
      "/admin-layout/plus-points",
      "/admin-layout/devices",
      "/admin-layout/articles",
      "/admin-layout/notifications",
      "/admin-layout/missions",
      "/admin-layout/vouchers",
      "/admin-layout/groups",
      "/admin-layout/bookings",
      "/admin-layout/activitys",
      "/admin-layout/releases",
      "/admin-layout/feedbacks",
      "/admin-layout/clubs",
      "/admin-layout/tags",
      "/admin-layout/system-logs",
      "/manager-layout/articles",
      "/manager-layout/notifications",
      "/manager-layout/missions",
      "/manager-layout/vouchers",
      "/manager-layout/groups",
      "/manager-layout/bookings",
      "/manager-layout/feedbacks",
    ]; // Các đường dẫn của marketing layout khác
    
    const managerLayoutPaths = [
      "/admin-layout/users",
      "/admin-layout/plus-points",
      "/admin-layout/devices",
      "/admin-layout/articles",
      "/admin-layout/notifications",
      "/admin-layout/missions",
      "/admin-layout/vouchers",
      "/admin-layout/groups",
      "/admin-layout/bookings",
      "/admin-layout/activitys",
      "/admin-layout/releases",
      "/admin-layout/feedbacks",
      "/admin-layout/clubs",
      "/admin-layout/tags",
      "/admin-layout/system-logs",
      "/marketing-layout/articles",
      "/marketing-layout/notifications",
      "/marketing-layout/missions",
      "/marketing-layout/vouchers",
      "/marketing-layout/groups",
      "/marketing-layout/bookings",
      "/marketing-layout/feedbacks",
    ]; // Các đường dẫn của manager layout khác
  
    if (user && user.role === "admin" && adminLayoutPaths.some(path => location.pathname.includes(path))) {
      navigate("/Page404");
    } else if (user && user.role === "marketing" && marketingLayoutPaths.some(path => location.pathname.includes(path))) {
      navigate("/Page404");
    } else if (user && user.role === "manager" && managerLayoutPaths.some(path => location.pathname.includes(path))) {
      navigate("/Page404");
    }
  }, [user, location, navigate]);
  
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={layoutMap[user?.role!]}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={layoutMap[user?.role!]}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={5} id="main-content">
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Admin;
