import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IEmployee, IEmployeeCreate } from "../../types/employee";

export interface EmployeeState {
    deleteEmployeesStatus: StatusEnums;
    fetchEmployeesStatus: StatusEnums;
    fetchEmployeeStatus: StatusEnums;
    updateEmployeeStatus: StatusEnums;
    addEmployeeStatus: StatusEnums;
    employees: IPagination<IEmployee> | null,
    employee: IEmployee | null,
    resError: IResError | null,
}

const initialState: EmployeeState = {
    deleteEmployeesStatus: StatusEnums.IDLE,
    fetchEmployeesStatus: StatusEnums.IDLE,
    fetchEmployeeStatus: StatusEnums.IDLE,
    addEmployeeStatus: StatusEnums.IDLE,
    updateEmployeeStatus:StatusEnums.IDLE,
    employees: null,
    employee: null,
    resError: null,

};

export const deleteEmployeesAsync = createAsyncThunk(
    "employee/deleteEmployees",
    async (ids: string[], thunkApi) => {
        try {
            const response = await axiosInstance.post("/api/employees/delete/many", { ids });
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const fetchEmployeeAsync = createAsyncThunk(
    "employee/fetchEmployee",
    async (id: string = "", thunkApi) => {
        try {
            const response = await axiosInstance.get("/api/employees/detail/" + id);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addEmployeeAsync = createAsyncThunk(
    "employee/addEmployee",
    async (employee: IEmployeeCreate, thunkApi) => {
        try {
            const formData = new FormData();
            formData.append("fullName", employee.fullName);
            formData.append("phone", employee.phone);
            formData.append("role", employee.role);
            formData.append("password", employee.password);
            formData.append("gender", employee.gender.toString());
            formData.append("email", employee.email);
            formData.append("address", employee.address);
            formData.append("branchId", employee.branchId.toString());
            formData.append("avatar", employee.avatar);
            if (employee.birthDate) {
                employee.birthDate = new Date(employee.birthDate.replace("T", " ")).toISOString();
                formData.append("birthDate", employee.birthDate);
            }
            const response = await axiosInstance.post("/api/employees/add", formData);
            return response.data;
        }
        catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const fetchEmployeesAsync = createAsyncThunk(
    "employee/fetchEmployees",
    async (filters?: IFilters) => {
        const response = await axiosInstance.post("/api/employees/search", filters);
        return response.data;
    }
);

export const updateEmployeeAsync = createAsyncThunk(
    "employee/updateEmployee",
    async (params: any, thunkApi) => {
      try {
        const employee = params.payload;
        const formData = new FormData();
        formData.append("fullName", employee.fullName);
        formData.append("phone", employee.phone);
        formData.append("role", employee.role);
        formData.append("password", employee.password);
        formData.append("gender", employee.gender.toString());
        formData.append("email", employee.email);
        formData.append("address", employee.address);
        formData.append("branchId", employee.branchId.toString());
        formData.append("avatar", employee.avatar);
        if (employee.birthDate) {
            employee.birthDate = new Date(employee.birthDate.replace("T", " ")).toISOString();
            formData.append("birthDate", employee.birthDate);
        }
        const response = await axiosInstance.put("/api/employees/update/" + params.id, formData);
        return response.data;
      }
      catch (err) {
        return thunkApi.rejectWithValue(err);
      }
    }
  );
export const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {
        resetDeleteEmployeesStatus: (state) => {
            state.deleteEmployeesStatus = StatusEnums.IDLE;
        },
        resetFetchEmployeesStatus: (state) => {
            state.fetchEmployeesStatus = StatusEnums.IDLE;
        },
        resetAddEmployeeStatus: (state) => {
            state.addEmployeeStatus = StatusEnums.IDLE;
        },
        resetFetchEmployeeStatus: (state) => {
            state.fetchEmployeeStatus = StatusEnums.IDLE;
        },
        resetUpdateEmployeeStatus: (state) => {
            state.updateEmployeeStatus = StatusEnums.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder
            // delete Employee 
            .addCase(deleteEmployeesAsync.pending, (state) => {
                state.deleteEmployeesStatus = StatusEnums.LOADING;
            })
            .addCase(deleteEmployeesAsync.fulfilled, (state) => {
                state.deleteEmployeesStatus = StatusEnums.SUCCESS;
            })
            .addCase(deleteEmployeesAsync.rejected, (state, action) => {
                state.deleteEmployeesStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // fetch Employee
            .addCase(fetchEmployeeAsync.pending, (state, action) => {
                state.fetchEmployeeStatus = StatusEnums.LOADING;
            })
            .addCase(fetchEmployeeAsync.fulfilled, (state, action) => {
                state.fetchEmployeeStatus = StatusEnums.SUCCESS;
                state.employee = action.payload;
            })
            .addCase(fetchEmployeeAsync.rejected, (state, action) => {
                state.fetchEmployeeStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // add Employee
            .addCase(addEmployeeAsync.pending, (state) => {
                state.addEmployeeStatus = StatusEnums.LOADING;
            })
            .addCase(addEmployeeAsync.fulfilled, (state, action) => {
                state.addEmployeeStatus = StatusEnums.SUCCESS;
            })
            .addCase(addEmployeeAsync.rejected, (state, action) => {
                state.addEmployeeStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
            // fetch Employee
            .addCase(fetchEmployeesAsync.pending, (state, action) => {
                state.fetchEmployeesStatus = StatusEnums.LOADING;
            })
            .addCase(fetchEmployeesAsync.fulfilled, (state, action) => {
                state.fetchEmployeesStatus = StatusEnums.SUCCESS;
                state.employees = action.payload as IPagination<IEmployee>;
            })
            .addCase(fetchEmployeesAsync.rejected, (state, action) => {
                state.fetchEmployeesStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
             // update Employee
             .addCase(updateEmployeeAsync.pending, (state) => {
                state.updateEmployeeStatus = StatusEnums.LOADING;
            })
            .addCase(updateEmployeeAsync.fulfilled, (state, action) => {
                state.updateEmployeeStatus = StatusEnums.SUCCESS;
            })
            .addCase(updateEmployeeAsync.rejected, (state, action) => {
                state.updateEmployeeStatus = StatusEnums.FAILED;
                state.resError = action.payload as IResError;
            })
    },
});

export const { resetDeleteEmployeesStatus, resetFetchEmployeeStatus, resetFetchEmployeesStatus, resetAddEmployeeStatus, resetUpdateEmployeeStatus } = employeeSlice.actions;


export const selectEmployees = (state: RootState) => state.employee.employees;
export const selectEmployee = (state: RootState) => state.employee.employee;
export const selectEmployeeCount = (state: RootState) => state.employee.employees?.count;
export const selectFetchEmployeesStatus = (state: RootState) => state.employee.fetchEmployeesStatus;
export const selectUpdateEmployeeStatus = (state: RootState) => state.employee.updateEmployeeStatus;
export const selectAddEmployeeStatus = (state: RootState) => state.employee.addEmployeeStatus;
export const selectFetchEmployeeStatus = (state: RootState) => state.employee.fetchEmployeeStatus;
export const selectDeleteEmployeesStatus = (state: RootState) => state.employee.deleteEmployeesStatus;
export const selectEmployeeResError = (state: RootState) => state.employee.resError;

export default employeeSlice.reducer;
