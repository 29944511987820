import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { ITransactionCreate } from "../../types/transaction";
import { IUploadRankCreate } from "../../types/upload-rank";

export interface TransactionState {
  addUploadRankStatus: StatusEnums;
  resError: IResError | null,
  addedResult: { id: string, paht: string } | null
}

const initialState: TransactionState = {
  addUploadRankStatus: StatusEnums.IDLE,
  resError: null,
  addedResult: null
};

export const addUploadRankAsync = createAsyncThunk(
  "uploadRank/addUploadRank",
  async (uploadRank: IUploadRankCreate, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("uploadedFile", uploadRank.uploadedFile);
      // formData.append("bankAccount", uploadRank.bankAccount);
      const response = await axiosInstance.post("/api/upload-ranks/upload", formData);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const uploadRanksSlice = createSlice({
  name: "uplodaRank",
  initialState,
  reducers: {
    resetAddUploadRankStatus: (state) => {
      state.addUploadRankStatus = StatusEnums.IDLE;
    }
  },
  extraReducers: (builder) => {
    builder
      // add booking
      .addCase(addUploadRankAsync.pending, (state) => {
        state.addUploadRankStatus = StatusEnums.LOADING;
      })
      .addCase(addUploadRankAsync.fulfilled, (state, action) => {
        state.addUploadRankStatus = StatusEnums.SUCCESS;
      })
      .addCase(addUploadRankAsync.rejected, (state, action) => {
        state.addUploadRankStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetAddUploadRankStatus } = uploadRanksSlice.actions;
export const selectAddUploadRankStatus = (state: RootState) => state.uploadRank.addUploadRankStatus;
export const selectUploadRankResError = (state: RootState) => state.uploadRank.resError;

export default uploadRanksSlice.reducer;
