import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IGroup, IGroupCreate } from "../../types/group";
import { IUserGroup } from "../../types/user-group";

export interface UserGroupState {
  fetchUserGroupsStatus: StatusEnums;
  addUserGroupStatus: StatusEnums;
  deleteUserGroupStatus: StatusEnums;
  userGroups: IPagination<IUserGroup> | null,
  userGroup: IUserGroup | null,
  resError: IResError | null
}

const initialState: UserGroupState = {
  fetchUserGroupsStatus: StatusEnums.IDLE,
  addUserGroupStatus: StatusEnums.IDLE,
  deleteUserGroupStatus: StatusEnums.IDLE,
  userGroups: null,
  userGroup: null,
  resError: null
};


export const fetchUserGroupsAsync = createAsyncThunk(
  "userGroup/fetchUserGroups",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/customer-groups/search-group-user", filters);
    return response.data;
  }
);

export const addUserGroupAsync = createAsyncThunk(
  "userGroup/addUserGroup",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/customer-groups/add-user/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteUserGroupAsync = createAsyncThunk(
  "userGroup/deleteUserGroup",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.post("/api/customer-groups/delete-user/many/" +params.id , params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const userGroupSlice = createSlice({
  name: "userGroup",
  initialState,
  reducers: {
    resetFetchUserGroupsStatus: (state) => {
      state.fetchUserGroupsStatus = StatusEnums.IDLE;
    },
    resetAddUserGroupsStatus: (state) => {
      state.addUserGroupStatus = StatusEnums.IDLE;
    },
    resetDeleteUserGroupsStatus: (state) => {
      state.deleteUserGroupStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch group 
      .addCase(fetchUserGroupsAsync.pending, (state, action) => {
        state.fetchUserGroupsStatus = StatusEnums.LOADING;
      })
      .addCase(fetchUserGroupsAsync.fulfilled, (state, action) => {
        state.fetchUserGroupsStatus = StatusEnums.SUCCESS;
        state.userGroups = action.payload as IPagination<IUserGroup>;
      })
      .addCase(fetchUserGroupsAsync.rejected, (state, action) => {
        state.fetchUserGroupsStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // update user group
      .addCase(addUserGroupAsync.pending, (state) => {
        state.addUserGroupStatus = StatusEnums.LOADING;
      })
      .addCase(addUserGroupAsync.fulfilled, (state) => {
        state.addUserGroupStatus = StatusEnums.SUCCESS;
        state.userGroups = null;
      })
      .addCase(addUserGroupAsync.rejected, (state, action) => {
        state.addUserGroupStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // delete user group
      .addCase(deleteUserGroupAsync.pending, (state) => {
        state.deleteUserGroupStatus = StatusEnums.LOADING;
      })
      .addCase(deleteUserGroupAsync.fulfilled, (state) => {
        state.deleteUserGroupStatus = StatusEnums.SUCCESS;
        state.userGroups = null;
      })
      .addCase(deleteUserGroupAsync.rejected, (state, action) => {
        state.deleteUserGroupStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchUserGroupsStatus, resetAddUserGroupsStatus, resetDeleteUserGroupsStatus } = userGroupSlice.actions;

export const selectUserGroups = (state: RootState) => state.userGroup.userGroups;
export const selectUserGroup = (state: RootState) => state.userGroup.userGroup;
export const selectFetchUserGroupsStatus = (state: RootState) => state.userGroup.fetchUserGroupsStatus;
export const selectAddUserGroupStatus = (state: RootState) => state.userGroup.addUserGroupStatus;
export const selectDeleteUserGroupStatus = (state: RootState) => state.userGroup.deleteUserGroupStatus;
export const selectUserGroupResError = (state: RootState) => state.userGroup.resError;

export default userGroupSlice.reducer;
