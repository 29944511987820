import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IMissionAutoType } from "../../types/mission-auto-types";
import { IFilters } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface MissionAutoTypeState {
  fetchMissionAutoTypesStatus: StatusEnums;
  missionAutoTypes: IMissionAutoType | null,
  missionAutoType: IMissionAutoType | null,
  filters: IFilters | null,
  resError: IResError | null,
}

const initialState: MissionAutoTypeState = {
  fetchMissionAutoTypesStatus: StatusEnums.IDLE,
  missionAutoTypes: null,
  missionAutoType: null,
  filters: null,
  resError: null,
};

export const fetchMissionAutoTypesAsync = createAsyncThunk(
  "missionAutoTypes/fetchMissionAutoTypes",
  async () => {
    const response = await axiosInstance.get("/api/mission-auto-types/all");
    return response.data;
  }
);

export const missionAutoTypesSlice = createSlice({
  name: "missionAutoTypes",
  initialState,
  reducers: {
    resetFetchMissionAutoTypesStatus: (state) => {
      state.fetchMissionAutoTypesStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // points
      .addCase(fetchMissionAutoTypesAsync.pending, (state) => {
        state.fetchMissionAutoTypesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchMissionAutoTypesAsync.fulfilled, (state, action) => {
        state.fetchMissionAutoTypesStatus = StatusEnums.SUCCESS;
        state.missionAutoTypes = action.payload as IMissionAutoType;
      })
      .addCase(fetchMissionAutoTypesAsync.rejected, (state, action) => {
        state.fetchMissionAutoTypesStatus = StatusEnums.FAILED;
      })
  },
});

export const {resetFetchMissionAutoTypesStatus} = missionAutoTypesSlice.actions;

export const selectMissionAutoTypes = (state: RootState) => state.missionAutoType.missionAutoTypes;
export const selectMissionAutoType = (state: RootState) => state.missionAutoType.missionAutoType;
export const selectFetchMissionAutoTypesStatus = (state: RootState) => state.missionAutoType.fetchMissionAutoTypesStatus;
export const selectMissionAutoTypesResError = (state: RootState) => state.missionAutoType.resError;

export default missionAutoTypesSlice.reducer;
