import { Typography } from "@mui/material";
type Props = {
  children: string;
  right?: JSX.Element;
}
function PageTitle({ children, right }: Props) {
  return (
    <Typography variant="h3" color="#686361" gutterBottom display="inline" style={{ display: "flex" }}>
      {children}
      <div style={{ flexGrow: 1 }} />
      {right}
    </Typography>
  )
}
export default PageTitle;