import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { ICity } from "../../types/city";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";

export interface CityState {
  fetchCitiesStatus: StatusEnums;
  fetchLocationDetailStatus: StatusEnums;
  cities: IPagination<ICity> | null;
  resError: IResError | null;
  location: ICity | null;
}

const initialState: CityState = {
  fetchCitiesStatus: StatusEnums.IDLE,
  fetchLocationDetailStatus: StatusEnums.IDLE,
  cities: null,
  resError: null,
  location: null,
};

export const fetchCitiesAsync = createAsyncThunk(
  "cities/fetchCities",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/locations/search", filters);
    return response.data;
  }
);

export const fetchLocationDetail = createAsyncThunk(
  "cities/detail",
  async (id: string) => {
    const response = await axiosInstance.get("/api/locations/detail/" + id);
    return response.data;
  }
);

export const citySlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    resetFetchCitiesStatus: (state) => {
      state.fetchCitiesStatus = StatusEnums.IDLE;
    },
    resetFetchLocationDetail: (state) => {
      state.fetchLocationDetailStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch cities
      .addCase(fetchCitiesAsync.pending, (state, action) => {
        state.fetchCitiesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchCitiesAsync.fulfilled, (state, action) => {
        state.fetchCitiesStatus = StatusEnums.SUCCESS;
        state.cities = action.payload as IPagination<ICity>;
      })
      .addCase(fetchCitiesAsync.rejected, (state, action) => {
        state.fetchCitiesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      .addCase(fetchLocationDetail.pending, (state, action) => {
        state.fetchLocationDetailStatus = StatusEnums.LOADING;
      })
      .addCase(fetchLocationDetail.fulfilled, (state, action) => {
        state.fetchLocationDetailStatus = StatusEnums.SUCCESS;
        state.location = action.payload as ICity;
      })
      .addCase(fetchLocationDetail.rejected, (state, action) => {
        state.fetchLocationDetailStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      });
  },
});

export const { resetFetchCitiesStatus, resetFetchLocationDetail } =
  citySlice.actions;

export const selectCities = (state: RootState) => state.city.cities;
export const selectCityCount = (state: RootState) => state.city.cities?.count;
export const selectFetchCitiesStatus = (state: RootState) =>
  state.city.fetchCitiesStatus;

export const selectLocation = (state: RootState) => state.city.location;

export default citySlice.reducer;
