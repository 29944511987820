import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnums } from "../../enums/status-enum";
import { IFilters, IPagination } from "../../types/pagination";
import { IResError } from "../../types/res-error";
import axiosInstance from "../../utils/axios";
import { RootState } from "../store";
import { IMissionProcess } from "../../types/mission-process";

export interface MissonProcessState {
  fetchMissionProcessesStatus: StatusEnums;
  fetchMissionProcessStatus: StatusEnums;
  updateMissionProcessStatus: StatusEnums;
  missionProcesses: IPagination<IMissionProcess> | null,
  missionProcess: IMissionProcess | null,
  resError: IResError | null,
}

const initialState: MissonProcessState = {
  fetchMissionProcessesStatus: StatusEnums.IDLE,
  fetchMissionProcessStatus: StatusEnums.IDLE,
  updateMissionProcessStatus: StatusEnums.IDLE,
  missionProcesses: null,
  missionProcess: null,
  resError: null,

};

export const fetchMissionProcessesAsync = createAsyncThunk(
  "missionProcess/fetchMissionProcesses",
  async (filters?: IFilters) => {
    const response = await axiosInstance.post("/api/missions/search-mission-admin", filters);
    return response.data;
  }
);

export const fetchMissionProcessAsync = createAsyncThunk(
  "missionProcess/fetchMissionProcess",
  async (id: string = "", thunkApi) => {
    try {
      const response = await axiosInstance.get("/api/missions/process-detail/" + id);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateMissionProcessAsync = createAsyncThunk(
  "missionProcess/updateMissionProcess",
  async (params: any, thunkApi) => {
    try {
      const response = await axiosInstance.put("/api/missions/update-process/" + params.id, params.payload);
      return response.data;
    }
    catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const missionProcesSlice = createSlice({
  name: "missionProcess",
  initialState,
  reducers: {
    resetFetchMissionProcessesStatus: (state) => {
      state.fetchMissionProcessesStatus = StatusEnums.IDLE;
    },
    resetFetchMissionProcessStatus: (state) => {
      state.fetchMissionProcessStatus = StatusEnums.IDLE;
    },
    resetUpdateMissionProcessStatus: (state) => {
      state.updateMissionProcessStatus = StatusEnums.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // update mission
      .addCase(updateMissionProcessAsync.pending, (state) => {
        state.updateMissionProcessStatus = StatusEnums.LOADING;
      })
      .addCase(updateMissionProcessAsync.fulfilled, (state) => {
        state.updateMissionProcessStatus = StatusEnums.SUCCESS;
        state.missionProcesses = null;
      })
      .addCase(updateMissionProcessAsync.rejected, (state, action) => {
        state.updateMissionProcessStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch mission process
      .addCase(fetchMissionProcessAsync.pending, (state, action) => {
        state.fetchMissionProcessStatus = StatusEnums.LOADING;
      })
      .addCase(fetchMissionProcessAsync.fulfilled, (state, action) => {
        state.fetchMissionProcessStatus = StatusEnums.SUCCESS;
        state.missionProcess = action.payload;
      })
      .addCase(fetchMissionProcessAsync.rejected, (state, action) => {
        state.fetchMissionProcessStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
      // fetch mission processes
      .addCase(fetchMissionProcessesAsync.pending, (state, action) => {
        state.fetchMissionProcessesStatus = StatusEnums.LOADING;
      })
      .addCase(fetchMissionProcessesAsync.fulfilled, (state, action) => {
        state.fetchMissionProcessesStatus = StatusEnums.SUCCESS;
        state.missionProcesses = action.payload as IPagination<IMissionProcess>;
      })
      .addCase(fetchMissionProcessesAsync.rejected, (state, action) => {
        state.fetchMissionProcessesStatus = StatusEnums.FAILED;
        state.resError = action.payload as IResError;
      })
  },
});

export const { resetFetchMissionProcessesStatus, resetFetchMissionProcessStatus, resetUpdateMissionProcessStatus } = missionProcesSlice.actions;

export const selectMissionProcesses = (state: RootState) => state.missionProcess.missionProcesses;
export const selectMissionProcess = (state: RootState) => state.missionProcess.missionProcess;
export const selectMissionProcessCount = (state: RootState) => state.missionProcess.missionProcesses?.count;
export const selectFetchMissionProcessesStatus = (state: RootState) => state.missionProcess.fetchMissionProcessesStatus;
export const selectFetchMissionProcessStatus = (state: RootState) => state.missionProcess.fetchMissionProcessStatus;
export const selectMissionProcessResError = (state: RootState) => state.missionProcess.resError;
export const selectUpdateMissionProcessStatus = (state: RootState) => state.missionProcess.updateMissionProcessStatus;

export default missionProcesSlice.reducer;
